import { Navbar, Image, Nav, Container, Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTotals } from "../Slices/CartSlice";
// import handleAddCart from "../Slices/CartSlice"
import "./Header.css";
import Logo from "../../public/assets/images/logo.webp";

function Header() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  var loginData = JSON.parse(localStorage.getItem("login"));

  const handelLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("cart_items");
    localStorage.removeItem("perMilagePrice");
    localStorage.removeItem("zipCode");
    navigate("/Containers");
  };

  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "fixed" && window.pageYOffset >= 100) {
      setHeaderClassName("fixed");
    } else if (headerClassName === "fixed" && window.pageYOffset < 100) {
      setHeaderClassName("");
    }
  };

  React.useEffect(() => {
    dispatch(getTotals());
  }, []);

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  // const handelProfile = () => {

  //   navigate('');
  // }
  return (
    <header className={headerClassName}>
      <div className="topbar">
        <div className="container">
          <ul>
            <li>
              <a href="mailto:sales@procontainers.com">
                {" "}
                <Icon icon="ion:mail" /> <i>sales@procontainers.com</i>
              </a>
            </li>
            <li>
              {/* TODO Update Telphone number when available */}
              <a href="tel:830-282-0000">
                <Icon icon="fluent:call-16-filled" />
                <i>830-282-0000</i>
              </a>
            </li>
            <li className="TopBarFacebook">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/procontainerscompany"
              >
                <Icon icon="ic:outline-facebook" />
              </a>
            </li>
            {(() => {
              if (loginData !== null && loginData.login) {
                return (
                  <li>
                    <a className="logOut" href="/" onClick={handelLogout}>
                      Log Out
                    </a>
                  </li>
                );
              } else {
                return (
                  <li>
                    <Link to="/Login">LOGIN/SIGNUP</Link>
                  </li>
                );
              }
            })()}
          </ul>
        </div>
      </div>
      {["lg"].map((expand) => (
        <Navbar key={expand} bg="" expand={expand}>
          <Container>
            <Navbar.Brand href="/">
              <Image src={Logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <a
                    className={`${location.pathname === "/" && "active"}`}
                    href="/"
                  >
                    Home
                  </a>
                  <a
                    className={`${
                      location.pathname === "/Containers" && "active"
                    }`}
                    href="/Containers"
                  >
                    Containers
                  </a>
                  <a
                    className={`${location.pathname === "/" && "active"}`}
                    href="/20ft"
                  >
                    20FT
                  </a>
                  <a
                    className={`${location.pathname === "/" && "active"}`}
                    href="/40ft"
                  >
                    40FT
                  </a>

                  <Dropdown className="CustomDropDown">
                    <Dropdown.Toggle
                      className={`${
                        (location.pathname === "/ClientAssetManagement" ||
                          location.pathname === "/RecyclingServices" ||
                          location.pathname === "/Appraisals" ||
                          location.pathname === "/FindersProgram" ||
                          location.pathname === "/Selltous" ||
                          location.pathname === "/SpecializedContracts") &&
                        "active"
                      }`}
                      variant=""
                      id="dropdown-basic"
                    >
                      Our Services
                    </Dropdown.Toggle>
                    {/* TODO : Update Route Links when pages created */}
                    <Dropdown.Menu>
                      <a href="/BuyAContainer">Buy a Container</a>
                      <a href="/RentAContainer">Rent a Container</a>
                      <a href="/ClimateControlledContainer">
                        Climate Controlled Containers
                      </a>
                      {/* <a href="/PortableOffice">Portable Offices</a> */}
                      {/* <a href="/ModularManufacturing">Modular Manufacturing</a> */}
                      <a href="/Selltous">Sell us your container</a>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="dropdown-menu show dropdown-menu-mobile">
                    <a href="/BuyAContainer">Buy a Container</a>
                    <a href="/RentAContainer">Rent a Container</a>
                    <a href="/ClimateControlledContainer">
                      Climate Controlled Containers
                    </a>
                    {/* <a href="/PortableOffice">Portable Offices</a> */}
                    {/* <a href="/ModularManufacturing">Modular Manufacturing</a> */}
                    <a href="/Selltous">Sell us your container</a>
                  </div>
                  <a
                    className={`${
                      location.pathname === "/Aboutus" && "active"
                    }`}
                    href="/Aboutus"
                  >
                    About Us
                  </a>
                  <a
                    className={`${
                      location.pathname === "/Gallery" && "active"
                    }`}
                    href="/ContactUs"
                  >
                    Contact Us
                  </a>
                  {/* <Link to="/Carrier">Carrier</Link> */}
                  {/* <button className="cart-btn">
                    <FiShoppingCart />
                  </button> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <ul className="header-btn-wrap">
              {loginData != null && loginData.login && (
                <>
                  <li>
                    <a
                      href="/ShoppingCart"
                      className={`${
                        location.pathname === "/ShoppingCart" && "active"
                      } cart-header`}
                    >
                      <Icon icon="akar-icons:cart" />
                      {cart.totalItem > 0 && (
                        <span className="cartCount">{cart.totalItem}</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Profile"
                      className={`${
                        location.pathname === "/Profile" && "active"
                      } cart-header`}
                    >
                      <Icon icon="mdi:user" />
                    </a>
                  </li>
                </>
              )}
            </ul>
          </Container>
        </Navbar>
      ))}
    </header>
  );
}

export default Header;
