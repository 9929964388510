import React, { useEffect, useState } from "react";
import axiosClient from "../../axiosClient";
import Select from "react-select";
import { setIndustry, setItemCondition } from "../Slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";

function Condition(props) {
  const [industryModel, setIndustryModel] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);

  const industryList = async () => {
    try {
      const res = await axiosClient.get("GetAllCondition");
      setIndustryModel({ data: res });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilters = () => {
    // Call the resetIndustryFilter callback passed from InventoryProductSection
    if (props.resetIndustryFilter) {
      props.resetIndustryFilter();
    }
  };

  // Function to remove brackets from the label
  const removeBrackets = (label) => {
    return label.replace(/\([^)]*\)/, "").trim();
  };

  useEffect(() => {
    if (selectedOption && selectedOption.label != null) {
      var selectItem = {
        id: selectedOption.value,
        name: selectedOption.label,
      };
      dispatch(setItemCondition(selectItem));
    } else {
      industryList();
    }
  }, [selectedOption]);

  function capitalize(str) {
    return str.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  return (
    <>
      {/* <Select
        className="custom-select input-form mb-2"
        placeholder="Container Condition"
        value={selectedOption}
        onChange={setSelectedOption}
        blurInputOnSelect={true}
        isSearchable={false}
        // menuIsOpen={true}
        options={
          industryModel &&
          industryModel.data &&
          industryModel.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        }
      /> */}

      {industryModel.data && industryModel.data.length > 0 && (
        <Form.Select
          className="filter-box searchbar"
          aria-label="Default select example"
          onChange={(e) => {
            const value = e.target.value;
            const label = removeBrackets(
              e.target.options[e.target.selectedIndex].text
            );
            const itemCount = industryModel.data.length;
            const selectItem = {
              id: value,
              name: label,
            };

              // initial array
           let conditionArray=[];
           conditionArray.push(label);
           dispatch(setItemCondition(conditionArray));
           // dispatch(setItemCondition(selectItem));
          }}
          value={filterState.Industry}
        >
          <option value="0">Condition</option>
          {industryModel.data.map((item) => (
            <option value={item.id}>
              {capitalize(removeBrackets(item.name)) +
                " (" +
                item.itemCount +
                ")"}
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
}

export default Condition;
