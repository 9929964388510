// import React, { useEffect, useState } from "react";
// import axiosClient from "../../axiosClient";
// import Select from "react-select";
// import { useSelector } from "react-redux";
// import { setCategory } from "../Slices/FilterSlice";
// import { useDispatch } from "react-redux";

// function Category(prop) {
//   const [categoryModel, setcategoryModel] = useState({});
//   const [selectedOption, setSelectedOption] = useState(null);
//   const filterState = useSelector((state) => state.filter);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const categoryList = async () => {
//       try {
//         setSelectedOption(null);
//         if (filterState.industry != null && filterState.industry.name) {
//           const res = await axiosClient.get(
//             "GetAllCategoryByIndustry/" + filterState.industry.name
//           );
//           setcategoryModel({ data: res });
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     categoryList();
//   }, [filterState.industry]);

//   useEffect(() => {
//     if (selectedOption && selectedOption.label != null) {
//       var selectItem = {
//         id: selectedOption.value,
//         name: selectedOption.label,
//       };
//       dispatch(setCategory(selectItem));
//     }
//   }, [selectedOption]);

//   function capitalize(str) {
//     return str.replace(/\b\w/g, (l) => l.toUpperCase());
//   }

//   return (
//     <>
//       {(() => {
//         return (
//           <>
//             <Select
//               className="custom-select input-form mb-2"
//               value={selectedOption}
//               placeholder="Browse by Container Size "
//               blurInputOnSelect={true}
//               isSearchable={false}
//               disabled={() => {
//                 if (
//                   filterState.selectedIndustry &&
//                   filterState.selectedIndustry.label
//                 ) {
//                   return false;
//                 } else {
//                   return false;
//                 }
//               }}
//               onChange={setSelectedOption}
//               options={
//                 categoryModel.data &&
//                 categoryModel.data.map((item) => ({
//                   value: item.id,
//                   label: item.name,
//                 }))
//               }
//             />
//           </>
//         );
//       })()}
//     </>
//   );
// }

// export default Category;
import React, { useEffect, useState } from "react";
import axiosClient from "../../axiosClient";
import { useSelector, useDispatch } from "react-redux";
import { setCategory } from "../Slices/FilterSlice";
import Form from "react-bootstrap/Form";
import "./category.css";

function Category(prop) {
  const [categoryModel, setCategoryModel] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const filterState = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  useEffect(() => {
    const categoryList = async () => {
      try {
        setSelectedOption(null);
        // if (filterState.industry != null && filterState.industry.name) {
        // const res = await axiosClient.get(
        //   "GetAllCategoryByIndustry/" + filterState.industry.name
        // );
        const res = await axiosClient.get("GetAllCategory");
        setCategoryModel({ data: res });

        // } else {
        //   const res = await axiosClient.get("GetAllCategory");
        //   setCategoryModel({ data: res });
        // }
      } catch (error) {
        console.log(error);
      }
    };
    categoryList();
  }, [filterState.industry]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       setSelectedOption(null);
  //       if (filterState.industry) {
  //         console.log(filterState.industry.name);
  //         // Fetch categories related to the selected industry
  //         const res = await axiosClient.get(
  //           `"GetAllCategoryByIndustry" + ${filterState.industry.name}`
  //         );
  //         setCategoryModel({ data: res });
  //       } else {
  //         // If no industry is selected, fetch all categories
  //         const res = await axiosClient.get("GetAllCategory");
  //         setCategoryModel({ data: res });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchCategories();
  // }, [filterState.industry]); // Listen for changes in the selected industry

  useEffect(() => {
    if (selectedOption && selectedOption.label != null) {
      var selectItem = {
        id: selectedOption.value,
        name: selectedOption.label,
      };
      dispatch(setCategory(selectItem));
    }
  }, [selectedOption]);

  function capitalize(str) {
    return str.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  return (
    <>
      {categoryModel.data && (
        <Form.Select
          className="filter-box"
          placeholder="Browse by category"
          blurInputOnSelect={true}
          onChange={(e) => {
            const labelWithBrackets =
              e.target.options[e.target.selectedIndex].text;
            const labelWithoutBrackets = labelWithBrackets
              .replace(/\([^)]*\)/, "")
              .trim();
            setSelectedOption({
              value: e.target.value,
              label: labelWithoutBrackets,
            });
          }}
        >
          <option value="0">Size</option>
          {categoryModel.data
            .map((item) => ({
              value: item.id,
              label: `${capitalize(item.name)}`,
              itemCount: item.itemCount,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => (
              <option value={item.value} key={item.value}>
                {item.label + " (" + item.itemCount + ")"}
              </option>
            ))}
        </Form.Select>
      )}
    </>
  );
}

export default Category;
