import React from "react";
import { Image } from "react-bootstrap";
import "./SpecializedContractsInfo.css";

function SpecializedContractsInfo() {
  return (
    <section className="sellwithusSection SellBannerFix contractBannerFix">
      <div className="bg-white white-0 DesktopInnerText">
        <div className="sellwithusInfo ps-0">
          <h4>
            Tailor-Made Solutions, Unwavering Dedication: Custom Contracts for
            Your Intense Demands <small className="TmUp">TM</small>
          </h4>
          <p className="mb-3">
            At ProContainers, we understand that every business has its own
            unique requirements and processes. That's why we offer completely
            custom specialized contracts tailored to suit your organization and
            ours.
          </p>
          <p>
            Combined with the construction of your needs, Our AMP ™ System can
            build applications to manage your unique processes in a way that
            seamlessly integrates with your day to day business, all while
            adhering to your requirements. Worried about onboarding? No worries,
            we can provide personnel and train your staff to utilize the system
            effectively.  We can even cater to your organization's custom
            reporting requirements. Let us be your one-stop solution for
            managing your organization's most complex asset management needs.
          </p>
        </div>
        <div className="sellwithusImg">
          <Image src="/assets/images/SpecializedContracts.jpg" />
        </div>
      </div>
      <div className=" MobileTextInfo">
        <h4>
          Tailor-Made Solutions, Unwavering Dedication: Custom Contracts for
          Your Intense Demands <small className="TmUp">TM</small>
        </h4>
        <div className="MobileDesInfo">
          <Image
            className="sellwithusImgEffect"
            src="/assets/images/SpecializedContracts.jpg"
          />
          <p>
            At ProContainers, we understand that every business has its own
            unique requirements and processes. That's why we offer completely
            custom specialized contracts tailored to suit your organization and
            ours.
          </p>
          <p>
            Combined with the construction of your needs, Our AMP ™ System can
            build applications to manage your unique processes in a way that
            seamlessly integrates with your day to day business, all while
            adhering to your requirements. Worried about onboarding? No worries,
            we can provide personnel and train your staff to utilize the system
            effectively.  We can even cater to your organization's custom
            reporting requirements. Let us be your one-stop solution for
            managing your organization's most complex asset management needs.
          </p>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="sellToUsBoxMain">
        <h4>Contract Solutions</h4>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Customized Process Management</h6>
              <p>
                We analyze your organization's existing processes and work
                closely with your team to understand your unique needs. Our team
                of experts then designs and builds a custom contract solution to
                streamline and manage the process, saving you time and
                resources.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Application Development</h6>
              <p>
                To further enhance the efficiency and effectiveness of your
                custom contract, our skilled developers will create tailor-made
                applications that seamlessly integrate with your existing
                systems. These applications are designed to ensure smooth
                operations, real-time tracking, and secure data management.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Personnel Provision</h6>
              <p>
                Our custom contract solutions include the provision of highly
                qualified and experienced personnel to manage your new processes
                and applications. This ensures that your organization can
                continue to focus on its core competencies, while we take care
                of the rest.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Staff Training</h6>
              <p>
                To ensure the successful implementation of your custom contract
                solution, we provide comprehensive training programs for your
                staff. Our training sessions are designed to equip your team
                with the necessary skills and knowledge to utilize the system
                effectively, thus maximizing the benefits of your custom
                solution.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Custom Reporting</h6>
              <p>
                Our custom contract solutions also include the provision of
                custom reporting as per your organization's specific
                requirements. We understand that every business has different
                reporting needs, and our team will work closely with you to
                develop and implement the most suitable reporting formats and
                metrics to meet those needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SpecializedContractsInfo;
