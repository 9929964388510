import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";
import "./SpecializedContractsForm.css";

function SpecializedContractsForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const handleMultipleImages = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const [formData, setFormData] = useState({
    BusinessName: "",
    ContactPerson: "",
    Email: "",
    PhoneNumber: "",
    Website: "",
    ContactFullName: "",
    ContactEmail: "",
    ContactPhoneNumber: "",
    ContactContactMethod: "",
    ContactTimeReach: "",
    Industry: "",
    YearsOperation: "",
    Employess: "",
    AnnualRevenue: "",
    GeographicalPresence: "",
    ServiceRequired: "Asset Management",
    AdditionInformation: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formData);
    const formDataEntries = Object.entries(formData);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "Custom Contracts",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
    setFormData({
      BusinessName: "",
      ContactPerson: "",
      Email: "",
      PhoneNumber: "",
      Website: "",
      ContactFullName: "",
      ContactEmail: "",
      ContactPhoneNumber: "",
      ContactContactMethod: "",
      ContactTimeReach: "",
      Industry: "",
      YearsOperation: "",
      Employess: "",
      AnnualRevenue: "",
      GeographicalPresence: "",
      ServiceRequired: "Asset Management",
      AdditionInformation: "",
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>
            Embarking Together <sup>TM</sup>
          </h3>
          <p>
            Customized Contract Solutions for Asset Excellence, Surplus Success,
            Responsible Recycling, and Accurate Appraisals
          </p>
        </div>
        <Link to="#" onClick={handleShow} className="btn btn-white btn-icon ">
          Share your needs{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="RecyclingModal"
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Custom Contracts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sellToUsPopup">
              <p>
                Please fill out the following form to provide us with the
                necessary information about your business and its requirements
              </p>
              <div className="row">
                <h6>Business Information</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="BusinessName">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Business Name"
                      name="BusinessName"
                      value={formData.BusinessName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="ContactPerson">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control
                      name="ContactPerson"
                      value={formData.ContactPerson}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Contact Person"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="Email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="Email"
                      value={formData.Email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Enter Your Email"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      name="PhoneNumber"
                      value={formData.PhoneNumber}
                      onChange={handleChange}
                      type="number"
                      placeholder="Enter Your Phone Number"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="Website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      name="Website"
                      value={formData.Website}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Website"
                    />
                  </Form.Group>
                </Col>
                <hr />
                <h6>Contact Information</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="ContactFullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="ContactFullName"
                      value={formData.ContactFullName}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Full Name"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="ContactEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="ContactEmail"
                      value={formData.ContactEmail}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="ContactPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      name="ContactPhoneNumber"
                      value={formData.ContactPhoneNumber}
                      onChange={handleChange}
                      type="number"
                      placeholder="Enter Your Phone Number"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="ContactContactMethod">
                    <Form.Label>Preferred Contact Method</Form.Label>
                    <Form.Control
                      name="ContactContactMethod"
                      value={formData.ContactContactMethod}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Preferred Contact Method"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="ContactTimeReach">
                    <Form.Label>Best Time to Reach</Form.Label>
                    <Form.Control
                      name="ContactTimeReach"
                      value={formData.ContactTimeReach}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Best Time to Reach"
                    />
                  </Form.Group>
                </Col>
                <hr />
                <h6>Business Description</h6>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="Industry">
                    <Form.Label>Industry</Form.Label>
                    <Form.Control
                      name="Industry"
                      value={formData.Industry}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Industry"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="YearsOperation">
                    <Form.Label>Years in Operation</Form.Label>
                    <Form.Control
                      name="YearsOperation"
                      value={formData.YearsOperation}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Years in Operation"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="Employess">
                    <Form.Label>Number of Employees</Form.Label>
                    <Form.Control
                      name="Employess"
                      value={formData.Employess}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Number of Employees"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="AnnualRevenue">
                    <Form.Label>Annual Revenue</Form.Label>
                    <Form.Control
                      name="AnnualRevenue"
                      value={formData.AnnualRevenue}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Annual Revenue"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="GeographicalPresence">
                    <Form.Label>
                      Geographical Presence (Local, National, Global)
                    </Form.Label>
                    <Form.Control
                      name="GeographicalPresence"
                      value={formData.GeographicalPresence}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter Your Geographical Presence (Local, National, Global)"
                    />
                  </Form.Group>
                </Col>
                <hr />
                <h6>Services Required</h6>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="ServiceRequired">
                    <Form.Label>
                      Please select the services you require
                    </Form.Label>
                    <Form.Select
                      name="ServiceRequired"
                      value={formData.ServiceRequired}
                      onChange={handleChange}
                    >
                      <option>Asset Management</option>
                      <option>Surplus Management</option>
                      <option>Recycling</option>
                      <option>Appraisals</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <hr />
                <h6>Additional Information</h6>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="AdditionInformation">
                    <Form.Label>
                      Please provide any additional information that will help
                      us better understand your needs.
                    </Form.Label>
                    <Form.Control
                      name="AdditionInformation"
                      value={formData.AdditionInformation}
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
              </div>
              <small>
                Thank you for taking the time to fill out this form. We will
                review your information and contact you as soon as possible to
                discuss your needs.
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default SpecializedContractsForm;
