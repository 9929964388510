import React from "react";
import { Col, Row } from "react-bootstrap";
import "./HowWeWork.css";

function HowWeWork() {
  return (
    <div className="how-works-section">
      {/* <div className="how-work-titles">
                <h3>Let us work together as one body, with safety and service in mind, discovering the joy of our corporate family in Christ.
                </h3>
            </div> */}
      <Row>
        <Col xl={3} md={6}>
          <div className="how-work-item">
            <h5>Our Focus</h5>
            <p>
              Our corporate family excels in expertise and collaboration. Our
              team focuses on custom solutions for clients, forging lasting
              relationships based on trust and mutual success.
            </p>
          </div>
        </Col>
        <Col xl={3} md={6}>
          <div className="how-work-item">
            <h5>Driven by Purpose</h5>
            <p>
              We personally pledge to uphold the highest standards of excellence
              throughout every facet of our business, while maintaining ethical
              and responsible practices in all stages of our operations.{" "}
            </p>
          </div>
        </Col>
        <Col xl={3} md={6}>
          <div className="how-work-item">
            <h5>Our Core Values</h5>
            <p>
              We hold true to the core values of recycling by promoting the
              ideas of reusing and repurposing within our company. This vision
              is an integral part of our values and it directs our efforts
              towards the future.{" "}
            </p>
          </div>
        </Col>
        <Col xl={3} md={6}>
          <div className="how-work-item">
            <h5>Supporting our Community</h5>
            <p>
              ProContainers proudly partners with local churches and donation
              centers in a shared mission to improve our communities and the
              world with God's guidance.{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HowWeWork;
