import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ClientManagemenForm.css";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";

function ClientManagemenForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    FullName: "",
    Title: "",
    CompanyName: "",
    EmailAddress: "",
    WorkPhoneNumber: "",
    CellPhoneNumber: "",
    AddressLine: "",
    BriefSummary: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formData);
    const formDataEntries = Object.entries(formData);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "Client Partnership Application",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
    setFormData({
      FullName: "",
      Title: "",
      CompanyName: "",
      EmailAddress: "",
      WorkPhoneNumber: "",
      CellPhoneNumber: "",
      AddressLine: "",
      BriefSummary: "",
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleMultipleImages = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };
  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>Rent a Container with Us Now</h3>
          <p>
            Click the "Rent a Container" button to experience our containers for
            a worry-free rental experience.
          </p>
        </div>
        <Link
          to="/Containers"
          onClick={handleShow}
          className="btn btn-white btn-icon "
        >
          Rent Containers
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="RecyclingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Client Partnership Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sellToUsPopup">
            <p>
              We are truly honored by your interest in our services. Please
              complete the form below for further consideration
            </p>

            <Form className="row" onSubmit={handleSubmit}>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="FullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Full Name"
                    name="FullName"
                    value={formData.FullName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="Title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Title"
                    name="Title"
                    onChange={handleChange}
                    value={formData.Title}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="CompanyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Company Name"
                    name="CompanyName"
                    onChange={handleChange}
                    value={formData.CompanyName}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="EmailAddress">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email Address"
                    name="EmailAddress"
                    onChange={handleChange}
                    value={formData.EmailAddress}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="WorkPhoneNumber">
                  <Form.Label>Work Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Phone Number"
                    name="WorkPhoneNumber"
                    onChange={handleChange}
                    value={formData.WorkPhoneNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="CellPhoneNumber">
                  <Form.Label>Cell Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Cell Phone Number"
                    name="CellPhoneNumber"
                    onChange={handleChange}
                    value={formData.CellPhoneNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="AddressLine">
                  <Form.Label>Address Line</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Address Line"
                    name="AddressLine"
                    onChange={handleChange}
                    value={formData.AddressLine}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="BriefSummary">
                  <Form.Label>
                    Could you briefly outline your requirements? If you're
                    pressed for time, feel free to leave this field empty. We
                    understand how valuable your time is.
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Brief Summary"
                    name="BriefSummary"
                    onChange={handleChange}
                    value={formData.BriefSummary}
                  />
                </Form.Group>
              </Col>
              {/* <Button variant="primary" type="submit">
    Submit
  </Button> */}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit Form
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClientManagemenForm;
