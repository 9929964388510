import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import useDocumentTitle from "../../DocumentTitle";
import "./TermsOfService.css";

function TermsOfService() {
  useDocumentTitle("PC | TOS");
  return (
    <>
      <Header />
      <div className="d-margin">
        <Container>
          <div className="Terms_Info">
            <h2>Terms of Service</h2>
            <p>
              Thank you for choosing ProContainers ("we", "us", "our", or
              "Company" “PC”). Prior to utilizing our website, services, or any
              products provided by us (collectively, the "Services"), we request
              that you review these Terms of Service ("Terms") in their
              entirety. By accessing or utilizing the Services, you agree to
              comply with and be legally bound by these Terms. If there is any
              aspect of the Terms with which you do not agree, please refrain
              from accessing or utilizing the Services.
            </p>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Services</Accordion.Header>
                <Accordion.Body>
                  <p>
                    {" "}
                    ProContainers is an e-commerce platform that offers various
                    services as shown below:
                  </p>
                  <ul className="box_service">
                    <li>Buy A Container</li>
                    <li>Rent A Container</li>
                    <li>Sell to Us Services</li>
                  </ul>
                  <p>
                    These services are subject to their respective terms and
                    conditions, which are incorporated herein by reference.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. Eligibility</Accordion.Header>
                <Accordion.Body>
                  <p>
                    By using the Services, you represent and warrant that you
                    are at least 18 years of age and have the legal capacity to
                    enter into a binding contract. If you are under 18, you must
                    obtain consent from your parent or guardian to use the
                    Services.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. User Accounts</Accordion.Header>
                <Accordion.Body>
                  <p>
                    To access certain features of the Services, you may be
                    required to create an account. You are responsible for
                    maintaining the confidentiality of your account information
                    and password, and for all activities that occur under your
                    account. You agree to immediately notify us of any
                    unauthorized use of your account or any breach of security.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>4. Buy A Container</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Buying a container from ProContainers are subject to a
                    separate agreement that will be provided to you at the time
                    of engagement. By using these services, you agree to comply
                    with all terms and conditions set forth in the agreement, as
                    well as any applicable laws and regulations.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>5. Rent A Container</Accordion.Header>
                <Accordion.Body>
                  <p>
                    By accessing, browsing, or using our website and services,
                    you agree to comply with and be bound by the following terms
                    and conditions. Any rental or purchase of a container
                    through our website or services will be governed by a
                    separate contract. This contract will be provided to the
                    customer at the time of sale or rental. The details and
                    obligations under that contract are separate from these
                    general website terms of service.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6.Climate Controlled Containers
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    By using our Recycling Services, you agree to comply with
                    all applicable laws and regulations, including those
                    relating to the disposal and recycling of metals. You are
                    responsible for ensuring that the metals you provide for
                    recycling are properly sorted, cleaned, and prepared in
                    accordance with our guidelines. Any and all metals may be
                    subject to downgrades due to contamination or foreign
                    materials found. Please ensure all materials are as
                    presented when entered into agreement.
                  </p>
                </Accordion.Body>
              </Accordion.Item> */}
              {/* <Accordion.Item eventKey="6">
                <Accordion.Header>7. Portable Offices</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our Appraisals services are subject to a separate agreement
                    that will be provided to you at the time of engagement. By
                    using these services, you agree to comply with all terms and
                    conditions set forth in the agreement, as well as any
                    applicable laws and regulations.
                  </p>
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="7">
                <Accordion.Header>6. Sell to Us Services</Accordion.Header>
                <Accordion.Body>
                  <p>
                    By using our Sell to Us service, you represent and warrant
                    that you have the legal right to sell the items you list for
                    sale, and that the items are accurately described and free
                    from defects. You agree to indemnify and hold us harmless
                    from any claims, damages, or losses resulting from your
                    breach of this representation.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="8">
                <Accordion.Header>10. Specialized Contracts</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our Specialized Contracts services are subject to a separate
                    agreement that will be provided to you at the time of
                    engagement. By using these services, you agree to comply
                    with all terms and conditions set forth in the agreement, as
                    well as any applicable laws and regulations.
                  </p>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
            <h4>Payment</h4>
            <p>
              Payment for our services is subject to the terms and conditions
              set forth in each respective service agreement. You agree to pay
              all fees and charges associated with your use of the Services,
              including any applicable taxes. We reserve the right to change our
              fees and charges at any time, and any such changes will be
              effective immediately upon posting on our website.
            </p>
            <hr />
            <h4>Intellectual Property</h4>
            <p>
              All content on our website, including but not limited to text,
              graphics, logos, images, and software, is the property of
              ProContainers or its licensors and is protected by intellectual
              property laws. You may not use our content for any commercial
              purpose without our prior written consent. Additionally, all
              slogans utilized on our website are trademarked and reserved for
              the exclusive use of ProContainers. Unauthorized use of these
              trademarks is strictly prohibited.
            </p>
            <hr />
            <h4>Disclaimer of Warranties</h4>
            <p>
              We make no representations or warranties of any kind, express or
              implied, regarding the Services or any content or information
              contained therein. We disclaim all warranties, including but not
              limited to warranties of merchantability, fitness for a particular
              purpose, and non-infringement. Furthermore, all items sold on our
              website are sold on an "as-is, where-is" basis. By making a
              purchase, you acknowledge and agree to accept the items in their
              current condition without any warranties or guarantees.
            </p>
            <hr />
            <h4>Limitation of Liability</h4>
            <p>
              In no event shall ProContainers, its officers, directors,
              employees, agents, or affiliates be liable for any direct,
              indirect, incidental, special, exemplary, punitive or
              consequential damages (including, but not limited to, damages for
              loss of profits, goodwill, use, data, or other intangible losses)
              arising out of or in connection with your use of, or inability to
              use, the Services, whether based on warranty, contract, tort
              (including negligence), or any other legal theory, even if
              ProContainers has been advised of the possibility of such damages.
              This limitation of liability shall apply to the fullest extent
              permitted by law and shall survive the termination or expiration
              of this agreement.
            </p>
            <hr />
            <h4>Indemnification</h4>
            <p>
              You agree to indemnify and hold ProContainers and its officers,
              directors, employees, agents, and affiliates harmless from any and
              all claims, damages, expenses, and liabilities arising out of or
              in connection with your use of the Services, including but not
              limited to your breach of these Terms.
            </p>
            <hr />
            <h4>Termination</h4>
            <p>
              We reserve the right to terminate your access to the Services at
              any time, with or without cause, and without prior notice. Upon
              termination, all rights granted to you under these Terms will
              immediately cease.
            </p>
            <hr />
            <h4>Governing Law</h4>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of the State of Texas, specifically in Val Verde County,
              without giving effect to any principles of conflicts of law. No
              other document can supersede the terms and conditions stated in
              this agreement.
            </p>
            <hr />
            <h4>Dispute Resolution</h4>
            <p>
              Any dispute arising out of or in connection with these Terms or
              the Services shall be resolved through binding arbitration in
              accordance with the rules of the American Arbitration Association.
              The arbitration shall be conducted in Del Rio, Texas, in Val Verde
              County, and the award of the arbitrator shall be final and
              binding. The judgment upon the award rendered by the arbitrator
              may be entered in any court having jurisdiction. By agreeing to
              these Terms, you consent to the exclusive jurisdiction and venue
              of the arbitration proceedings in Del Rio, Texas, Val Verde
              County.
            </p>
            <hr />
            <h4>Changes to the Terms</h4>
            <p>
              We reserve the right to change these Terms at any time, without
              prior notice. Any such changes will be effective immediately upon
              posting on our website. Your continued use of the Services
              following the posting of changes constitutes your acceptance of
              such changes.
            </p>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a href="mailto:info@procontainers.com.">
                info@procontainers.com.
              </a>
            </p>
            <hr />
            <Row>
              <Col lg={6}>
                <div className="TermsBox">
                  <h5>Payment terms:</h5>
                  <ul>
                    <li>Bank wire transfer any amount</li>
                    <li>
                      Up to $10,000.00 Credit Card or Company Check (10-Day Hold
                      for Clearing)
                    </li>
                    <li>Over $10,000.00 Wire transfer only.</li>
                    <li>
                      Any amount over 3500 is subject to a 3% Credit Card
                      processing fee.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={6}>
                <div className="TermsBox">
                  <h5>Accounts Receivables for Payments:</h5>
                  <ul>
                    <li>
                      <a href="mailto:ar@procontainers.com">
                        ar@procontainers.com
                      </a>
                    </li>
                    <li>
                      <b>COMFORT ADDRESS:</b> 110 US HWY 87 COMFORT TEXAS 78013
                    </li>
                    <li>
                      <b>TRUCK ENTRANCE:</b>112 US HWY 87 COMFORT TEXAS 78013
                    </li>
                    <li>
                      <b>DEL RIO ADDRESS:</b> 100 Ave T Del Rio, TX 78840
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={12}>
                <div className="TermsBox">
                  <h5>Wire Transfer Instructions:</h5>
                  <p>
                    Please adhere strictly to the following instructions: The
                    payment must be made as an FFC (For Further Credit) payment.
                    Direct wire transfers are not accepted.
                  </p>
                  <Row>
                    <Col lg={12}>
                      <h6>1. Receiving Bank</h6>
                      <h6>
                        2. The Bankers Bank Oklahoma City, <bt />{" "}
                        <small> Oklahoma ABA: 103003616</small>
                      </h6>
                      <h6>3. For Further Credit:</h6>
                      <h6>4. Amistad Bank</h6>
                      <h6>5. Account Number: 111316829</h6>
                      <h6>6. For Final Credit Of:</h6>
                      <h6>7. Account Number: 01616978</h6>
                    </Col>
                    <Col lg={12}>
                      <h6>
                        8. In The Name Of: Ranger Trailers LLC, DBA
                        ProContainers
                      </h6>
                      <p>
                        <strong>
                          International Wire transfer instructions are:
                        </strong>
                      </p>
                      <p>
                        Send funds to: The Bankers Bank
                        <br />
                        SWIFT code BBOK US 44
                        <br />
                        Payable thru Bank of New York <br />
                        Routing # 021000018 SWIFT CODE for Bank of New York IRVT
                        US 3N BNF: Amistad Bank
                        <br />
                        Account Number: 316 829
                        <br />
                        REF: Ranger Trailers LLC DBA ProContainers Company
                        Account Number: Clearing Account
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12}>
                <div className="TermsBox">
                  <h5>Warehouse Scheduling</h5>

                  <p>
                    <b>Contact Name: </b> Operations & Warehouse Managers <br />
                    <b> Company Name: </b> ProContainers <br />
                    <b> Street Address: </b>{" "}
                    <a href="https://www.google.com/maps/place/King+Surplus/@29.9864958,-98.9042339,15z/data=!4m6!3m5!1s0x865bf3259adf637d:0x79383dc7818f09a7!8m2!3d29.9864958!4d-98.9042339!16s%2Fg%2F11s8hzpm_2">
                      104 US-87, Comfort, TX 78013
                    </a>{" "}
                    <br />
                    <b> Phone Number: </b>{" "}
                    <a href="tel:830-282-0000">830-282-0000</a>
                  </p>
                </div>
              </Col>
            </Row>
            <hr />
            <h4>Logistics:</h4>
            <ul>
              <li>
                ProContainers Company (PC) can assist with shipping worldwide,
                or buyers can choose to use their own shippers or pick up items
                themselves.
              </li>
              <li>
                In certain cases, ProContainers offers free shipping; however,
                in other instances, pick-up only or buyer-paid shipping options
                are available.
              </li>
              <li>
                All freight and shipping costs, including any nonstandard fees
                imposed by the hauler or government, are the sole responsibility
                of the purchaser.
              </li>
              <li>
                ProContainers Company will not be held responsible for any
                damage or claims related to shipping, and the buyer agrees to
                hold ProContainers Company harmless in such cases.
              </li>
              <li>
                As a customer service, ProContainers Company may assist buyers
                with packaging and shipping, but the buyer is responsible for
                any damages that occur during transit.
              </li>
              <li>
                ProContainers Company does not file freight claims, and the
                buyer may be responsible for any additional charges related to
                delivery, special deliveries, or residential or lift gate
                delivery fees.
              </li>
              <li>
                Buyers are encouraged to use their own shippers or pick up items
                themselves. Out-of-state buyers who pick up items at one of
                ProContainers Company's warehouses will be responsible for
                paying sales tax. However, if the item is shipped, the State of
                Texas provides an automatic sales exemption.
              </li>
              <li>
                ProContainers Company is not a shipping company and does not
                profit from shipping. All buyers pay the shipper of their
                choice, whether it's one of ProContainers Company's discount
                shippers or their own.
              </li>
              <li>
                In most cases, ProContainers Company does not accept payment for
                shipping. Instead, buyers pay the shipper directly to keep the
                price point down for the buyers.
              </li>
              <li>
                ProContainers Company maintains significant general liability
                insurance.
              </li>
              <li>
                Any sales tax applicable now or in the future must be paid by
                the customer. If it is discovered after the fact that sales tax
                is due and ProContainers Company is required to pay said taxes
                on behalf of the buyer, the buyer will repay ProContainers
                Company, including penalties and interest.
              </li>
              <li>
                All bills are payable in Comfort, Texas. A charge of 1.5% per
                month or the maximum allowed by law will be charged on all past
                due invoices.
              </li>
              <li>
                Items may arrive partially disassembled, and ProContainers
                Company offers consultation, training, assembly, erection, and
                setup services for a separate fee, which is not included in the
                contract.
              </li>
              <li>
                The purchaser represents that they are familiar with the item or
                items purchased and are capable of assembling, erecting, setting
                up, and operating the equipment without assistance from
                ProContainers Company. ProContainers Company is not responsible
                for any problems caused by improper assembly, erection, setup,
                or operation, and makes no warranty unless otherwise stated in
                writing and signed by an officer of the corporation.
              </li>
            </ul>
            <hr />

            <hr />
            <h4>Returns, Refunds, and Restocking Fees:</h4>
            <ul>
              <li>
                ProContainers guarantees their products with a 100% commitment.
                Should a mistake in the description or photos occur, returns
                will be accepted without any restocking fees.
              </li>
              <li>
                ProContainers may accept returns within fourteen days if the
                customer has made an error in purchasing. In cases of trading
                for another item, a 10% processing fee will be applied. For
                cashback requests, a 20% restocking fee will be deducted, in
                addition to rigging, packaging, shipping, and other associated
                fees, such as commissions.
              </li>
              <li>
                Please note that packaging and shipping fees are non-refundable
                for any shipper, including ProContainers and items with free
                shipping.
              </li>
              <li>
                ProContainers does not issue refunds for damages incurred during
                shipping. Instead, the carrier and their insurance will
                compensate the buyer directly for any damage or repair losses.
                ProContainers will assist in the refund process as needed.
              </li>
              <li>
                Freight claims must be pursued through the carrier. If damage is
                not noted on the Bill of Lading (BOL) upon receipt and
                documented with photographs, the consignee will be held
                responsible and cannot hold either the freight company or
                ProContainers liable.
              </li>
              <li>
                While ProContainers will provide assistance whenever possible,
                it is essential that customers adhere to the established
                guidelines. Failure to do so may result in an inability to
                provide support.
              </li>
              <li>
                To initiate a return to ProContainers, a signed return
                authorization from ProContainers must be included in the
                packaging slip. Without this authorization, the warehouse will
                not accept returns.
              </li>
              <li>
                Please be advised that all return responsibilities rest with the
                buyer.
              </li>
            </ul>
            <hr />
            <h4>Summary of Terms and Conditions:</h4>
            <ul>
              <li>
                Mediation: Parties agree to immediate mediation within 30 days
                of notice in case of disputes. Mediation takes place in Del Rio,
                Texas, Val Verde County, Texas.
              </li>
              <li>
                Modification: Modifications to the agreement must be in writing
                and executed by both parties.
              </li>
              <li>
                Jurisdiction/Venue: Agreement is governed and enforced under the
                laws of the State of Texas. Proper venue for enforcement is Val
                Verde County, Texas.
              </li>
              <li>
                Sever-ability: Invalid provisions will be modified or removed,
                and the rest of the agreement will remain in effect.
              </li>
              <li>
                Prior Agreements Superseded: This agreement supersedes all prior
                correspondence, discussions, and agreements.
              </li>
              <li>
                Force Majeure: Neither party is liable for unavoidable delays or
                interruptions in performance beyond their control.
              </li>
              <li>
                Assignment: Purchaser cannot assign or transfer this agreement
                without the seller's written consent.
              </li>
              <li>
                No Warranty: All items are sold as-is, where-is, and with all
                faults and defects.
              </li>
              <li>
                Inspection and Acceptance of Goods: Buyers are responsible for
                inspecting items before purchasing. ProContainers Company
                invites purchasers to inspect goods at their locations.
                Purchaser accepts goods in their current condition, based on
                their inspection or refusal to inspect, and waives any rights to
                repudiate or reject the goods upon delivery. Failure to inspect
                does not relieve the bidder/buyer of their responsibilities.
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfService;
