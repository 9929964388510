import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify/dist/react-toastify";
import axiosClient from "../../axiosClient";
import { setPaymentMethod } from "../Slices/CartSlice";
import { useDispatch } from "react-redux";

function CheckOutPreferencesForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [cardId, setCardId] = useState("");
  const userData = JSON.parse(localStorage.getItem("login"));
  const [formData, setFormData] = useState({ alais: "" });
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
      toast.error(
        "Unfortunately, We couldn't add your card. Please check the information and try again."
      );
    } else {
      if (formData.alais === "") {
        setLoading(false);
        toast.error("Please enter alais name");
        return true;
      }

      var body = {
        id: 0,
        nameAlias: formData.alais,
        paymentMethodId: paymentMethod.id,
        cardLastDigits: paymentMethod.card.last4,
        expireDate:
          paymentMethod.card.exp_month + "/" + paymentMethod.card.exp_year,
        cardType: paymentMethod.card.brand,
        userId: userData.user.id,
      };

      axiosClient
        .post("savePaymentPreferenceInfo", body)
        .then((res) => {
          if (res != null && props.isFromPB) {
            var shipData = [];
            shipData.push(res);
            dispatch(setPaymentMethod(shipData));

            var selectOption = {};
            selectOption.value = res.id;
            selectOption.label = res.nameAlias;
            props.SetValFromPb(selectOption);
          }

          setCardId(paymentMethod.card.last4);
          setLoading(false);
          setErrorMessage(null);
          setShow(true);
          toast.success("Your bank details have been successfully added!");
          //setFormData({alais: ''});
          props.refreshData();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to save card details");
          setLoading(false);
          setShow(false);
        });
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-input">
            <label>Nickname </label>
            <input
              id="name"
              name="alais"
              value={formData.alais}
              onChange={handleChange}
            />
          </div>
          <CardElement className="form-control" />
        </div>
        <div className="d-flex flex-row justify-content-end">
          {loading ? (
            <Button
              variant="primary"
              className="btn-block bg-lg mt-2"
              disabled
              style={{ position: "relative" }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  color: "white",
                  top: "calc(50% - 12px)",
                  left: "calc(50% - 12px)",
                }}
              />
            </Button>
          ) : (
            <Button
              variant="primary"
              className="btn-block bg-lg mt-2"
              type="submit"
            >
              {" "}
              Save{" "}
            </Button>
          )}
        </div>
      </form>
    </>
  );
}
export default CheckOutPreferencesForm;
