import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";
import "./EquipmentServicingForm.css";

function EquipmentServicingForm() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);
  const [images, setImages] = useState([]);
  const handleMultipleImages = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    mailingAddress: "",
    companyName: "",
    companyWebsite: "",
    industry: "",
    experience: "",
    howDidYouHear: "",
    previousExperience: "",
    assetTypes: "",
    communicationMethod: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataEntries = Object.entries(formData);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "Equipment Servicing",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          mailingAddress: "",
          companyName: "",
          companyWebsite: "",
          industry: "",
          experience: "",
          howDidYouHear: "",
          previousExperience: "",
          assetTypes: "",
          communicationMethod: "",
        });
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  };

  const [formData2, setFormData2] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    mailingAddress: "",
    typeOfAsset: "",
    conditionOfAsset: "",
    quantityOfAsset: "",
    locationOfAsset: "",
    descriptionOfAsset: "",
    photosOfAsset: [],
  });

  function handleChange2(event) {
    const { name, value } = event.target;
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleImageChange2(event) {
    const files = Array.from(event.target.files);
    const images = files.map((file) => URL.createObjectURL(file));
    setFormData((prevData) => ({
      ...prevData,
      photosOfAsset: images,
    }));
  }

  function handleSubmit2(event) {
    event.preventDefault();
    const formDataEntries = Object.entries(formData2);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "Asset Submission Form",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          mailingAddress: "",
          companyName: "",
          companyWebsite: "",
          industry: "",
          experience: "",
          howDidYouHear: "",
          previousExperience: "",
          assetTypes: "",
          communicationMethod: "",
        });
        handleClose2();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  }
  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>
            Finders keepers, earn some cash! <sup>TM</sup>
          </h3>
          <p>
            The ultimate treasure hunt – find valuable assets and earn a
            finder's fee for your efforts!
          </p>
        </div>
        <Link
          to="#"
          onClick={handleShow}
          className="btn btn-white btn-icon mb-2 "
        >
          Become a Finder{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
        <Link
          to="#"
          onClick={handleShow2}
          className="btn btn-white btn-icon mb-2"
        >
          Submit your discoveries{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="FinderPopup RecyclingModal"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Become a Finder </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sellToUsPopup">
              <h6>Personal Information</h6>
              <div className="row">
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="fullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Your Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="mailingAddress">
                    <Form.Label>Mailing Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Mailing Address"
                      name="mailingAddress"
                      value={formData.mailingAddress}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <h6>Business Information</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Company Name (if applicable)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Company Name (if applicable)"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="companyWebsite">
                    <Form.Label>Company Website (if applicable)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Company Website (if applicable)"
                      name="companyWebsite"
                      value={formData.companyWebsite}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="industry">
                    <Form.Label>Industry or Niche</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Industry or Niche"
                      name="industry"
                      value={formData.industry}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="previousExperience">
                    <Form.Label>Years of Experience in Industry</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Years of Experience in Industry"
                      name="previousExperience"
                      value={formData.previousExperience}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <h6>Finder Details</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="howDidYouHear">
                    <Form.Label>
                      How did you hear about ProContainers?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="howDidYouHear"
                      value={formData.howDidYouHear}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="experience">
                    <Form.Label>
                      Do you have any previous experience as a finder?{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="assetTypes">
                    <Form.Label>
                      What types of assets or opportunities do you typically
                      come across?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="assetTypes"
                      value={formData.assetTypes}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="communicationMethod">
                    <Form.Label>
                      What is your preferred method of communication? (phone,
                      email, etc.)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="communicationMethod"
                      value={formData.communicationMethod}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </div>
              <h6>Terms and Agreements</h6>
              <ul>
                <li>
                  By submitting this form, you agree to the Finder Agreement
                  terms and conditions of ProContainers.
                </li>
                <li>
                  You acknowledge that you will receive a finder's fee for any
                  assets or opportunities that you refer to ProContainers.
                </li>
                <li>
                  You understand that ProContainers may conduct a background
                  check and/or verify any information submitted on this form.
                </li>
              </ul>

              <i>
                <small className="text-danger">
                  Please fill out the form completely and click the "Submit
                  Application" button to apply to become a finder for
                  ProContainers.
                </small>
              </i>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        className="FinderPopup RecyclingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Asset Submission Form </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sellToUsPopup">
            <h6>Personal Information</h6>
            <Form className="row">
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="fullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Full Name"
                    name={"fullName"}
                    onChange={handleChange2}
                    value={formData2.fullName}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email"
                    name={"email"}
                    onChange={handleChange2}
                    value={formData2.email}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Phone Number"
                    name={"phoneNumber"}
                    onChange={handleChange2}
                    value={formData2.phoneNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="mailingAddress">
                  <Form.Label>Mailing Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Mailing Address"
                    name={"mailingAddress"}
                    onChange={handleChange2}
                    value={formData2.mailingAddress}
                  />
                </Form.Group>
              </Col>
              <h6>Asset Details</h6>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="typeOfAsset">
                  <Form.Label>Type of Asset</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type of Asset"
                    name={"typeOfAsset"}
                    onChange={handleChange2}
                    value={formData2.typeOfAsset}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="conditionOfAsset">
                  <Form.Label>Condition of Asset</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Condition of Asset"
                    name={"conditionOfAsset"}
                    onChange={handleChange2}
                    value={formData2.conditionOfAsset}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="quantityOfAsset">
                  <Form.Label>Quantity of Asset</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Quantity of Asset"
                    name={"quantityOfAsset"}
                    onChange={handleChange2}
                    value={formData2.quantityOfAsset}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="locationOfAsset">
                  <Form.Label>Location of Asset</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location of Asset"
                    name={"locationOfAsset"}
                    onChange={handleChange2}
                    value={formData2.locationOfAsset}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="descriptionOfAsset">
                  <Form.Label>Description of Asset</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description of Asset"
                    name={"descriptionOfAsset"}
                    onChange={handleChange2}
                    value={formData2.descriptionOfAsset}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Photos of Asset (optional)</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleMultipleImages}
                    multiple
                  />
                  <div className="ModalImagePopup">
                    <Image className="UploadImg" src={images} />
                  </div>
                </Form.Group>
              </Col>
              <ul>
                <li>
                  By submitting this form, you agree to the terms and conditions
                  of ProContainers.
                </li>
                <li>
                  You acknowledge that you have the legal right to sell or
                  transfer ownership of the asset(s) listed on this form.
                </li>
                <li>
                  You understand that ProContainers may conduct a background
                  check and/or verify any information submitted on this form.
                </li>
              </ul>
            </Form>
            <i>
              <small className="text-danger">
                Please fill out the form completely and click the "Submit Asset
                Information" button to inform ProContainers of any assets that
                you have discovered and would like to sell or transfer ownership
                of. A ProContainers representative will contact you within 1-2
                business days to discuss further details.
              </small>
            </i>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit2}>
            Submit Asset Information
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EquipmentServicingForm;
