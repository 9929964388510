import React, { useState, useEffect } from "react";
import { Card, Button, Table, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { toast } from "react-toastify/dist/react-toastify";
import { handleAddCart, getTotals } from "../Slices/CartSlice";
import { useDispatch } from "react-redux";

function SaveForLater() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [saveForLaters, setSaveForLaters] = useState([]);
  const navigate = useNavigate();

  const handleShow = () => {
    if (show == null) {
      if (show == null) {
        setShow(true);
      }
    } else {
      setShow(!show);
    }
  };

  useEffect(() => {
    getSaveForLaters();
  }, []);

  const refreshData = () => {
    getSaveForLaters();
  };

  function addCart(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData == null) {
      navigate("/login");
    } else {
      deleteSaveLater(selectedItem.id);
      dispatch(handleAddCart(selectedItem));
      dispatch(getTotals());

      toast.success("Container Added to Cart");
    }
  }

  function detailPage(itemId) {
    navigate(`/InventoryDetail/${itemId}`, { state: { id: itemId } });
  }
  return (
    <>
      <Card style={{ width: "100%" }} className="mb-4">
        <Card.Body>
          <Card.Title> Saved for Later</Card.Title>
          <div className="order-history">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Beauty Shot</th>
                  <th>price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {saveForLaters &&
                  saveForLaters.map((item) => (
                    <tr key={item.id}>
                      <td style={{ width: "80% !important" }}>
                        {item.description}
                      </td>
                      <td style={{ width: "10% !important" }}>
                        {(() => {
                          if (item.coverImageURL) {
                            return (
                              <div
                                className="img-fluid"
                                style={{ height: "100%" }}
                                dangerouslySetInnerHTML={{
                                  __html: item.coverImageURL,
                                }}
                              />
                            );
                          } else {
                            return (
                              <Image
                                style={{ width: "100px" }}
                                src="/assets/images/not-found.png"
                              />
                            );
                          }
                        })()}
                      </td>
                      <td>$ {item.salePrice}</td>

                      <td className="w-25">
                        <Button
                          variant="primary"
                          onClick={() => addCart(item)}
                          className="me-2"
                        >
                          Add To Cart
                        </Button>

                        <Button
                          variant="primary"
                          onClick={() => {
                            detailPage(item.id);
                          }}
                          className="me-2"
                        >
                          View Item
                        </Button>

                        <Button
                          variant="primary"
                          onClick={() => {
                            deleteSaveLater(item.id);
                          }}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );

  function getSaveForLaters() {
    const userData = JSON.parse(localStorage.getItem("login"));
    if (userData && userData.user) {
      axiosClient
        .get("getSaveLatersInfoByUserId/" + Number(userData.user.id))
        .then((res) => {
          setSaveForLaters(res);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function deleteSaveLater(id) {
    axiosClient
      .get("deleteSaveLater/" + id)
      .then((res) => {
        getSaveForLaters();
        toast.success("Item removed Successfully!");
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export default SaveForLater;
