import React, { useState, useEffect } from "react";
import { Nav, Spinner, ListGroup, ListGroupItem, Alert } from "react-bootstrap";
import axiosClient from "../../axiosClient";
import { useSelector } from "react-redux";
import getStateAbbreviation from "../../Helper/getStateAbbreviation";
import { carriersId, carriersName } from "../../constants";
import ShippingDDList from "../Shippings/ShippingDDList";
import uuid from "react-uuid";
import setWeightCriteriaForTLT from "../../Helper/setWeightCriteriaForTLT";
import { EstimatedConditionWeight } from "../../constants";
import "./ShipeEgine.css";

function CarrierQuote(props) {
  const [loadingData, setLoadingData] = useState(false);
  const [activeTab, setActiveTab] = useState(carriersId.FedEx);
  const [rates, setRates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const cart = useSelector((state) => state.cart);
  const [selectedItem, setSelectedItem] = useState({});
  var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  let packagesData = [];
  const [isAboveTotalEstimated70, setIsAboveTotalEstimated70] = useState(false);

  let address1 = "100 Ave T";
  let postal = "78840";
  let city = "Del Río";
  let itemsExit = false;

  const handleTabClick = (eventKey) => {
    setLoadingData(true);
    setActiveTab(eventKey);
    props.cofirmPostQuote(null);
    if (cart.shippingAddres[0] != null && cart.shippingAddres[0] != undefined) {
      if (eventKey == carriersName.UPS) {
        getPostRates(carriersId.UPS);
      }
      if (eventKey == carriersName.Stamps) {
        getPostRates(carriersId.Stamps);
      }
      if (eventKey == carriersName.FedEx) {
        getPostRates(carriersId.FedEx);
      }
    }
  };

  function countPostItemInCarts() {
    let count = 0;
    for (const item of cartItems) {
      if (
        Number(item.estimatedTotalWeight) < 150 &&
        Number(item.widthInches) <= 96 &&
        Number(item.heightInches) <= 96 &&
        Number(item.lengthInches) <= 96
      ) {
        count++;
      }
    }
    return count;
  }

  const handleSubmit = () => {
    if (cart.shippingAddres[0] != null && cart.shippingAddres[0] != undefined) {
      props.cofirmPostQuote(null);
      if (activeTab == carriersName.UPS) {
        getPostRates(carriersId.UPS);
      }
      if (activeTab == carriersName.Stamps) {
        getPostRates(carriersId.Stamps);
      }
      if (activeTab == carriersName.FedEx) {
        getPostRates(carriersId.FedEx);
      }
    }
  };

  useEffect(() => {
    setErrorMessage("");
    setActiveTab(carriersName.FedEx);
    if (cart.shippingAddres[0] != null && cart.shippingAddres[0] != undefined) {
      getCartDetails();
      getPostRates(carriersId.FedEx);
    }
  }, []);

  useEffect(() => {
    setErrorMessage("");

    handleSubmit();
  }, [cart]);

  function handleFormSubmit(rate) {
    setSelectedItem({ data: rate });
  }

  useEffect(() => {
    if (selectedItem && selectedItem.data) {
      props.cofirmPostQuote(selectedItem);
    }
  }, [selectedItem]);

  function getPostRates(carrierId) {
    getCartDetails();
    setLoadingData(true);
    if (cart.shippingAddres[0] == undefined && cart.shippingAddres[0] == null) {
      setErrorMessage("Please Select Shipping Address");
      setLoadingData(false);
      return true;
    }

    setErrorMessage("");
    let stateAbbre = getStateAbbreviation(cart.shippingAddres[0].state);

    const postData = {
      carrierId: carrierId,
      toAddress: {
        name: cart.shippingAddres[0].nameAlias,
        company_name: cart.shippingAddres[0].companyName,
        address_line1: cart.shippingAddres[0].address1,
        address_line2: " ",
        city_locality: cart.shippingAddres[0].city,
        state_province: stateAbbre,
        postal_code: cart.shippingAddres[0].zip_PostalCode,
        country_code: cart.shippingAddres[0].country,
        phone: cart.shippingAddres[0].phoneNumber,
        shipeEngineContact: {
          name: cart.shippingAddres[0].nameAlias,
          phone_number: cart.shippingAddres[0].phoneNumber,
          email: cart.shippingAddres[0].email,
        },
      },
      fromAddress: {
        name: "Pro Container",
        company_name: "Pro Container",
        address_line1: address1,
        address_line2: "",
        city_locality: city,
        state_province: "TX",
        postal_code: postal,
        country_code: "US",
        phone: "830-775-2580", // TODO : Change this phone number
        shipeEngineContact: {
          name: "Pro Container",
          phone_number: "830-775-2580",
          email: "shipping@procontainers.com", // TODO :Create this email
        },
      },

      packages: packagesData,
    };

    if (postData.packages != null && itemsExit) {
      axiosClient
        .post("getPostalShipRates", postData)
        .then((response) => {
          let r = JSON.parse(response).rate_response.rates;

          if (r && r.length > 0) {
            var sortedDate = r.sort(
              (a, b) => a.shipping_amount.amount - b.shipping_amount.amount
            );

            var updatevalues = sortedDate.map((itm) => ({
              ...itm,
              shipping_amount: {
                ...itm.shipping_amount,
                amount: itm.shipping_amount.amount * 1.35,
              },
            }));
            setRates([]);
            setRates(updatevalues);

            setLoadingData(false);
          } else {
            var err = JSON.parse(response).rate_response.errors[0].message;
            setRates([]);
            setLoadingData(false);
            setErrorMessage(err);
          }
        })
        .catch((error) => {
          setErrorMessage(error);
          setLoadingData(false);
        });
    }

    setLoadingData(false);
  }

  function getCartDetails() {
    packagesData = [];
    setLoadingData(true);
    let estimateTotal = 0;
    let addedTotalweight = 0;
    let totalQtyItemW = 0;
    cartItems.forEach((item) => {
      if (setWeightCriteriaForTLT(item) == false) {
        itemsExit = true;

        totalQtyItemW =
          Number(item.selectedQty) * Number(item.estimatedTotalWeight);
        estimateTotal = estimateTotal + Number(item.estimatedTotalWeight);
        if (totalQtyItemW > EstimatedConditionWeight.maxPostalShipping) {
          for (
            addedTotalweight;
            totalQtyItemW > addedTotalweight;
            addedTotalweight += EstimatedConditionWeight.maxPostalShipping
          ) {
            let pckg = {
              package_code: " ",
              freight_class: 0,
              nmfc_code: " ",
              description: item.description,
              dimensions: {
                length: Number(item.lengthInches),
                width: Number(item.widthInches),
                height: Number(item.heightInches),
                unit: "inches",
              },
              weight: {
                value: EstimatedConditionWeight.maxPostalShipping,
                unit: "pounds",
              },
              quantity: 1,
              stackable: false,
              hazardous_materials: false,
            };

            let sumCurrent =
              addedTotalweight + EstimatedConditionWeight.maxPostalShipping;
            if (
              pckg != undefined &&
              pckg != null &&
              totalQtyItemW > sumCurrent
            ) {
              packagesData.push(pckg);
            }
          }

          if (totalQtyItemW < addedTotalweight) {
            let lastPackage =
              totalQtyItemW -
              (addedTotalweight - EstimatedConditionWeight.maxPostalShipping);

            let pckg2 = {
              package_code: " ",
              freight_class: 0,
              nmfc_code: " ",
              description: item.description,
              dimensions: {
                length: Number(item.lengthInches),
                width: Number(item.widthInches),
                height: Number(item.heightInches),
                unit: "inches",
              },
              weight: {
                value: Number(lastPackage),
                unit: "pounds",
              },
              quantity: 1,
              stackable: false,
              hazardous_materials: false,
            };

            if (pckg2 != undefined && pckg2 != null) {
              packagesData.push(pckg2);
            }
          }
        }

        if (totalQtyItemW < EstimatedConditionWeight.maxPostalShipping) {
          let packOne = {
            package_code: " ",
            freight_class: 0,
            nmfc_code: " ",
            description: item.description,
            dimensions: {
              length: Number(item.lengthInches),
              width: Number(item.widthInches),
              height: Number(item.heightInches),
              unit: "inches",
            },
            weight: {
              value:
                Number(item.estimatedTotalWeight) * Number(item.selectedQty),
              unit: "pounds",
            },
            quantity: Number(item.selectedQty),
            stackable: false,
            hazardous_materials: false,
          };

          if (packOne != undefined && packOne != null) {
            packagesData.push(packOne);
          }
        }
      }
    });

    // console.log("postal items =====>>>", packagesData);

    if (estimateTotal > EstimatedConditionWeight.maxUspsShipping) {
      setIsAboveTotalEstimated70(true);
    }

    setLoadingData(false);
  }
  return (
    <div className="card h-100">
      <div className="card-header">Postal Quote</div>
      <div className="card-body p-2 m-0">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="card">
              <div className="card-header">Items in Shipment</div>
              <div className="card-body p-2 m-0">
                <div>
                  {cartItems != null &&
                    cartItems != undefined &&
                    cartItems.map((product) => (
                      <>
                        {setWeightCriteriaForTLT(product) == false && (
                          <div
                            className="d-flex flex-column justify-content-between align-items-cente p-1 m-1 rounded shadow-sm"
                            key={product.id}
                          >
                            <span className="text-truncate fw-bold">
                              {product.description}
                            </span>
                            <i> Qty: {product.selectedQty}</i>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div>
              <Nav
                variant="pills"
                className="flex-row justify-content-start mb-2"
                activeKey={activeTab}
                onSelect={handleTabClick}
              >
                <Nav.Item>
                  <Nav.Link eventKey={carriersName.FedEx}>FedEx</Nav.Link>
                </Nav.Item>
                {/* {Number(props.item.estimatedTotalWeight) < StamLimit &&
                    <Nav.Item>
                      <Nav.Link eventKey={carriersName.Stamps}>USPS</Nav.Link>
                    </Nav.Item>
                  } */}

                {isAboveTotalEstimated70 == false && (
                  <>
                    {cartItems != null && countPostItemInCarts() < 2 && (
                      <Nav.Item>
                        <Nav.Link eventKey={carriersName.Stamps}>USPS</Nav.Link>
                      </Nav.Item>
                    )}
                  </>
                )}
                <Nav.Item>
                  <Nav.Link eventKey={carriersName.UPS}>UPS</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            {/* {(() => { */}
            <div>
              <div>
                {loadingData == true && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50%",
                    }}
                  >
                    <Spinner
                      animation="border"
                      style={{
                        color: "#1A2C55",
                        width: "5rem",
                        height: "5rem",
                      }}
                    />
                  </div>
                )}
              </div>

              {errorMessage != "" && loadingData == false && (
                <Alert
                  variant="danger"
                  onClose={() => setErrorMessage("")}
                  dismissible
                >
                  <Alert.Heading>Error !</Alert.Heading>
                  <p>{errorMessage}</p>
                </Alert>
              )}
              {loadingData ? (
                <ListGroup>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50%",
                    }}
                  >
                    <Spinner
                      animation="border"
                      style={{
                        color: "#1A2C55",
                        width: "5rem",
                        height: "5rem",
                      }}
                    />
                  </div>
                </ListGroup>
              ) : (
                <>
                  {(cart.shippingAddres[0] == null ||
                    cart.shippingAddres[0] == undefined) && (
                    <div className="col-md-6">
                      <ShippingDDList key={uuid.toString()} />
                    </div>
                  )}
                  {cart.shippingAddres[0] != null &&
                    cart.shippingAddres[0] != undefined && (
                      <ListGroup className="postal">
                        {rates != undefined &&
                          rates.length > 0 &&
                          rates.map((rate) => (
                            <>
                              {(rate.package_type == undefined ||
                                rate.package_type == "package" ||
                                rate.package_type == null) &&
                                rate.service_code != "usps_media_mail" && (
                                  <ListGroupItem
                                    key={rate.rate_id}
                                    className={
                                      selectedItem &&
                                      selectedItem.data &&
                                      selectedItem.data.rate_id == rate.rate_id
                                        ? "blue-background"
                                        : ""
                                    }
                                    onClick={() => {
                                      handleFormSubmit(rate);
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center item_detail">
                                      <div>
                                        <b className="mb-0">
                                          {rate.service_type}{" "}
                                        </b>
                                        <small>
                                          {" "}
                                          {rate.delivery_days} days
                                        </small>

                                        {rate.warning_messages.length > 0 && (
                                          <div className="text-warning ">
                                            {rate.warning_messages.map(
                                              (msg, i) => (
                                                <p
                                                  key={i}
                                                  className="mb-0 fs-12"
                                                >
                                                  {msg}
                                                </p>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        <h6
                                          className={
                                            selectedItem &&
                                            selectedItem.data &&
                                            selectedItem.data.rate_id ==
                                              rate.rate_id
                                              ? "blue-background m-0"
                                              : "m-0"
                                          }
                                        >
                                          $
                                          {rate.shipping_amount.amount.toFixed(
                                            2
                                          )}{" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </ListGroupItem>
                                )}
                            </>
                          ))}
                      </ListGroup>
                    )}
                </>
              )}
            </div>
            {/* })()} */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CarrierQuote;
