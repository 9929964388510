import React, { useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import "./ForgotPassOtp.css";

function ForgotPassOtp() {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  // const [loading, setLoading] = useState(false);

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index] = value;
      return newInputValues;
    });

    if (index < inputRefs.length - 1 && value.length > 0) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (
      event.key === "Backspace" &&
      inputValues[index].length === 0 &&
      index > 0
    ) {
      inputRefs[index - 1].current.focus();
    }
  };

  useDocumentTitle("PC | Verification");
  return (
    <div className="main-login-wrap">
      <Row className="m-0 bg-light-gray">
        <Col lg={6} className="bg-white">
          <div className="logoInfo">
            <div className="login-logo">
              <Image src="/assets/images/logo.webp" />
            </div>
            <p>
              "Unlock boundless possibilities with just one click. Explore our
              container wonderland and find the perfect box to hold your
              dreams."
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="login-box">
            <h3 className="mb-2">Forgot Password</h3>
            <p className="max-100">Please enter your one time password. </p>
            <div className="login-input-width">
              <div className="form-input">
                <label>Enter OTP</label>
                <div className="otpboxWrap">
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[0]}
                    onChange={(event) => handleInputChange(event, 0)}
                    onKeyDown={(event) => handleKeyDown(event, 0)}
                    ref={inputRefs[0]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[1]}
                    onChange={(event) => handleInputChange(event, 1)}
                    onKeyDown={(event) => handleKeyDown(event, 1)}
                    ref={inputRefs[1]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[2]}
                    onChange={(event) => handleInputChange(event, 2)}
                    onKeyDown={(event) => handleKeyDown(event, 2)}
                    ref={inputRefs[2]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[3]}
                    onChange={(event) => handleInputChange(event, 3)}
                    onKeyDown={(event) => handleKeyDown(event, 3)}
                    ref={inputRefs[3]}
                  />
                </div>
              </div>
              <i className="otp-link">
                Didn’t recive otp? <Link to="/"> Resend</Link>
              </i>
              <Link to="/" className="btn btn-blue d-block w-100 btn-sign">
                {" "}
                Submit
              </Link>
            </div>
            <h6 className="signup-link">
              Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassOtp;
