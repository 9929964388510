import React, { useRef, useState, useEffect } from "react";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify/dist/react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./VerifyAccount.css";
import axiosClient from "../../axiosClient";

function VerifyAccount() {
  const navigate = useNavigate();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  useDocumentTitle("PC | Verification");
  const { email } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index] = value;
      return newInputValues;
    });

    if (index < inputRefs.length - 1 && value.length > 0) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (
      event.key === "Backspace" &&
      inputValues[index].length === 0 &&
      index > 0
    ) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (inputValues.some((value) => value == "")) {
      toast.error("Enter 4 digits OTP code.");
      setLoading(false);
      return;
    }

    if (password == "") {
      toast.error("Please enter your password.");
      setLoading(false);
      return;
    }
    if (confirmPassword == "") {
      toast.error("Please confirm your password.");
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      const otpCode = inputValues.join("");

      const requestData = {
        email: email,
        otp: otpCode,
        token: " ",
        newPassword: password,
      };

      const response = await axiosClient.post(
        "ConfirmResetPassword",
        requestData
      );
      if (response.statusCode === 200) {
        toast.success("Reset password successfully");
        setLoading(false);
        navigate(`/Login`);
      } else {
        toast.error("Invalid OPT code.");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something wrong");
      setLoading(false);
    }
  };

  const resendCode = async (e) => {
    e.preventDefault();

    setInputValues([]);
    setPassword("");
    setConfirmPassword("");
    setPasswordsMatch(true);

    setLoading(true);
    if (!email) {
      toast.error("Email is not exist.");
      setLoading(false);
      return;
    }
    try {
      const response = await axiosClient.get("ResetPasswordRequest/" + email);
      if (response) {
        setLoading(false);
        toast.success("The OTP  code has been sent to your email.");
      } else {
        setLoading(false);
        toast.error("Email does not exist");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <div className="main-login-wrap">
      <Row className="m-0 bg-light-gray">
        <Col lg={6} className="bg-white">
          <div className="logoInfo">
            <div className="login-logo">
              <Image src="/assets/images/logo.webp" />
            </div>
            <p>
              "Unlock boundless possibilities with just one click. Explore our
              container wonderland and find the perfect box to hold your
              dreams."
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="login-box">
            <h3>Verify Your Account</h3>
            <p className="max-100">
              We have sent you an email at {email} Please check your email and
              enter the correct OTP code to verify your account.
            </p>
            <div className="login-input-width">
              <div className="form-input">
                <label>Enter OTP</label>
                <div className="otpboxWrap">
                  <input
                    type="text"
                    maxLength="1"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    value={inputValues[0]}
                    onChange={(event) => handleInputChange(event, 0)}
                    onKeyDown={(event) => handleKeyDown(event, 0)}
                    ref={inputRefs[0]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[1]}
                    inputmode="numeric"
                    pattern="[0-9]*"
                    onChange={(event) => handleInputChange(event, 1)}
                    onKeyDown={(event) => handleKeyDown(event, 1)}
                    ref={inputRefs[1]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[2]}
                    inputmode="numeric"
                    pattern="[0-9]*"
                    onChange={(event) => handleInputChange(event, 2)}
                    onKeyDown={(event) => handleKeyDown(event, 2)}
                    ref={inputRefs[2]}
                  />
                  <input
                    type="text"
                    maxLength="1"
                    value={inputValues[3]}
                    inputmode="numeric"
                    pattern="[0-9]*"
                    onChange={(event) => handleInputChange(event, 3)}
                    onKeyDown={(event) => handleKeyDown(event, 3)}
                    ref={inputRefs[3]}
                  />
                </div>
              </div>
              <i className="otp-link">
                Didn't receive OTP? <a onClick={resendCode}> Resend</a>
              </i>

              <div className="mt-5">
                <div className="form-input ">
                  <label>Enter Your New Password</label>
                  <div className="input-icon">
                    <input
                      type={showPassword ? "text" : "password"}
                      name={"password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <a onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <Icon icon="ant-design:eye-filled" />
                      ) : (
                        <Icon icon="mdi:eye-off" />
                      )}
                    </a>
                  </div>
                </div>
                <div className="form-input">
                  <label>Confirm Your Password</label>
                  <div className="input-icon">
                    <input
                      type={showPassword ? "text" : "password"}
                      name={"password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <a onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <Icon icon="ant-design:eye-filled" />
                      ) : (
                        <Icon icon="mdi:eye-off" />
                      )}
                    </a>
                  </div>
                </div>
              </div>

              {loading ? (
                <button
                  type="button"
                  className="btn btn-blue d-block w-100 btn-sign mt-5"
                  disabled
                  style={{ position: "relative" }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{
                      color: "white",
                      top: "calc(50% - 12px)",
                      left: "calc(50% - 12px)",
                    }}
                  />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-blue d-block w-100 btn-sign mt-5"
                >
                  {" "}
                  Submit
                </button>
              )}
            </div>
            <h6 className="signup-link">
              Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default VerifyAccount;
