export default function setWeightCriteriaForTLT(item) {

   if (( Number(item.estimatedTotalWeight) > 150 ) && ( Number(item.widthInches) <= 96
    && Number(item.heightInches) <= 96 && Number(item.lengthInches) <= 96)) {
    return true;
  }

  if (( Number(item.estimatedTotalWeight) < 150 ) && ( Number(item.widthInches) <= 96
  && Number(item.heightInches) <= 96 && Number(item.lengthInches) <= 96)) {

  return false;
}

    return null;
 
    

}
