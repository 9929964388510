import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import axiosClient from "../../../axiosClient";
import ItemPagenation from "../../../Pagination/ItemPagination";
import FilterBox from "../FilterBox/FilterBox";
import InventoryProduct from "../InventoryProduct/InventoryProduct";
import uuid from "react-uuid";
import "./InventoryroductSection.css";
import {
  categoryRemove,
  removeEndPrice,
  removeStartPrice,
  setItemLocation,
  setItemCondition,
  industryRemove,
  removeItemBrands,
  removeItemCondition,
  typeRemove,
  setQueryText,
  removeItemLocation,
  colorsRemove,
  accessorialsRemove,
  removeisBuy,
  removeisRent,
} from "../../Slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../Slices/ProductSlice";
import "./InventoryroductSection.css";
import Category from "../../Category/Category";
import Industry from "../../Industry/Industry";
import Condition from "../../ContainerCondition/Condition";

function InventoryPoductSection(props) {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [processing, setProcessing] = useState(false);
  const form1 = useRef(null);
  const filterState = useSelector((state) => state.filter);
  const productState = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const location = useLocation();
  const [bodyClass, setBodyClass] = useState("");
  const myScrollableElement = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [searchParamsHandle, setSearchParamsHandle] = useSearchParams();
  const [defaultSortOption, setDefaultSortOption] = useState("price_asc");

  function handleClickToggleClass() {
    document.body.classList.toggle("showSidebar");
    setBodyClass(
      document.body.classList.contains("showSidebar") ? "showSidebar" : ""
    );
  }
  useEffect(() => {
    document.body.className = bodyClass;
  }, [bodyClass]);
  var payload = {
    PageNumber: 1,
    pageSize: 10,
    CategoryId: 3,
  };
  useEffect(() => {
    if (productState.pagination) {
      setPage(productState.pagination);
    }
  }, [productState.pagination]);
  const clickPagination = (page) => {
    setPage(page);
    dispatch(setPagination(page));
  };
  function handelRemoveSearchKeyword() {
    dispatch(setQueryText(""));
    const form = form1.current;
    form["searchBox"].value = "";
    mapPayloadGetItems();
  }
  function HomePageSearchProps() {
    if (props.payload && props.payload.IsGlobelSearch) {
      payload = props.payload;
    }
  }
  function listViewProps() {
    if (
      location.state &&
      location.state.industryId &&
      location.state.industryId > 0
    ) {
      payload.IndustryId = Number(location.state.industryId);
    }
  }

  function clearFilters() {
    window.location.reload();
    dispatch(industryRemove());
    dispatch(categoryRemove());

    // Clear other filters here
    dispatch(typeRemove());
    dispatch(removeStartPrice());
    dispatch(removeEndPrice());
    // dispatch(removeItemBrands());
    // dispatch(removeItemCondition());
    dispatch(setQueryText(""));
    // resetIndustryFilter();

    // dispatch(removeItemLocation());
  }

  useEffect(() => {
    payload.Reset = false;
    mapPayloadGetItems();
  }, [page]);
  const handleChangeCategory = (id) => {
    if (Number(id) > 0) {
      payload.CategoryId = Number(id);
    }
  };
  useEffect(() => {
    payload.Reset = true;
    mapPayloadGetItems();
  }, [useDispatch, filterState]);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const fetchData = async () => {
    payload.PageNumber = page;
    await GetPagedItems();
    // window.scrollTo(0, 0);
  };
  const handleSearchClickEvent = (e) => {
    mapPayloadGetItems();
    e.preventDefault();
  };
  const handelRemoveLocation = (val) => {
    dispatch(setItemLocation(val));
    // window.scrollTo(0, 0);
  };
  const handelRemoveCondition = (val) => {
    dispatch(setItemCondition(val));
    // window.scrollTo(0, 0);
  };
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const handleSortChange = (value) => {
    setDefaultSortOption(value);
    payload.Sort = value;
    mapPayloadGetItems();
  };

  // console.log(filterState);

  const handleScrollPosition = () => {
    const node = myScrollableElement.current;
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (node) {
      window.scrollTo(0, savedScrollPosition);
      setTimeout(() => {
        sessionStorage.removeItem("scrollPosition");
      }, 1000);
    }
  };

  return (
    <div className="HomeProductSection">
      <Container>
        <Row className="HomeBanner-row">
          <Col lg={3}>
            <div
              className={
                isActive
                  ? "product-sidebar product-sidebar-height inventoryPageSidebar pr-3"
                  : "product-sidebar product-sidebar-height inventoryPageSidebar pr-3 show"
              }
            >
              <h5>
                Search Result Filters{" "}
                <a className="CloseSidebar" onClick={handleClickToggleClass}>
                  <Icon icon="ic:sharp-close" />
                </a>
              </h5>
              {data && data.filtersResult && (
                <FilterBox filtersResult={data.filtersResult} />
              )}
            </div>
          </Col>
          <Col lg={9}>
            <form ref={form1} onSubmit={handleSearchClickEvent}>
              <div className="searchbar  mb-2">
                <input placeholder="Search Containers" name={"searchBox"} />
                <span>
                  <Icon icon="uil:search" />
                </span>
                <button
                  className="btn btn-blue"
                  type="button"
                  onClick={handleSearchClickEvent}
                >
                  Search
                </button>
              </div>
            </form>
            <Dropdown className="d-flex justify-content-end">
              <Col className="d-flex mb-2 gap-1 selectgrp">
                <Industry />
                <Category />
                <Condition />
                {/* <DoorOptions /> */}
                {/* <ItemLocation /> */}
              </Col>
              <Row className="filterBtn">
                <button
                  className="btn-blue clearFilterbtn"
                  onClick={clearFilters}
                >
                  Clear Filters
                </button>
                <Dropdown.Toggle
                  variant=""
                  className="btn-blue sortBtn"
                  id="dropdown-basic"
                >
                  Sort by
                </Dropdown.Toggle>
              </Row>

              <Dropdown.Menu>
                <Dropdown.Item
                  value="price_asc"
                  onClick={() => {
                    handleSortChange("price_asc");
                  }}
                >
                  Price: Low to High
                </Dropdown.Item>
                <Dropdown.Item
                  value="price_desc"
                  onClick={() => {
                    handleSortChange("price_desc");
                  }}
                >
                  Price: High to Low
                </Dropdown.Item>
                <Dropdown.Item
                  value="date_desc"
                  onClick={() => {
                    handleSortChange("date_desc");
                  }}
                >
                  Newest Arrivals
                </Dropdown.Item>
                <Dropdown.Item
                  value="name_asc"
                  onClick={() => {
                    handleSortChange("name_asc");
                  }}
                >
                  A to Z
                </Dropdown.Item>
                <Dropdown.Item
                  value="name_desc"
                  onClick={() => {
                    handleSortChange("name_desc");
                  }}
                >
                  Z to A
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="product-wrap ProductWrap">
              <div className="product-header-wrap">
                <h2>Our Containers</h2>
                <a
                  href="#"
                  className="CloseSidebar"
                  onClick={handleClickToggleClass}
                >
                  <Icon icon="material-symbols:filter-list-rounded" />
                </a>
              </div>
              <div className="product-main-list">
                <ul className="filter-tags">
                  {filterState &&
                    filterState.industry.name !== "" &&
                    filterState.industry.name !== undefined && (
                      <li>
                        <span>
                          {" "}
                          <b> Type : </b>
                          {filterState.industry.name}{" "}
                        </span>
                        <a>
                          <Icon
                            icon="mdi:close"
                            onClick={() => {
                              // dispatch(industryRemove());
                              window.location.reload();
                            }}
                          />
                        </a>
                      </li>
                    )}
                  {filterState &&
                    filterState.category.name !== "" &&
                    filterState.category.name !== undefined && (
                      <li>
                        <b>Size : </b> <span>{filterState.category.name} </span>
                        <a>
                          <Icon
                            icon="mdi:close"
                            onClick={() => {
                              // dispatch(categoryRemove());
                              window.location.reload();
                            }}
                          />
                        </a>
                      </li>
                    )}
                  {filterState &&
                    filterState.type.name !== "" &&
                    filterState.type.name !== undefined && (
                      <li>
                        <b>Door Option : </b>
                        <span>{filterState.type.name} </span>
                        <a>
                          <Icon
                            icon="mdi:close"
                            onClick={() => {
                              // dispatch(typeRemove());
                              window.location.reload();
                            }}
                          />
                        </a>
                      </li>
                    )}
                  {filterState.priceStartRange > -1 && (
                    <li>
                      <span> Min Price : {filterState.priceStartRange} </span>
                      <a>
                        <Icon
                          icon="mdi:close"
                          onClick={() => {
                            dispatch(removeStartPrice());
                          }}
                        />
                      </a>
                    </li>
                  )}
                  {filterState.priceEndRange > -1 && (
                    <li>
                      <span>Max Price : {filterState.priceEndRange} </span>
                      <a>
                        <Icon
                          icon="mdi:close"
                          onClick={() => {
                            dispatch(removeEndPrice());
                          }}
                        />
                      </a>
                    </li>
                  )}

                  {filterState.brandNameList &&
                    filterState.brandNameList.map((brand, index) => {
                      return (
                        <li key={index}>
                          <b>Brand : </b>
                          <span> {brand} </span>
                          <a>
                            <Icon
                              icon="mdi:close"
                              onClick={() => {
                                dispatch(removeItemBrands(brand));
                              }}
                            />
                          </a>
                        </li>
                      );
                    })}
                  {filterState.locations &&
                    filterState.locations.map((loc, index) => {
                      return (
                        <li key={index}>
                          <b>Locations : </b>
                          <span> {loc} </span>
                          <a>
                            <Icon
                              icon="mdi:close"
                              onClick={() => {
                                dispatch(removeItemLocation(loc));
                              }}
                            />
                          </a>
                        </li>
                      );
                    })}

                  {filterState.colors &&
                    filterState.colors.map((colorItem, index) => {
                      return (
                        <li key={index}>
                          <b>Color : </b>
                          <span> {colorItem} </span>
                          <a>
                            <Icon
                              icon="mdi:close"
                              onClick={() => {
                                dispatch(colorsRemove(colorItem));
                              }}
                            />
                          </a>
                        </li>
                      );
                    })}

                  {filterState.isBuy && (
                    <li>
                      <b>Purchase Option : </b>
                      <span>Buy</span>
                      <a>
                        <Icon
                          icon="mdi:close"
                          onClick={() => {
                            dispatch(removeisBuy(filterState.isRent));
                          }}
                        />
                      </a>
                    </li>
                  )}

                  {filterState.isRent && (
                    <li>
                      <b>Purchase Option : </b>
                      <span>Rent</span>
                      <a>
                        <Icon
                          icon="mdi:close"
                          onClick={() => {
                            dispatch(removeisRent(filterState.isRent));
                          }}
                        />
                      </a>
                    </li>
                  )}

                  {filterState.accessorials &&
                    filterState.accessorials.map((accessItem, index) => {
                      return (
                        <li key={index}>
                          <b>Accessorials : </b>
                          <span> {accessItem} </span>
                          <a>
                            <Icon
                              icon="mdi:close"
                              onClick={() => {
                                dispatch(accessorialsRemove(accessItem));
                              }}
                            />
                          </a>
                        </li>
                      );
                    })}

                  {filterState.conditionList &&
                    filterState.conditionList.map((con, index) => {
                      return (
                        <li key={index}>
                          <b>Condition : </b>
                          <span> {con} </span>
                          <a>
                            <Icon
                              icon="mdi:close"
                              onClick={() => {
                                window.location.reload();
                                // dispatch(removeItemCondition(con));
                              }}
                            />
                          </a>
                        </li>
                      );
                    })}
                  {filterState.queryText && filterState.queryText !== "" && (
                    <li>
                      <b>Keyword : </b>
                      <span> {filterState.queryText} </span>
                      <a>
                        <Icon
                          icon="mdi:close"
                          onClick={() => {
                            handelRemoveSearchKeyword();
                          }}
                        />
                      </a>
                    </li>
                  )}
                </ul>
                {data && data.items ? (
                  <div
                    className="InventoryPageWrapss"
                    ref={myScrollableElement}
                  >
                    {(() => {
                      if (data && data.items && data.items.length > 0) {
                        return data.items.map((product, index) => (
                          <div
                            className="my-3 InventoryProductw-50"
                            key={index}
                          >
                            <InventoryProduct
                              key={product.id}
                              productDetail={product}
                            />
                          </div>
                        ));
                      } else {
                        return (
                          <div className="NoFound">
                            <h3>No Records Found</h3>
                          </div>
                        );
                      }
                    })()}
                  </div>
                ) : (
                  <div className="NoFound">
                    <h3>No Records Found</h3>
                  </div>
                )}
                {data.totalPages && data.totalPages > 1 && (
                  <ItemPagenation
                    key={uuid() + "item"}
                    total={data.totalPages}
                    current={page}
                    onChangePage={(p) => {
                      clickPagination(p);
                    }}
                  ></ItemPagenation>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );

  async function mapPayloadGetItems() {
    if (!processing) {
      setProcessing(true);
      if (
        filterState.industry.name !== undefined &&
        filterState.industry.name !== undefined
      ) {
        payload.RelatedIndustry = filterState.industry.name;
        appendparaToUrl("type", filterState.industry.name);
      } else {
        removeQueryParamIfExists("type");
      }

      // Set isBuy and isRent to false if they are not checked
      if (!filterState.isBuy || filterState.isBuy.length === 0) {
        payload.isBuy = false;
      }
      if (!filterState.isRent || filterState.isRent.length === 0) {
        payload.isRent = false;
      }

      if (filterState.category.name !== null) {
        payload.RelatedCategory = filterState.category.name;
        appendparaToUrl("size", filterState.category.name);
      } else {
        removeQueryParamIfExists("size");
      }
      if (filterState.type.name !== null) {
        payload.RelatedType = filterState.type.name;
        appendparaToUrl("door-option", filterState.type.name);
      } else {
        removeQueryParamIfExists("door-option");
      }
      if (filterState.priceStartRange > -1) {
        payload.priceStartRange = filterState.priceStartRange;
        appendparaToUrl("start_price_range", filterState.priceStartRange);
      } else {
        removeQueryParamIfExists("start_price_range");
      }
      if (filterState.priceEndRange > filterState.priceStartRange) {
        payload.priceEndRange = filterState.priceEndRange;
        appendparaToUrl("end_price_range", filterState.priceEndRange);
      } else {
        removeQueryParamIfExists("end_price_range");
      }
      if (filterState.locations.length > 0) {
        payload.locations = filterState.locations;
        appendparaToUrl("location", filterState.locations);
      } else {
        removeQueryParamIfExists("location");
      }
      if (filterState.brandNameList.length > 0) {
        payload.BrandNameList = filterState.brandNameList;
        appendparaToUrl("brand", filterState.brandNameList);
      } else {
        removeQueryParamIfExists("brand");
      }
      if (filterState.conditionList.length > 0) {
        payload.conditionList = filterState.conditionList;
        appendparaToUrl("condition", filterState.conditionList);
      } else {
        removeQueryParamIfExists("condition");
      }

      if (filterState.isBuy) {
        payload.isBuy = filterState.isBuy;
        appendparaToUrl("PurchaseOption", filterState.isBuy);
      } else {
        removeQueryParamIfExists("PurchaseOption");
      }
      if (filterState.isRent) {
        payload.isRent = filterState.isRent;
        appendparaToUrl("PurchaseOption", filterState.isRent);
      } else {
        removeQueryParamIfExists("PurchaseOption");
      }

      if (filterState.colors.length > 0) {
        payload.colors = filterState.colors;
        appendparaToUrl("color", filterState.colors);
      } else {
        removeQueryParamIfExists("color");
      }

      if (filterState.saleType.length > 0) {
        payload.saleType = filterState.saleType;
        appendparaToUrl("saleType", filterState.saleType);
      } else {
        removeQueryParamIfExists("saleType");
      }

      if (filterState.accessorials.length > 0) {
        payload.accessorials = filterState.accessorials;
        appendparaToUrl("accessorials", filterState.accessorials);
      } else {
        removeQueryParamIfExists("accessorials");
      }

      const form = form1.current;
      if (form["searchBox"].value !== null && form["searchBox"].value !== "") {
        var text = form["searchBox"].value;
        if (text !== undefined && text !== null) {
          dispatch(setQueryText(text));
          payload.TextSearch = text;
        }
      } else {
      }
      if (filterState.queryText && filterState.queryText !== "") {
        payload.TextSearch = filterState.queryText;
        appendparaToUrl("keyword", filterState.queryTex);
      } else {
        removeQueryParamIfExists("keyword");
      }

      // Set default sorting option
      payload.Sort = defaultSortOption;
      middleFetchData();
      setProcessing(false);
    }
  }

  function appendparaToUrl(name, value) {
    const paramName = name;
    const paramValue = value;

    if (searchParams.has(paramName)) {
      searchParams.set(paramName, paramValue);
    } else {
      searchParams.append(paramName, paramValue);
    }
    setSearchParamsHandle(searchParams);
    // navigate({ search: searchParams.toString() });
  }

  function removeQueryParamIfExists(paramName) {
    const searchDel = new URLSearchParams(location.search);

    if (searchDel.has(paramName)) {
      searchDel.delete(paramName);
      setSearchParamsHandle(searchDel);
      //  navigate({ search: searchParams.toString() });
    }
  }

  function middleFetchData() {
    fetchData();
  }

  async function GetPagedItems() {
    try {
      var res = await axiosClient.post("GetPagedItems", payload);

      setData({
        items: res.data,
        totalPages: res.totalPages,
        filtersResult: res.filtersResultDto,
      });

      if (res) {
        handleScrollPosition();
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default InventoryPoductSection;
