import React, { useEffect, useState } from "react";
import { Image, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  handleUpdateQtyCart,
  getTotals,
  handleStartAndEndDate,
  setRentRate,
} from "../../Slices/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import ShipeEngineQuotes from "../../ShipeEgine/ShipeEngineQuotes";
import uuid from "react-uuid";
import axiosClient from "../../../axiosClient";
import { toast } from "react-toastify/dist/react-toastify";
import "./CartProductInfo.css";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillCloseCircle } from "react-icons/ai";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const CartProductInfo = (props) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [showCarrierQuote, setShowCarrierQuote] = useState(null);
  const [showCarrierQuoteF, setShowCarrierQuoteF] = useState(null);
  const [isPlusQtyEqual, setIsPlusQtyEqual] = useState(false);
  const userData = JSON.parse(localStorage.getItem("login"));
  const [selectedRentalPeriod, setSelectedRentalPeriod] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedRentType, setSelectedRentType] = useState("");
  const [rentPrice, setRentPrice] = useState(0);
  const [rentDays, setRentDays] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [daysBetween, setDaysBetween] = useState(null);

  const today = new Date();
  const defaultStartDate = new Date(today);
  defaultStartDate.setDate(today.getDate() + 2);

  const defaultEndDate = new Date(defaultStartDate);
  defaultEndDate.setMonth(defaultStartDate.getMonth() + 1);
  let defaultDateRange = [defaultStartDate, defaultEndDate];

  const [dateRange, setDateRange] = useState(null);
  const onQuoteFPopClose = () => {
    setShowCarrierQuoteF(false);
  };

  const onQuotePopClose = () => {
    setShowCarrierQuote(false);
  };

  const handleStartDateChange = (event) => {
    setStartDate(new Date(event.target.value));
  };

  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
  };

  useEffect(() => {
    updateRangeDate();
  }, [dateRange]);

  function updateRangeDate() {
    // debugger;
    if (dateRange && dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      calculatePrice(props.item, days);
      setRentDays(days);
      localStorage.setItem("rentDays", days);
      var dataItm = props.item;
      dataItm.startedRentDate = startDate;
      dataItm.endRentDate = endDate;
      dispatch(handleStartAndEndDate(dataItm));
      dispatch(getTotals());
    } else {
      setDaysBetween(null);
    }
  }

  const handleShow = () => {
    if (showCarrierQuoteF == null) {
      if (showCarrierQuoteF == null) {
        setShowCarrierQuoteF(true);
      }
    } else {
      setShowCarrierQuoteF(!showCarrierQuote);
    }
  };

  const handleIncrement = () => {
    let q = props.item.selectedQty + 1;
    var itm = props.item;
    itm.selectedQty = q;
    props.handleUpdateQtyCart(itm);
  };
  const handleDecrement = () => {
    if (props.item.selectedQty > 1) {
      let q = props.item.selectedQty - 1;
      var itm = props.item;
      itm.selectedQty = q;
      props.handleUpdateQtyCart(itm);
    }
  };

  var PackagingList;
  if (props.item.packagingCostData) {
    var PackagingList = props.item.packagingCostData.split(";");
  }

  useEffect(() => {
    if (props.item.startedRentDate != "" && props.item.endRentDate) {
      setDateRange([
        new Date(props.item.startedRentDate),
        new Date(props.item.endRentDate),
      ]);
    } else {
      setDateRange(defaultDateRange);
    }
    dispatch(handleUpdateQtyCart(props.item, props.item.selectedQty));
  }, []);
  const handleShippingType = (value) => {
    if (value == "3" && props.item.selectedShippingType != "3") {
      handleShow();
    }
  };

  async function saveLaterItem(selectedItem) {
    try {
      var body = {
        id: 0,
        userId: userData.user.id,
        ItemId: Number(selectedItem.id),
      };
      await axiosClient.post("saveSaveLaterInfo", body).then((res) => {
        props.RemoveAddCart(selectedItem);
        toast.success("Successfully saved for later");
      });
    } catch (error) {}
  }

  function calculatePrice(item, rentDays) {
    // debugger;
    var totalWeeks = rentDays / 7;
    var totalMonths = rentDays / 30;
    var totalYears = rentDays / 365;

    var calculatedPrice;

    // if (rentDays < 7) {
    //   calculatedPrice = item.rentWeekly;
    // }
    // if (totalWeeks < 4) {
    //   calculatedPrice = item.rentWeekly * totalWeeks;
    // }
    // if (totalMonths < 12) {
    //   calculatedPrice = item.rentMonthly * totalMonths;
    // }
    // if (totalYears > 1) {
    //   calculatedPrice = item.rentYearly * totalYears;
    // }

    // let FinalValueOne;
    // if (totalWeeks < 4 && calculatedPrice > item.rentMonthly) {
    //   FinalValueOne = item.rentMonthly;
    // } else {
    //   FinalValueOne = calculatedPrice;
    // }

    // let FinalValueTwo;
    // if (totalMonths < 12 && calculatedPrice > item.rentYearly) {
    //   FinalValueTwo = item.rentYearly;
    // } else {
    //   FinalValueTwo = calculatedPrice;
    // }

    // if (totalWeeks < 4 ){
    //   calculatedPrice = FinalValueOne;
    // }

    // else if(totalMonths < 12){
    //   calculatedPrice=FinalValueTwo;
    // }

    if (rentDays < 7) {
      calculatedPrice = item.rentWeekly;
    } else if (totalWeeks < 4) {
      calculatedPrice = item.rentWeekly * totalWeeks;
    } else if (totalMonths < 12) {
      calculatedPrice = item.rentMonthly * totalMonths;
    } else if (totalYears > 1) {
      calculatedPrice = item.rentYearly * totalYears;
    }

    var finalValueOne =
      totalWeeks < 4 && calculatedPrice > item.rentMonthly
        ? item.rentMonthly
        : calculatedPrice;
    var finalValueTwo =
      totalMonths < 12 && calculatedPrice > item.rentYearly
        ? item.rentYearly
        : calculatedPrice;

    var finalPrice;

    if (totalWeeks < 4) {
      finalPrice = finalValueOne;
    } else if (totalMonths < 12) {
      finalPrice = finalValueTwo;
    } else {
      finalPrice = calculatedPrice;
    }

    // debugger;
    let selectedItem = item;
    selectedItem.selectedRentPrice = finalPrice.toFixed(2);

    if (selectedItem.selectedRentPrice && selectedItem.selectedRentPrice > 0) {
      dispatch(setRentRate(selectedItem));
      setRentPrice(
        Number(selectedItem.selectedRentPrice) *
          Number(selectedItem.selectedQty)
      );
    }

    return finalPrice;
  }

  const handleRentClick = () => {
    props.handleRentSelection(rentPrice);
  };

  const onChangeRentDuration = (event) => {
    let rentPrice = 0;
    let value = event.target.value;
    setSelectedType(value);
    let RentDurration = "";
    if (value == "1") {
      rentPrice = Number(props.item.rentWeekly);
      RentDurration = "weekly";
    }

    if (value == "2") {
      rentPrice = Number(props.item.rentMonthly);
      RentDurration = "monthly";
    }

    if (value == "3") {
      rentPrice = Number(props.item.rentYearly);
      RentDurration = "yearly";
    }

    let selectedItem = props.item;

    setSelectedRentType(RentDurration);

    selectedItem.selectedRentPrice = rentPrice.toFixed(2);
    selectedItem.selectedRentType = RentDurration;
    // console.log(RentDurration);
    props.calculateRent(selectedItem);

    props.handleRentSelection(selectedItem);
  };

  const onChangeBuyOrRent = (event) => {
    let value = event.target.value;
    let RentDurration = "";
    let rentPrice = 0;
    let selectedItem = props.item;
    selectedItem.selectedRentPrice = rentPrice.toFixed(2);
    selectedItem.selectedRentType = RentDurration;
    selectedItem.selectedBuyOrRent = value;
    props.calculateRent(selectedItem);

    // debugger;
    if (value === "buy") {
      rentPrice = 0;
      localStorage.setItem("rentRate", rentPrice);
      dispatch(setRentRate(rentPrice));
      setRentPrice(rentPrice);
      dispatch(getTotals());
    } else {
      setDateRange(defaultDateRange);
    }
  };

  useEffect(() => {
    cart.cartItems.map((item, index) => {});
    props.item.selectedRentType = "rent";
    props.item.selectedRentPrice = 0;
  }, [selectedRentalPeriod]);

  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);
      if (v > 0) {
        return v;
      } else {
        return "0.00";
      }
    } else {
      return "0.00";
    }
  }

  return (
    <div className="product-cart-box-wrap">
      {(() => {
        if (props.item.coverImageURL != null) {
          return (
            <div>
              <div
                className="productItem-Img"
                dangerouslySetInnerHTML={{ __html: props.item.coverImageURL }}
              ></div>
              <div className="RentTag">
                {props.item.isRental == true && (
                  <a className="rentTaCart">Available For Rent</a>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="productItem-Img">
              {" "}
              <Image src="/assets/images/not-found.png" />{" "}
            </div>
          );
        }
      })()}

      <div className="product-cart-info">
        <div className="product-title-container">
          <div className="product-title">
            <h5 style={{ marginBottom: "0" }}>{props.item.description}</h5>
          </div>
          <div className="delete-btn">
            <Link onClick={() => props.RemoveAddCart(props.item)}>
              <AiFillCloseCircle
                style={{
                  fontSize: "1.5rem",
                  color: "#ff0000",
                }}
              />
            </Link>
          </div>
        </div>
        <span>{props.item.location} </span>
        {props.item.selectedBuyOrRent === "rent" && rentPrice === 0 ? (
          <p>Please select the rent date to get rental cost.</p>
        ) : (
          <h6>
            <i>
              {props.item.selectedBuyOrRent === "rent"
                ? "Rent Price"
                : "Sale Price"}{" "}
              :{" "}
            </i>{" "}
            $
            {props.item.selectedBuyOrRent === "rent"
              ? rentPrice.toFixed(2)
              : props.item.salePrice}{" "}
            USD
          </h6>
        )}

        <div className="d-flex flex-row mb-2 buyonrentsection">
          <h6 className="mt-1">
            <i className="me-4">Select Buy or Rent : </i>
          </h6>
          <div>
            <Form.Select
              style={{ width: "16rem", marginBottom: "8px" }}
              onChange={(e) => {
                onChangeBuyOrRent(e);
              }}
              value={
                !props.item.isRental ? "buy" : props.item.selectedBuyOrRent
              }
              disabled={!props.item.isRental}
            >
              <option value="buy">Buy</option>
              <option value="rent">Rent</option>
            </Form.Select>
          </div>
        </div>

        <div className="d-flex flex-row mb-2">
          {props.item.selectedBuyOrRent === "rent" && (
            <div className="select-date-box">
              <h6 className="mt-1">
                <i className="me-4">Select Rental Date : </i>
              </h6>
              <div>
                <DateRangePicker
                  value={dateRange}
                  clearIcon={null}
                  onChange={handleDateChange}
                  format="y-MM-dd"
                  minDate={defaultStartDate}
                  fixedPosition={true}
                />
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-row mb-2">
          <h6 className="mt-2">
            {" "}
            <i className="me-3"> Quantity : </i>{" "}
            {props.item.quantity < 2 && <>{props.item.selectedQty}</>}
          </h6>
          <div className="qtyNumber mt-2">
            <br />
            {props.item.quantity > 1 && (
              <div className="quantity-selector">
                <a className="btn btn-sm btn-primary" onClick={handleDecrement}>
                  <b>-</b>{" "}
                </a>
                <span className="quantity">{props.item.selectedQty} </span>
                {isPlusQtyEqual == false && (
                  <a
                    className="btn btn-sm btn-primary"
                    onClick={handleIncrement}
                  >
                    {" "}
                    <b>+</b>{" "}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flew-row justify-content-between">
          <div className="d-flex flew-row rentCard">
            {props.item.milagePrice !== "" && (
              <div className="transportation-charg-box">
                <h5 className="labelBox">
                  Transportation Charges{" "}
                  <span> {formattedValue(props.item.milagePrice)}</span>
                </h5>
              </div>
            )}
            {props.item.selectedBuyOrRent == "rent" && (
              <div className="d-flex flex-row Card">
                <div>
                  <h5 className="labelBox">
                    Rent Rate
                    <span> {formattedValue(rentPrice)}</span>
                  </h5>
                </div>
                <div className="ms-2">
                  <h5 className="labelBox">
                    Days{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {" "}
                      {rentDays}
                    </span>
                  </h5>
                </div>
              </div>
            )}

            <div className="d-flex flex-row Card"></div>
          </div>

          <div className="product-btn-wrap">
            {props.item.assetNumber && (
              <div className="refBox2">
                <p className="ref-text">Reference Number</p>
                <p className="ref-num">{props.item.assetNumber}</p>
              </div>
            )}
            <Link
              className="btn btn-blue btn-save-letter"
              onClick={() => saveLaterItem(props.item)}
            >
              Save for later
            </Link>
          </div>
        </div>
      </div>
      <ShipeEngineQuotes
        key={uuid.toString() + 2}
        item={props.item}
        show={showCarrierQuoteF}
        close={onQuotePopClose}
      />
    </div>
  );
};

export default CartProductInfo;
