import React, { useRef, useState } from "react";
import { Accordion, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import {
  setCategory,
  setPriceRange,
  setItemLocation,
  setItemCondition,
  setIndustry,
  setType,
  setItemBrands,
  setColors,
  setAccessorials,
  setSaleType,
  setBuyOption,
  setRentOption,
} from "../../Slices/FilterSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import "./FilterBox.css";

function FilterBox(props) {
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);
  const formPriceRange = useRef(null);
  const checkboxesBrandsRef = useRef(null);
  const checkboxesConditionsRef = useRef(null);
  const checkboxesLocationsRef = useRef(null);
  const checkboxesColorsRef = useRef(null);
  const checkboxesSaleTypeRef = useRef(null);
  const checkboxesAccessorialsRef = useRef(null);
  let showDefaultFilter = ["0", "1", "2", "3", "4", "5", "6"];
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(10000);

  const handleMinRangeChange = (event) => {
    const newMinRange = parseInt(event.target.value, 10);
    const newMaxRange = Math.max(newMinRange, maxRange);
    setMinRange(newMinRange);
    setMaxRange(newMaxRange);
  };

  const handleMaxRangeChange = (event) => {
    const newMaxRange = parseInt(event.target.value, 10);
    const newMinRange = Math.min(newMaxRange, minRange);
    setMaxRange(newMaxRange);
    setMinRange(newMinRange);
  };

  const handleBuyOptionChange = () => {
    dispatch(setBuyOption(true));
    dispatch(setRentOption(false)); // Uncheck Rent option
  };

  const handleRentOptionChange = () => {
    dispatch(setBuyOption(false)); // Uncheck Buy option
    dispatch(setRentOption(true));
  };

  const clickIndustry = (item) => {
    dispatch(setIndustry(item));
  };
  const clickCategory = (item) => {
    dispatch(setCategory(item));
  };

  const clickSaleType = (item) => {
    dispatch(setSaleType(item));
  };

  const clickType = (item) => {
    dispatch(setType(item));
  };

  const clickAccessorials = (item) => {
    dispatch(setAccessorials(item));
  };

  const handlePurchaseOptions = () => {
    // console.log(filterState.isBuy);
    // console.log(filterState.isRent);
    // const payload = {
    //   isBuy: filterState.isBuy,
    //   isRent: filterState.isRent,
    // };
    dispatch(setBuyOption(filterState.isBuy));
    dispatch(setRentOption(filterState.isRent));
  };

  const handlePriceRange = () => {
    const form = formPriceRange.current;
    var min = form["min"].value;
    var max = form["max"].value;
    var priceRange = {
      minPrice: Number(min),
      maxPrice: Number(max),
    };

    if (priceRange.minPrice > -1 && priceRange.maxPrice > priceRange.minPrice)
      dispatch(setPriceRange(priceRange));
  };

  const handleLocationChange = () => {
    const checkcondtionboxes = checkboxesLocationsRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );
    const locations = Array.from(checkcondtionboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
    dispatch(setItemLocation(locations));
  };

  const handleLocationToggle = (val) => {
    var index = _.findIndex(filterState.locations, function (o) {
      return o == val;
    });
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  // console.log(filterState);

  const handleConditionToggle = (val) => {
    const checkcondtionboxes = checkboxesConditionsRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );
    const selectedBrandList = Array.from(checkcondtionboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    dispatch(setItemCondition(selectedBrandList));
  };

  const handleBrandClick = () => {
    const checkboxes = checkboxesBrandsRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );
    const selectedBrandList = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    dispatch(setItemBrands(selectedBrandList));
  };

  // console.log(props.filtersResult);

  const handleChangeColors = () => {
    const checkboxes = checkboxesColorsRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );

    const colors = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    dispatch(setColors(colors));
  };

  const handleSaleType = () => {
    const checkboxes = checkboxesSaleTypeRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );

    const saleType = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    dispatch(setSaleType(saleType));
  };

  // setAccessorials
  // console.log(props.filtersResult);

  const handleChangeAccessorials = () => {
    const checkboxes = checkboxesAccessorialsRef.current.querySelectorAll(
      'input[type="checkbox"]'
    );
    const accessorials = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
    dispatch(setAccessorials(accessorials));
  };

  return (
    <>
      {/* defaultActiveKey="0"  */}
      <div className="filter-box-container">
        <Accordion
          className="filter-accordion"
          defaultActiveKey={["1", "2", "3", "8", "4"]}
          alwaysOpen
          a
        >
          {/* {props.filtersResult &&
            props.filtersResult.saleTypes &&
            props.filtersResult.saleTypes != undefined && (
              <Accordion.Item eventKey="8">
                <Accordion.Header>Purchase Options</Accordion.Header>
                <Accordion.Body>
                  <div ref={checkboxesSaleTypeRef}>
                    <ul className="filter-list-item">
                      {props.filtersResult &&
                        props.filtersResult.saleTypes &&
                        props.filtersResult.saleTypes.map((item, index) => (
                          <li key={index}>
                            <label>
                              <input
                                type="checkbox"
                                name={item}
                                value={item}
                                id={item}
                              />
                              <span className="checkboxText">{item}</span>
                            </label>
                          </li>
                        ))}
                    </ul>

                    <a
                      href="#"
                      className="searchBtn"
                      onClick={() => handleSaleType()}
                      style={{ color: "white" }}
                    >
                      <Icon icon="material-symbols:arrow-right-alt" />
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )} */}

          <Accordion.Item eventKey="8">
            <Accordion.Header>Purchase Options</Accordion.Header>
            <Accordion.Body>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="buyCheckbox"
                    checked={filterState.isBuy}
                    onChange={handleBuyOptionChange}
                  />
                  <span className="checkboxText">Buy</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="rentCheckbox"
                    checked={filterState.isRent}
                    onChange={handleRentOptionChange}
                  />
                  <span className="checkboxText">Rent</span>
                </label>
              </div>
              {/* <a
                href="#"
                className="searchBtn"
                onClick={handlePurchaseOptions}
                style={{ color: "white" }}
              >
                <Icon icon="material-symbols:arrow-right-alt" />
              </a> */}
            </Accordion.Body>
          </Accordion.Item>
          {props.filtersResult && props.filtersResult.industryList && (
            <Accordion.Item eventKey="4">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                <div className="overflowfilters">
                  <ul className="categories-list">
                    {props.filtersResult.industryList.map((item, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={() => {
                            clickIndustry(item);
                          }}
                        >
                          {item.name}
                          <span>({item.itemCount})</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="2">
            <Accordion.Header>Condition</Accordion.Header>
            <Accordion.Body>
              <div ref={checkboxesConditionsRef}>
                <ul className="filter-list-item">
                  {props.filtersResult &&
                    props.filtersResult.conditionList &&
                    props.filtersResult.conditionList.map(
                      (condition, index) => (
                        <li key={index}>
                          <label>
                            <input
                              type="checkbox"
                              name={condition}
                              value={condition}
                              id={condition}
                              defaultChecked={filterState.conditionList.includes(
                                condition
                              )}
                            />
                            <span className="checkboxText">{condition}</span>
                          </label>
                        </li>
                      )
                    )}
                </ul>
                <a
                  href="#"
                  className="searchBtn"
                  onClick={handleConditionToggle}
                  style={{ color: "white" }}
                >
                  <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {props.filtersResult && props.filtersResult.categoryList && (
            <Accordion.Item eventKey="1">
              <Accordion.Header>Size</Accordion.Header>
              <Accordion.Body>
                <div className="overflowfilters">
                  <ul className="categories-list">
                    {props.filtersResult.categoryList.map((item, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={() => {
                            clickCategory(item);
                          }}
                        >
                          {item.name}
                          <span>({item.itemCount})</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {/* <Accordion.Item eventKey="3" alwaysOpen>
            <Accordion.Header>Price</Accordion.Header>
            <Accordion.Body>
              <form ref={formPriceRange}>
                <div className="price-list">
                  <div className="price-list-wrap">
                    <input placeholder="$ Min" name={"min"} />
                    
                    <input placeholder="$ Max" name={"max"} />
                    <a
                      href="#"
                      onClick={handlePriceRange}
                      style={{ color: "white" }}
                    >
                      <Icon icon="material-symbols:arrow-right-alt" />
                    </a>
                  </div>
                </div>
              </form>
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="3" alwaysOpen>
            <Accordion.Header>Price</Accordion.Header>
            <Accordion.Body className="priceRange">
              <form ref={formPriceRange}>
                <Form.Label>$ Min: {minRange}</Form.Label>
                <Form.Range
                  value={minRange}
                  className="custom-range"
                  onChange={handleMinRangeChange}
                  name="min"
                  min={0}
                  max={10000}
                  step={100}
                />
                <Form.Label>$ Max: {maxRange}</Form.Label>
                <Form.Range
                  value={maxRange}
                  className="custom-range"
                  onChange={handleMaxRangeChange}
                  name="max"
                  min={0}
                  max={10000}
                  step={100}
                />
              </form>
              <a
                variant="primary"
                className="price-btn"
                onClick={handlePriceRange}
              >
                <Icon icon="material-symbols:arrow-right-alt" />
              </a>
            </Accordion.Body>
          </Accordion.Item>

          {props.filtersResult &&
            props.filtersResult.typeList &&
            props.filtersResult.typeList != undefined && (
              <Accordion.Item eventKey="5">
                <Accordion.Header>Doors</Accordion.Header>
                <Accordion.Body>
                  <div className="overflowfilters">
                    <ul className="categories-list">
                      {props.filtersResult.typeList.map((item, index) => (
                        <li key={index}>
                          <a
                            href="#"
                            onClick={() => {
                              clickType(item);
                            }}
                          >
                            {item.name}
                            <span>({item.itemCount})</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          {props.filtersResult &&
            props.filtersResult.colors &&
            props.filtersResult.colors != undefined && (
              <Accordion.Item eventKey="6">
                <Accordion.Header>Colors</Accordion.Header>
                <Accordion.Body>
                  <div ref={checkboxesColorsRef}>
                    <ul className="filter-list-item">
                      {props.filtersResult &&
                        props.filtersResult.colors &&
                        props.filtersResult.colors.map((colorItem, index) => (
                          <li key={index}>
                            <label>
                              <input
                                type="checkbox"
                                name={colorItem}
                                value={colorItem}
                                id={colorItem}
                                defaultChecked={filterState.colors.includes(
                                  colorItem
                                )}
                              />
                              <span className="checkboxText">{colorItem}</span>
                            </label>
                          </li>
                        ))}
                    </ul>

                    <a
                      href="#"
                      className="searchBtn"
                      onClick={() => handleChangeColors()}
                      style={{ color: "white" }}
                    >
                      <Icon icon="material-symbols:arrow-right-alt" />
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          {/* {props.filtersResult &&
          props.filtersResult.typeList &&
          props.filtersResult.typeList != undefined && (
            <Accordion.Item eventKey="2">
              <Accordion.Header>Container Condition Options</Accordion.Header>
              <Accordion.Body>
                <div className="overflowfilters">
                  <ul className="categories-list">
                    {props.filtersResult.typeList.map((item, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={() => {
                            clickType(item);
                          }}
                        >
                          {item.name}
                          <span>({item.itemCount})</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )} */}
          {props.filtersResult &&
            props.filtersResult.accessorials &&
            props.filtersResult.accessorials != undefined && (
              <Accordion.Item eventKey="7">
                <Accordion.Header>Accessorials</Accordion.Header>
                <Accordion.Body>
                  <div ref={checkboxesAccessorialsRef}>
                    <ul className="filter-list-item">
                      {props.filtersResult &&
                        props.filtersResult.accessorials &&
                        props.filtersResult.accessorials.map(
                          (accessorials, index) => (
                            <li key={index}>
                              <label>
                                <input
                                  type="checkbox"
                                  name={accessorials}
                                  value={accessorials}
                                  id={accessorials}
                                  defaultChecked={filterState.accessorials.includes(
                                    accessorials
                                  )}
                                />
                                <span className="checkboxText">
                                  {accessorials}
                                </span>
                              </label>
                            </li>
                          )
                        )}
                    </ul>

                    <a
                      href="#"
                      className="searchBtn"
                      onClick={() => handleChangeAccessorials()}
                      style={{ color: "white" }}
                    >
                      <Icon icon="material-symbols:arrow-right-alt" />
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          {/* <Accordion.Item eventKey="9">
            <Accordion.Header>Brand</Accordion.Header>
            <Accordion.Body>
              <div ref={checkboxesBrandsRef}>
                <ul className="filter-list-item">
                  {props.filtersResult &&
                    props.filtersResult.brandNameList &&
                    props.filtersResult.brandNameList.map((item, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            name={item}
                            value={item}
                            id={item}
                            defaultChecked={filterState.brandNameList.includes(
                              item
                            )}
                          />
                          <span className="checkboxText">{item}</span>
                        </label>
                      </li>
                    ))}
                </ul>
                <a
                  href="#"
                  className="searchBtn"
                  onClick={handleBrandClick}
                  style={{ color: "white" }}
                >
                  <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="10">
            <Accordion.Header>Item Location</Accordion.Header>
            <Accordion.Body>
              <div ref={checkboxesLocationsRef}>
                <ul className="filter-list-item">
                  {props.filtersResult &&
                    props.filtersResult.locations &&
                    props.filtersResult.locations.map((locationItem, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            name={locationItem}
                            value={locationItem}
                            id={locationItem}
                            defaultChecked={filterState.locations.includes(
                              locationItem
                            )}
                            // checked={handleLocationToggle(item)}
                          />
                          <span className="checkboxText">{locationItem}</span>
                        </label>
                      </li>
                    ))}
                </ul>

                <a
                  href="#"
                  className="searchBtn"
                  onClick={() => handleLocationChange()}
                  style={{ color: "white" }}
                >
                  <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <div className="overflowfilters">
                <div >
                    <h5>All Categories</h5>
                    <ul className='categories-list'>
                        {props.filtersResult && props.filtersResult.categoryList &&
                            (props.filtersResult.categoryList.map(item => (
                                <li  >
                                    <a onClick={() => { clickCategory(item) }}  >
                                        {item.name}
                                        <span>({item.itemCount})</span></a></li>
                            )))
                        }

                    </ul>
                </div>

                <div>
                    <h5>Price</h5>

                    <form ref={formPriceRange}>
                        <div className='price-list'>
                            <div className='price-list-wrap'>
                                <input placeholder='$ Min' name={'min'} />
                                <span>To</span>
                                <input placeholder='$ Max' name={'max'} />
                                <a onClick={handlePriceRange} style={{ color: 'white' }} ><Icon icon="material-symbols:arrow-right-alt" /></a>
                            </div>
                        </div>
                    </form>

                </div>

                <div>
                    <h5>Brand</h5>

                    <ul className='filter-list-item'>

                        {props.filtersResult && props.filtersResult.brandNameList &&
                            (props.filtersResult.brandNameList.map(item => (

                                <li>
                                    <label htmlFor="brand" className="black-custom-checkbox">
                                        <input type="checkbox" name={item} value={item} id={item} />
                                        <span>
                                            <Icon icon="charm:tick" />
                                        </span>
                                        <p>{item}</p>
                                    </label>
                                </li>

                            )))

                        }

                    </ul>

                </div>

                <div>
                    <h5>Condition</h5>

                    <ul className='filter-list-item'>

                        {props.filtersResult && props.filtersResult.conditionList &&
                            (props.filtersResult.conditionList.map(condition => (
                                <li>

                                    <label htmlFor="Condition" className="black-custom-checkbox">
                                        <input type="checkbox"
                                            name={condition}
                                            value={condition}
                                            id={condition}
                                            checked={handleConditionToggle(condition)}
                                            onClick={() => handleConditionChange(condition)} />
                                        <span>
                                            <Icon icon="charm:tick" />
                                        </span>
                                        <p>{condition}</p>
                                    </label>
                                </li>

                            )))

                        }



                    </ul>
                </div>

                <div>
                    <h5>Item Location</h5>

                    <ul className='filter-list-item'>

                        {props.filtersResult && props.filtersResult.locations &&
                            (props.filtersResult.locations.map(item => (


                                <li>
                                    <label htmlFor="location" className="black-custom-checkbox">
                                        <input type="checkbox"
                                            name={item}
                                            value={item}
                                            id={item}
                                            checked={handleLocationToggle(item)}
                                            onClick={() => handleLocationChange(item)} />
                                        <span>
                                            <Icon icon="charm:tick" />
                                        </span>
                                        <p>{item}</p>
                                    </label>
                                </li>

                            )))

                        }

                    </ul>

                </div>
            </div> */}
      </div>
    </>
  );
}

export default FilterBox;
