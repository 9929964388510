import React, { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { Spinner } from "react-bootstrap";
import useDocumentTitle from "../../DocumentTitle";
import { toast } from "react-toastify/dist/react-toastify";
import "./Login.css";
import { Helmet } from "react-helmet";

function Login() {
  useDocumentTitle("PC | Login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  var payload = {};

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLoginbtn = async () => {
    setLoading(true);
    try {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(email)) {
        setLoading(false);
        toast.error("Please enter a valid email address.");
        return;
      }

      if (email === "" || password === "") {
        setLoading(false);
        toast.error("Please enter both your email address and password.");
        return;
      }
      payload.email = email;
      payload.password = password;
      const res = await axiosClient.post("Login", payload);
      if (res && res.email != "") {
        localStorage.removeItem("login");
        localStorage.setItem(
          "login",
          JSON.stringify({
            login: true,
            token: res.accessToken,
            user: res,
          })
        );
        setLoading(false);
        toast.success("You have successfully logged in.");
        navigate("/Containers");
      } else {
        setLoading(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Please check your email and password and try again.");
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://kingsurplus.com/InventoryDetail/Login`}
        />
      </Helmet>
      <div className="main-login-wrap">
        <Row className="m-0 bg-light-gray">
          <Col lg={6} className="bg-white">
            <div className="logoInfo">
              <div className="login-logo">
                <Image src="/assets/images/logo.webp" />
              </div>
              <p>
                "A good name is more desirable than great riches; to be esteemed
                is better than silver or gold." - Proverbs 22:1{" "}
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="login-box">
              <h3>Welcome Back </h3>
              <form onSubmit={handleLoginbtn}>
                <div className="login-input-width">
                  <div className="form-input">
                    <label>Enter Your Email</label>
                    <input
                      type="email"
                      name={"email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-input">
                    <label>Enter Your Password</label>
                    <div className="input-icon">
                      <input
                        type={showPassword ? "text" : "password"}
                        name={"password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <Icon icon="ant-design:eye-filled" />
                        ) : (
                          <Icon icon="mdi:eye-off" />
                        )}
                      </a>
                    </div>
                  </div>
                  <Link className="forgot-password-link" to="/ForgotPassword">
                    Forgot Password?
                  </Link>
                  {loading ? (
                    <button
                      className="btn btn-blue d-block w-100 btn-sign"
                      disabled
                      style={{ position: "relative" }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          color: "white",
                          top: "calc(50% - 12px)",
                          left: "calc(50% - 12px)",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-blue d-block w-100 btn-sign"
                      onClick={handleLoginbtn}
                    >
                      Sign In
                    </button>
                  )}
                </div>
              </form>

              <h6 className="signup-link">
                Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
