import React, { useEffect, useState } from "react";
import axiosClient from "../axiosClient";

import axios from "axios";

function SiteMapLoader() {
  const [sitemapXml, setSitemapXml] = useState(null);

  // useEffect(() => {
  //   const fetchSitemap = async () => {
  //     try {
  //       const xmlRes = await axios.get(
  //         "https://prorental.blob.core.windows.net/kingsurplus-web-container/sitemap.xml"
  //       );
 
  //     } catch (error) {
  //       console.error("Error fetching sitemap:", error);
  //     }
      
  //   };

  //   fetchSitemap();
  // }, []);

  return (
    <>
    </>
  );
}

export default SiteMapLoader;
