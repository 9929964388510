import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axiosClient from "../../axiosClient";
import CheckOutPreferencesForm from "./CheckOutPreferencesForm";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from '../../constants';


const stripePromise = loadStripe(appConstants.stripePublishedKey);

function PaymentPreferences() {
    const [paymentPreferences, setPaymentPreferences] = useState([]);
    useEffect(() => {
        getPaymentPreferences();
    }, []);

    const refreshData = () => {
        getPaymentPreferences();
    };
    return (
        <>
            <Card style={{ width: "100%" }} className="mb-4">
                <Card.Body>
                    <Row>
                        <Col lg={6} >
                            <div className='bg-gray px-2' >
                                <div>
                                    <Card.Title>Payment Preferences</Card.Title>
                                </div>
                            </div>
                            <div className="TableResponsive">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Nickname </th>
                                            <th>Expire Date</th>
                                            <th>Card's Last 4 digits</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentPreferences && paymentPreferences.map(itemPP => (
                                            <tr key={itemPP.id}>
                                                <td>{itemPP.nameAlias}</td>
                                                <td>{itemPP.expireDate}</td>
                                                <td>{itemPP.cardLastDigits}</td>
                                                <td>
                                                    <Button type="button" className=" btn btn-primary btn-sm "
                                                        onClick={() => { deletePaymentPreference(itemPP.id) }}  >

                                                        <Icon icon="ic:baseline-delete" />
                                                    </Button >
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col lg={6} >
                            <div className='bg-gray p-3'>
                                <Card.Title>Please Add Your Payment Information</Card.Title>
                                <Elements stripe={stripePromise}>
                                    <CheckOutPreferencesForm refreshData={refreshData} />
                                </Elements>
                            </div>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );


    function getPaymentPreferences() {
        const userData = JSON.parse(localStorage.getItem('login'));

        if(userData && userData.user){
        axiosClient.get("getPaymentPreferencesInfoByUserId/" + Number(userData.user.id))
            .then(res => {
                setPaymentPreferences(res);
            })
            .catch(error => {
                console.error(error);
            });

        }
    }

    function deletePaymentPreference(id) {
        axiosClient.get("deletePaymentPreference/" + id)
            .then(res => {
                getPaymentPreferences();
                toast.success('Your card has been successfully removed.')
            })
            .catch(error => {
                console.error(error);
            });
    }
}
export default PaymentPreferences;



