import React, { useEffect, useState } from "react";
import axiosClient from "../../axiosClient";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIndustry } from "../Slices/FilterSlice";

function IndustryListView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [industryModel, setIndustryModel] = useState({});
  useEffect(() => {
    const industryList = async () => {
      try {
        const res = await axiosClient.get("GetAllIndustry");
        sortedIndustry(res);
      } catch (error) {
        console.log(error);
      }
    };

    industryList();
  }, []);

  const gotToInventory = (item) => {
    var selectItem = {
      id: item.id,
      name: item.name,
    };
    dispatch(setIndustry(selectItem));
    navigate("/Containers", { state: { industryId: item.id } });
  };
  return (
    <ul className="cateList">
      {industryModel &&
        industryModel.data &&
        industryModel.data.map((item) => (
          <li key={item.id}>
            <a
              
              onClick={() => {
                gotToInventory(item);
              }}
            >
              {item.name} ({item.itemCount})
            </a>
          </li>
        ))}
    </ul>
  );

  function sortedIndustry(res) {
    const sortedData = res.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    setIndustryModel({ data: sortedData });
  }
}

export default IndustryListView;
