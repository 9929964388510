import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SurplusInventoryInfo from "./SurplusInventoryInfo/SurplusInventoryInfo";
import RentForm from "../SwitchtoSell/RentForm/RentForm";
import useDocumentTitle from "../../DocumentTitle";

function SurplusInventory() {
  useDocumentTitle("PC | Surplus Inventory");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <div className="contactBanner SurplusBanner">
            <h3>Surplus Inventory </h3>
          </div>
          <SurplusInventoryInfo />
          <RentForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default SurplusInventory;
