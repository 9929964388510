import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./RecyclingServicesInfo.css";

function RecyclingServicesInfo() {
  return (
    <section className="sellwithusSection pb100 pt-4">
      <div className="d-flex g-5 DesktopInnerText">
        <div className="sellwithusInfo RecyclingInfo ps-0 ">
          <h4 className="mb-lg-4">
            The genesis of our enterprise lies in the practice of recycling.
            Since the 1950s, our family has been recycling for our tomorrow.
          </h4>
          <p className="mb-3">
            As a result of the nature of our business, we handle a significant
            volume of scrap material. This is not due to our inherent
            involvement in the industry, but rather, it is an essential
            byproduct of our core services, namely Client Asset Management
            through the AMP Platform™ and Surplus.
          </p>
          <p className="mb-3">
            We possess a deep appreciation for scrap material, recognizing the
            inherent symmetry that exists between it and Surplus. With our
            expertise in handling surplus, we are also well-equipped to manage
            and recycle a wide range of materials, including super alloy,
            titanium, ferrous and non-ferrous scrap, in any size or form.
          </p>
          <p>
            Whether your needs demand a modest 100 pounds per month or a
            staggering 1,000,000 pounds, rest assured that our team is fully
            capable of meeting your requirements.
          </p>
        </div>
        <div className="sellwithusImg RecyclingImgs">
          <Image src="/assets/images/new-logo.png" />
        </div>
      </div>
      <div className="MobileTextInfo">
        <h4>
          The genesis of our enterprise lies in the practice of recycling. Since
          the 1950s, our family has been recycling for our tomorrow.
        </h4>
        <div className="MobileDesInfo">
          <Image src="/assets/images/new-logo.png" />
          <p>
            As a result of the nature of our business, we handle a significant
            volume of scrap material. This is not due to our inherent
            involvement in the industry, but rather, it is an essential
            byproduct of our core services, namely Client Asset Management
            through the AMP Platform™ and Surplus.
          </p>
          <p>
            We possess a deep appreciation for scrap material, recognizing the
            inherent symmetry that exists between it and Surplus. With our
            expertise in handling surplus, we are also well-equipped to manage
            and recycle a wide range of materials, including super alloy,
            titanium, ferrous and non-ferrous scrap, in any size or form.
          </p>
          <p>
            Whether your needs demand a modest 100 pounds per month or a
            staggering 1,000,000 pounds, rest assured that our team is fully
            capable of meeting your requirements.
          </p>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="distinctText">
        <h5 className="mt-0">
          We have 4 distinct BU’s within our scrap operations
        </h5>
        <Row>
          <Col lg={3} md={6}>
            <h6>
              Super Alloy <br /> <small>(High Temp)</small>
            </h6>
          </Col>
          <Col lg={3} md={6}>
            <h6>Titanium</h6>
          </Col>
          <Col lg={3} md={6}>
            <h6>
              Non-Ferrous <br />
              <small>(Red and White Metals)</small>
            </h6>
          </Col>
          <Col lg={3} md={6}>
            <h6>Ferrous</h6>
          </Col>
        </Row>
        <h5>We will manage the entire process for you</h5>
        <Row>
          <Col lg={2} md={6}>
            <h6>Collection</h6>
          </Col>
          <Col lg={2} md={6}>
            <h6>Shipping</h6>
          </Col>
          <Col lg={4} md={6}>
            <h6>
              Processing
              <Row>
                <Col md={6}>
                  <p>Cutting</p>
                </Col>
                <Col md={6}>
                  <p>Crushing</p>
                </Col>
                <Col md={6}>
                  <p>Briquetting</p>
                </Col>
                <Col md={6}>
                  <p>Shredding</p>
                </Col>
              </Row>
            </h6>
          </Col>
          <Col lg={2} md={6}>
            <h6>Quality Control</h6>
          </Col>
          <Col lg={2} md={6}>
            <h6>Settlements</h6>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default RecyclingServicesInfo;
