import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  ListGroup,
  Container,
  ListGroupItem,
  Alert,
  Form,
  Card,
} from "react-bootstrap";
import axiosClient from "../../axiosClient";
import { useSelector } from "react-redux";
import getStateAbbreviation from "../../Helper/getStateAbbreviation";
import {
  carriersId,
  ODFLserviceOptions,
  ODFlServiceNames,
} from "../../constants";
import _ from "lodash";
import setWeightCriteriaForTLT from "../../Helper/setWeightCriteriaForTLT";
import "./ShipeEgine.css";
import "./CarrierFreightQuote.css";

function CarrierFreightQuote(props) {
  const [loadingData, setLoadingData] = useState(false);
  const [itemFee, setItemFee] = useState(0);
  const [rates, setRates] = useState([]);
  const [days, setDays] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const cart = useSelector((state) => state.cart);
  const [pickupDate, setPickupDate] = useState("");
  const [ODFLOptionValues, setODFLOptionValues] = useState([]);
  const [selectedODFLServiceCode, setSelectedODFLServiceCode] =
    useState("stnd");
  const [calculateTotalODFL, setCalculateTotalODFL] = useState(0);
  const [isQuote, setIsQuote] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 2);
  const minDate = currentDate.toISOString().slice(0, 10);
  var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  const handleODFLServiceCodeChange = (event) => {
    setSelectedODFLServiceCode(event.target.value);
  };

  const handleServiceChange = (event) => {
    setErrorMessage("");
    const { name, checked } = event.target;
    if (checked) {
      setODFLOptionValues((prevOptions) => [
        ...prevOptions,
        {
          code: event.target.value,
          shipEngineAttributes: {
            name: cart.shippingAddres[0].nameAlias,
            phone: cart.shippingAddres[0].phoneNumber,
          },
        },
      ]);
    } else {
      setODFLOptionValues((prevOptions) =>
        prevOptions.filter((option) => option.code !== event.target.value)
      );
    }
  };

  const handleSubmitODFL = (event) => {
    event.preventDefault();
    getRates(carriersId.ODFL);
  };

  const handleDateChangeODFL = (event) => {
    setPickupDate(event.target.value);
  };

  const handleQuoteRateAgain = () => {
    intialValues();
  };

  useEffect(() => {
    intialValues();
  }, []);

  useEffect(() => {
    if (calculateTotalODFL > 0) {
    }
  }, [calculateTotalODFL]);

  const calculateTotal = (data, response) => {
    let totalItemsAmount = 0;
    let accessorial = 0;
    data.forEach((item, i) => {
      if (item.type === "total") {
        //total = Number(item.amount.value);
        totalItemsAmount = Number(item.amount.value) * 1.35;
      }
      if (item.type === "accessorial") {
        var markUp = Number(item.amount.value) * 1.35;
        accessorial = accessorial + markUp;
        data[i].amount.value = markUp;
      }
    });

    setItemFee(formattedValue(totalItemsAmount));
    // let finalTotal = (itemVal + accessorial).toFixed(2);
    let finalTotal = (totalItemsAmount + accessorial).toFixed(2);
    // setItemFee(formattedValue(itemVal));
    setCalculateTotalODFL(formattedValue(finalTotal));
    setRates(data);
    var res = JSON.parse(response);
    res.amount = finalTotal;
    props.cofirmFreight(res);
  };

  function intialValues() {
    setIsQuote(false);
    setErrorMessage("");
    setCalculateTotalODFL(0);
    setRates([]);
    setDays("");
    setODFLOptionValues([]);
    setSelectedODFLServiceCode("stnd");
    setPickupDate("");
  }

  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);
      if (v > 0) {
        return v;
      } else {
        return "0.00";
      }
    } else {
      return "0.00";
    }
  }

  function getRates(carrierId) {
    try {
      setLoadingData(true);

      let address1 = "";
      let postal = "";
      let city = "";
      let packages = [];
      let total_linear_length = 0;
      let total_width = 0;
      let total_height = 0;
      let total_weight = 0;
      address1 = "100 Ave T";
      postal = "78840";
      city = "Del Rio";

      if (
        cart.shippingAddres[0] == undefined ||
        cart.shippingAddres[0] == null
      ) {
        setErrorMessage("Please Select Shipping Address");
        setLoadingData(false);
        return true;
      }

      setErrorMessage("");
      let stateAbbre = getStateAbbreviation(cart.shippingAddres[0].state);

      if (cartItems.length > 0) {
        total_width = Number(cartItems[0].widthInches);
      }

      cartItems.forEach((item) => {
        if (setWeightCriteriaForTLT(item) == true) {
          let pckg = {
            package_code: item.packageCode,
            freight_class: Number(item.shippingClass),
            nmfc_code: " ",
            description: item.description,
            dimensions: {
              length: Number(item.lengthInches),
              width: Number(item.widthInches),
              height: Number(item.heightInches),
              unit: "inches",
            },
            weight: {
              value: Number(item.estimatedTotalWeight),
              unit: "pounds",
            },
            quantity: Number(item.selectedQty),
            stackable: false,
            hazardous_materials: false,
          };
          let sum =
            Number(item.lengthInches) +
            Number(item.widthInches) +
            Number(item.heightInches);

          total_linear_length += sum;
          total_height += Number(item.heightInches);

          total_weight += Number(item.estimatedTotalWeight);
          packages.push(pckg);

          ItemQBOptions(item);
        }
      });

      let tltBody = {
        shipmentRequest: {
          service_code: selectedODFLServiceCode,
          pickup_date: pickupDate,
          packages: packages,
          optionValues: ODFLOptionValues,

          to_address: {
            name: cart.shippingAddres[0].nameAlias,
            company_name: cart.shippingAddres[0].companyName,
            address_line1: cart.shippingAddres[0].address1,
            address_line2: " ",
            city_locality: cart.shippingAddres[0].city,
            state_province: stateAbbre,
            postal_code: cart.shippingAddres[0].zip_PostalCode,
            country_code: cart.shippingAddres[0].country,
            phone: cart.shippingAddres[0].phoneNumber,
            shipeEngineContact: {
              name: cart.shippingAddres[0].nameAlias,
              phone_number: cart.shippingAddres[0].phoneNumber,
              email: cart.shippingAddres[0].email,
            },
          },
          from_address: {
            name: "Pro Container",
            company_name: "Pro Container",
            address_line1: address1,
            address_line2: " ",
            city_locality: city,
            state_province: stateAbbre,
            postal_code: postal,
            country_code: "US",
            phone: "830-282-0000",
            shipeEngineContact: {
              name: "Pro Container",
              phone_number: "830-775-2580", // TODO : Update this phone number
              email: "shipping@procontainers.com",
            },
          },

          bill: {
            type: "shipper",
            payment_terms: "prepaid",
            account: "12823507",
            address: {
              name: "Pro Container",
              company_name: "Pro Container",
              address_line1: address1,
              address_line2: " ",
              city_locality: city,
              state_province: stateAbbre,
              postal_code: postal,
              country_code: "US",
              phone: "830-775-2580",
              shipeEngineContact: {
                name: "Pro Container",
                phone_number: "830-775-2580",
                email: "shipping@procontainers.com",
              },
            },
            contact: {
              name: cart.shippingAddres[0].nameAlias,
              phone_number: cart.shippingAddres[0].phoneNumber,
              email: cart.shippingAddres[0].email,
            },
          },
          shipeEngineRequestedBy: {
            company_name: "Pro Container",
            contact: {
              name: "Pro Container",
              phone_number: "830-775-2580",
              email: "shipping@procontainers.com",
            },
          },
        },
        measurements: {
          total_linear_length: {
            value: total_linear_length,
            unit: "inches",
          },
          total_width: {
            value: total_width,
            unit: "inches",
          },
          total_height: {
            value: total_height,
            unit: "inches",
          },
          total_weight: {
            value: total_weight,
            unit: "pounds",
          },
        },
        CarrierId: carrierId,
      };

      axiosClient
        .post("getRatesTLT", tltBody)
        .then((response) => {
          let charges = JSON.parse(response).charges;
          if (charges && charges.length > 0) {
            calculateTotal(charges, response);
            setDays(JSON.parse(response).estimated_delivery_days);
            setLoadingData(false);
            setIsQuote(true);
          } else {
            var err = JSON.parse(response).errors[0].message;
            setErrorMessage(err);
            setRates({});
            setDays("");
            setLoadingData(false);
          }
        })
        .catch((error) => {
          setLoadingData(false);
          setErrorMessage(error);
        });
    } catch (error) {
      setErrorMessage(error);
    }
  }

  return (
    <div className="card">
      <div className="card-header">Freight Quote</div>
      <div className="card-body p-2 m-0">
        <div className="row p-2">
          <div className="col-lg-6 mb-3">
            <div className="card">
              <div className="card-header">Items in Shipment</div>
              <div className="card-body p-2 m-0">
                <div>
                  {cartItems != null &&
                    cartItems != undefined &&
                    cartItems.map((product) => (
                      <>
                        {setWeightCriteriaForTLT(product) == true && (
                          <div
                            className="d-flex flex-column justify-content-between align-items-cente p-1 m-1 rounded shadow-sm"
                            key={product.id}
                          >
                            <span className="text-truncate fw-bold">
                              {product.description}
                            </span>
                            <i> Qty: {product.selectedQty}</i>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            {(() => {
              return (
                <div>
                  <Container>
                    <Row>
                      <Col lg={12}>
                        {loadingData == true ? (
                          <ListGroup>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "50%",
                              }}
                            >
                              <Spinner
                                animation="border"
                                style={{
                                  color: "#1A2C55",
                                  width: "5rem",
                                  height: "5rem",
                                }}
                              />
                            </div>
                          </ListGroup>
                        ) : (
                          <ListGroup>
                            {<></>}
                            {cart.shippingAddres[0] !== null &&
                              cart.shippingAddres[0] !== undefined && (
                                <>
                                  {errorMessage != "" &&
                                    loadingData == false && (
                                      <Alert
                                        variant="danger"
                                        onClose={() => {
                                          setErrorMessage("");
                                          setLoadingData(false);
                                        }}
                                        dismissible
                                      >
                                        <p>
                                          {errorMessage}
                                          May be using Wrong Service Type and
                                          Accessorials Charges
                                        </p>
                                      </Alert>
                                    )}
                                  {!isQuote && cartItems.length > 0 && (
                                    <Col>
                                      <form onSubmit={handleSubmitODFL}>
                                        <div className="card mb-3 p-2">
                                          <div className="row ">
                                            <div className="col-12">
                                              <label
                                                htmlFor="pickupDate"
                                                className="mt-2 "
                                              >
                                                {" "}
                                                Pickup Date
                                              </label>
                                            </div>
                                            <div className="col-12">
                                              <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Pickup Date"
                                                id="pickupDate"
                                                required
                                                value={pickupDate}
                                                onChange={handleDateChangeODFL}
                                                min={minDate}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {days != "" && (
                                          <div className="card mb-3 p-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="mb-2">
                                                <label
                                                  htmlFor="pickupDate"
                                                  className="mt-2 "
                                                >
                                                  {" "}
                                                  Estimated delivery Time
                                                </label>
                                              </div>
                                              <div>
                                                <h6 className="mb-0">
                                                  {days} days{" "}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <Card className="mb-3">
                                          <Card.Header>
                                            Service Type Selection{" "}
                                          </Card.Header>
                                          <Card.Body className="p-2 m-0">
                                            <Form.Group>
                                              {Object.entries(
                                                ODFlServiceNames
                                              ).map(([code, name]) => (
                                                <Form.Check
                                                  key={code}
                                                  type="radio"
                                                  label={name}
                                                  name="odfl-service"
                                                  value={code}
                                                  checked={
                                                    selectedODFLServiceCode ===
                                                    code
                                                  }
                                                  onChange={
                                                    handleODFLServiceCodeChange
                                                  }
                                                />
                                              ))}
                                            </Form.Group>
                                          </Card.Body>
                                        </Card>
                                        <div className="card mb-3 ">
                                          <div className="card-header">
                                            {" "}
                                            Accessorials Charges{" "}
                                          </div>
                                          <div className="card-body p-2 m-0">
                                            <div className="mt-2 service">
                                              {Object.entries(
                                                ODFLserviceOptions
                                              ).map(([code, name]) => (
                                                <Form.Check
                                                  key={code}
                                                  inline
                                                  label={name}
                                                  type="checkbox"
                                                  value={code}
                                                  checked={ODFLOptionValues.some(
                                                    (option) =>
                                                      option.code === code
                                                  )}
                                                  onChange={handleServiceChange}
                                                />
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <button
                                              type="submit"
                                              className="btn btn-primary btn-lg w-100"
                                            >
                                              Get Rate
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </Col>
                                  )}
                                  {isQuote && (
                                    <Col lg={12}>
                                      <div className="scroll60">
                                        {rates != undefined &&
                                          rates != null &&
                                          rates.length > 0 && (
                                            <Card className="mb-2">
                                              <Card.Header>
                                                Shipping fee for all freight
                                                Items
                                              </Card.Header>
                                              <Card.Body className="p-2 m-0">
                                                {itemFee != undefined &&
                                                  itemFee > 0 && (
                                                    <>
                                                      <div>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                          <div>
                                                            <div>
                                                              <label>
                                                                Transportation
                                                                Fee(s)
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div>
                                                            <h6 className="mb-0">
                                                              ${itemFee}{" "}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                              </Card.Body>
                                            </Card>
                                          )}
                                        {rates != undefined &&
                                          rates != null &&
                                          rates.length > 0 && (
                                            <Card className="mb-2">
                                              <Card.Header>
                                                Additional Services Requested{" "}
                                              </Card.Header>
                                              <Card.Body className="p-2 m-0">
                                                {rates != undefined &&
                                                  rates != null &&
                                                  rates.length > 0 &&
                                                  rates.map((rate) => (
                                                    <>
                                                      {rate.type ==
                                                        "accessorial" && (
                                                        <div>
                                                          <div className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                              {rate.description && (
                                                                <div>
                                                                  <label className="mt-2">
                                                                    {
                                                                      rate.description
                                                                    }
                                                                  </label>
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div>
                                                              <h6 className="mb-0">
                                                                {" "}
                                                                $
                                                                {rate.amount &&
                                                                  rate.amount
                                                                    .value && (
                                                                    <>
                                                                      {formattedValue(
                                                                        Number(
                                                                          rate
                                                                            .amount
                                                                            .value
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )}{" "}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </>
                                                  ))}
                                              </Card.Body>
                                            </Card>
                                          )}
                                        {rates != undefined &&
                                          rates != null &&
                                          rates.length > 0 &&
                                          calculateTotalODFL != undefined &&
                                          calculateTotalODFL > 0 && (
                                            <ListGroupItem>
                                              <div className="d-flex justify-content-between align-items-center px-2">
                                                <div className="pt-2 mb-2">
                                                  <h6 className="mb-0">
                                                    Total charges
                                                  </h6>
                                                </div>
                                                <div>
                                                  <h6 className="mb-0">
                                                    $ {calculateTotalODFL}{" "}
                                                  </h6>
                                                </div>
                                              </div>
                                            </ListGroupItem>
                                          )}
                                        <Button
                                          variant="primary"
                                          className="mt-2 w-100 btn-block"
                                          type="button"
                                          onClick={handleQuoteRateAgain}
                                        >
                                          {" "}
                                          Get Quote Again
                                        </Button>
                                      </div>
                                    </Col>
                                  )}
                                </>
                              )}
                          </ListGroup>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              );
            })()}
          </div>
        </div>
      </div>
    </div>
  );
  function ItemQBOptions(item) {
    var itemOptions = JSON.parse(item.freightAdditionalOptions);
    if (
      itemOptions != undefined &&
      itemOptions != null &&
      cart.shippingAddres[0] !== null &&
      cart.shippingAddres[0] !== undefined
    ) {
      itemOptions.forEach((qbOption) => {
        let splitString = qbOption.split(":");
        let val = splitString[0].trim();
        setODFLOptionValues((prevOptions) => [
          ...prevOptions,
          {
            code: val,
            shipEngineAttributes: {
              name: cart.shippingAddres[0].nameAlias,
              phone: cart.shippingAddres[0].phoneNumber,
            },
          },
        ]);
      });
    }
  }
}
export default CarrierFreightQuote;
