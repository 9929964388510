import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./ClientManagemenInfo.css";

function BuyAContainer() {
  return (
    <section className="sellwithusSection pb100 pt-5">
      <Tab.Container id="ClientTabs" defaultActiveKey="first">
        <Row>
          <Col lg={3} className="mb-3">
            <Nav variant="pills" className="flex-column about-column">
              <Nav.Item>
                <Nav.Link eventKey="first">1. Universal Application</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">2. Cost-Effective</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">
                  3. Flexibility & Scalability 
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">4. Superior Protection</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">5.Mobility</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">6.Sustainable & Green</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seven">7.Customizable</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="bg-white d-block">
                  <h4 className="about-title">1.Universal Application</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Greetings, industry giants, trailblazing startups, and
                          savvy homeowners! Ever encountered a storage challenge
                          where temperature makes all the difference? Whether
                          you're safeguarding delicate products, preserving
                          cherished items, or simply seeking year-round comfort,
                          our climate-controlled shipping containers are the
                          magic key. Let’s explore why these containers are the
                          perfect fit for literally everyone.
                        </p>
                        <p>
                          <strong>Business Titans: </strong>
                          Store vast volumes of temperature-sensitive goods,
                          electronics, or perishables, ensuring they remain in
                          optimal condition.
                          <br></br>
                          <br></br>
                          <strong>Budding Entrepreneurs: </strong>
                          Launch a pop-up store, boutique cafe, or tech hub
                          without breaking the bank, and without battling
                          external temperature changes.
                          <br></br>
                          <br></br>
                          <strong>Neighborly Needs: </strong>Need a wine cellar?
                          A personal gym that’s cool in summer and warm in
                          winter? Or perhaps a space to store family heirlooms?
                          Say no more!
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div div className="bg-white d-block">
                  <h4 className="about-title">2.Cost-Effective</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Energy Efficiency: </strong> Cutting-edge
                          insulation means temperature regulation without the
                          hefty utility bills.
                          <br></br>
                          <br></br>
                          <strong>Long-Term Savings: </strong>Protecting your
                          items from extreme temperature fluctuations prevents
                          potential loss, be it products, equipment, or
                          sentimental treasures.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="bg-white d-block">
                  <h4 className="about-title">3.Flexibility & Scalability</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Business Solutions: </strong>Start with one
                          container, and as your business grows, easily add
                          more. They're modular and easy to integrate.
                          <br></br>
                          <br></br>
                          <strong>Home Projects:</strong>
                          Expand your living space, create a workshop, or set up
                          that hobby room you've always dreamed of.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="bg-white d-block">
                  <h4 className="about-title">4.Superior Protection</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Safety Firs: </strong>
                          Whether it's protecting valuable art, delicate
                          fabrics, electronic equipment, or food products, the
                          consistent environment guarantees longevity.
                          <br></br>
                          <br></br>
                          <strong> Weather-Resistant: </strong>
                          Come rain, sun, or snow, your items remain unaffected.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <div className="bg-white d-block">
                  <h4 className="about-title">5.Mobility</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Business on the Go: </strong>
                          For startups or businesses that thrive on mobility,
                          these containers can be easily relocated, ensuring
                          your products remain perfect during transit.
                          <br></br>
                          <br></br>
                          <strong>Homeowners: </strong>
                          Come rain, sun, or snow, your items remain unaffected.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="six">
                <div className="bg-white d-block">
                  <h4 className="about-title">6. Sustainable & Green</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Eco-Conscious Choice: </strong> Repurpose a
                          shipping container and reduce environmental footprint.
                          Plus, energy-efficient climate control systems further
                          your green credentials.
                          <br></br>
                          <br></br>
                          <strong>Community Gardens: </strong> For the
                          green-thumbed neighbor, create a year-round garden
                          sanctuary to grow sensitive plants, regardless of
                          external conditions.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="seven">
                <div className="bg-white d-block">
                  <h4 className="about-title">7.Customizable</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Branding for Businesses:</strong> Customize
                          exteriors to resonate with your brand ethos or
                          marketing campaigns.
                          <br></br>
                          <br></br>
                          <strong>Home Aesthetics: </strong> Paint, design, and
                          modify to blend seamlessly with your home decor or
                          stand out as a contemporary addition.
                        </p>
                        <p>
                          In today's unpredictable world, stability can be a
                          game-changer. With our climate-controlled shipping
                          containers, stability is precisely what you get. No
                          matter the size of your venture or the depth of your
                          dreams, these containers are a universal solution,
                          promising unmatched value.
                          <br></br>
                          <br></br>
                          So, whether you're a global conglomerate, a startup
                          aiming for the stars, or the neighbor seeking
                          innovative spaces, it's time to control your climate
                          and steer your destiny. Embrace the future of storage
                          and space solutions with us!
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </section>
  );
}

export default BuyAContainer;
