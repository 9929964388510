import HomeProductSection from "./HomeProductSection/HomeProductSection";
import ProjectCounter from "./ProjectCounter/ProjectCounter";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import OurServices from "./OurServices/OurServices";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NewHomeBanner from "./NewHomeBanner/NewHomeBanner";
import useDocumentTitle from "../../DocumentTitle";
import ScrollToTop from "../../scrollToTop";
import "./HomePage.css";
import SeoHelmet from "../Seo/SeoHelmet";

function HomePage() {
  const [searchText, setSearchText] = useState("");
  useDocumentTitle("PC | Home");
  return (
    <div>
      <SeoHelmet
        title="PC | Home"
        description="Welcome to ProContainer - Your Source for Surplus Business and Industrial Assets"
        name="Pro Container"
        type="website"
        canonical="https://procontainers.com"
        url="https://procontainers.com"
      />
      <ScrollToTop />
      <Header />
      <div className="d-margin">
        <NewHomeBanner />
        <OurServices />
        <Container className="mb-5">
          <HomeProductSection />
        </Container>
        <ProjectCounter />
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
