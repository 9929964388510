import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination: 0,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setPagination(state, param){
            state.pagination = param.payload
        }
    }
})

export const{
    setPagination
} = productSlice.actions;


export default productSlice.reducer;