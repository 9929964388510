import React, { useState, useEffect } from 'react';
import { Card, Button, Table } from "react-bootstrap";
import { Icon } from '@iconify/react';
import CreateShipping from "./CreateShipping/CreateShipping"
import EditShipping from "./CreateShipping/EditShipping"
import axiosClient from "../../axiosClient";
import { toast } from "react-toastify/dist/react-toastify";

function Shippings() {
    const [show, setShow] = useState(null);
    const [editShow, setEditShow] = useState(null);
    const [shippingInfos, setShippingInfos] = useState([]);
    const [editData, setEditData] = useState(null);

    const handleEditShow = () => {
        if (editShow == null) {
            if (editShow == null) {
                setEditShow(true)
            }
        }
        else {
            setEditShow(!editShow)
        }
    };

    const edit = (item) => {
        setEditData(item);
    }

    useEffect(() => {
      
        if(editData != undefined && editData != null){
            handleEditShow();
        }
        
    }, [editData])


    const handleShow = () => {
        if (show == null) {
            if (show == null) {
                setShow(true)
            }
        }
        else {
            setShow(!show)
        }
    };

    useEffect(() => {
        getshippings();
    }, []);

    const refreshData = () => {
      
        getshippings();
    };


    function deleteShippingItem(id) {
        axiosClient.get("deleteShipping/" + id)
            .then(res => {
                getshippings();
                toast.success("Shipping Addresses added successfully");
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <>
            <Card style={{ width: "100%" }} className="mb-4">
                <Card.Body>
                    <div className='flex-between'>
                        <div>
                            <Card.Title>Shipping Addresses</Card.Title>
                        </div>
                        <div className='pr-3 pb-3'>
                            <Button onClick={handleShow} type="button" className=" btn btn-primary pt-1" >
                                <Icon icon="material-symbols:add" /> Add
                            </Button >
                        </div>
                    </div>
                    <div className="TableResponsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nickname</th>
                                    
                                    <th>Address 1</th>
                                    <th>Address 2</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Zip / Postal Code</th>
                                    <th>Country</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shippingInfos && shippingInfos.map(shippingInfo => (
                                    <tr key={shippingInfo.id}>
                                        <td>{shippingInfo.nameAlias}</td>
                                       
                                        <td>{shippingInfo.address1}</td>
                                        <td>{shippingInfo.address2}</td>
                                        <td>{shippingInfo.city}</td>
                                        <td>{shippingInfo.state}</td>
                                        <td>{shippingInfo.zip_PostalCode}</td>
                                        <td>{shippingInfo.country}</td>
                                        <td>
                                            <div className='d-flex'>
                                                <Button type="button"  onClick={() => { edit(shippingInfo) }}  className=" btn btn-primary btn-sm ">
                                                    <Icon icon="material-symbols:edit" />
                                                </Button >
                                                <Button type="button" className=" btn btn-primary btn-sm  ms-1"
                                                    onClick={() => { deleteShippingItem(shippingInfo.id) }}  >
                                                    <Icon icon="ic:baseline-delete" />
                                                </Button >
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                </Card.Body>
            </Card>
            <CreateShipping show={show} refreshData={refreshData} />

            {
                editData != undefined && editData != null 
                &&  <EditShipping show={editShow}  refreshData={refreshData}  editData ={editData} />

            }

        </>
    );

    function getshippings() {
        const userData = JSON.parse(localStorage.getItem('login'));
        if(userData && userData.user){
        axiosClient.get("getShippingsInfoByUserId/" + Number(userData.user.id))
            .then(res => {
                setShippingInfos(res);
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
}
export default Shippings;
