import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./OurHistory.css";

function OurHistory() {
  return (
    <>
      <div className="bg-white ">
        <div className="histroy-info">
          <Row className="align-items-center mb-4">
            <Col lg={6}>
              <p>
                At ProContainers, we're not just another container company. In
                fact, we weren't even in the business of containers to begin
                with. We found ourselves frustrated with the shady sellers,
                constant stock-outs, and overall poor experience that came with
                relying on middlemen. We were tired of the hassle and decided to
                take matters into our own hands.
              </p>
              <p>
                That's how ProContainers was born. With a burning desire to fix
                the problem and provide a better experience for customers, we
                set out on a mission to revolutionize the container industry. We
                wanted to eliminate the middlemen, ensuring complete control
                over every aspect of our business. From owning all of our
                containers, trucks, and equipment to managing all of our
                staffing internally, we have built a company that operates with
                efficiency and transparency.
              </p>
              <p>
                At ProContainers, we value speed, reliability, and convenience.
                We understand the importance of fast answers, prompt
                transportation, and easy payments. We have streamlined our
                processes to ensure that you get the service you need without
                any unnecessary delays or complications. When you choose
                ProContainers, you can expect quick responses, swift
                transportation, and hassle-free transactions.
              </p>
            </Col>
            <Col lg={6}>
              <div className="histroy-img">
                <Image src="/assets/images/about-img-01.png" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="histroy-img histroy-img2">
                <Image src="/assets/images/about-img-02.png" />
              </div>
            </Col>
            <Col lg={6}>
              <p>
                With over 60 years of experience in various industries through
                our sister companies, we bring a wealth of knowledge and
                expertise to the container business. As a fourth-generation
                family-owned company, we have a deep-rooted commitment to
                customer service and building lasting relationships with our
                incredible customers. We understand that your satisfaction is
                the foundation of our success, and we go above and beyond to
                exceed your expectations.
              </p>
              <p>
                At ProContainers, we are dedicated to providing you with the
                highest quality containers, reliable transportation, and
                exceptional customer service. Whether you need containers for
                storage, shipping, or any other purpose, we have the solution
                for you. Trust in our experience, reliability, and commitment to
                excellence as we work together to meet your container needs.
                Thank you for choosing ProContainers. We look forward to serving
                you and building a long-lasting partnership.
                <br></br>
                <br></br>Sincerely,
                <br></br>The ProContainers Team
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default OurHistory;
