import React from "react";
import { Container, Image } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ClientManagemenInfo from "./ClientManagemenInfo/BuyAContainer";
import ClientManagemenForm from "./ClientManagemenForm/ClientManagemenForm";
import useDocumentTitle from "../../DocumentTitle";

function ClimateControlContainer() {
  // useDocumentTitle("PC | Buy A Container");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <div className="contactBanner ClientBanner DesktopInnerText">
            <h3>Climate Control Containers</h3>
            <p>
              Unlock the Future with Climate-Controlled Shipping Containers:
              Everyone's Ultimate Solution!
            </p>
          </div>
          <div className="MobileTextInfo">
            <h4>Client Asset Management </h4>
            <div className="MobileDesInfo">
              <Image src="/assets/images/clientManagement.webp" />
              <p>
                Empowering Success: Your Assets, Our Expertise <sup>TM</sup>
              </p>
            </div>
          </div>
          <div className="clearfix"></div>
          <ClientManagemenInfo />
          <ClientManagemenForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default ClimateControlContainer;
