import { Button, Col, Image, Row } from "react-bootstrap";
import React, { useRef } from "react";
import Category from "../../Category/Category";
import Industry from "../../Industry/Industry";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewHomeBanner.css";

const bannerSlides = [
  {
    image: "/assets/images/h1.webp",
    title: "Buy a Container",
    desc: "Overpricing, shady dealings, delayed deliveries, and stockouts leaving you frustrated? Your search ends here! We prioritize value, punctuality, and constant availability in our shipping container sales. We own all our containers, eliminating 'callback' waits. Let us transform your experience in the secondary container market!",
  },
  {
    image: "/assets/images/h2.webp",
    title: "Rent a Container",
    desc: "Tired of hidden fees, delays, unavailable or distant rentals? We've got you covered! We offer transparent pricing - what you see is what you get. Our containers are perfect for job sites, ranches, or anywhere they fit. Rent hassle-free, local containers with us and never look back!",
  },
  {
    image: "/assets/images/h7.webp",
    title: "Climate Controlled Containers",
    desc: "Explore the versatility of our climate-controlled containers! Perfect for any purpose, these multi-use units adapt to your needs, ensuring optimal conditions regardless of your project. Unlock endless possibilities with our all-climate solution in a container!",
  },
  {
    image: "/assets/images/h8.webp",
    title: "Portable Offices",
    desc: "Transform the way you work with our portable office containers. Climate-controlled, multi-office, or open – tailor your space for any purpose. These multi-use units redefine versatility, providing a custom work solution right where you need it. Step into your portable office haven!",
  },
  // {
  //   image: "/assets/images/h5.webp",
  //   title: "Modular Manufacturing",
  //   desc: "Have unique scrap recycling needs? We can manage all of it. From superalloys, titanium alloys, down to iron and cardboard. We can manage the full lifecycle of your scrap from shipment at your facilities to processing, handling, quality control and compliance.",
  // },
  {
    image: "/assets/images/h5.webp",
    title: "Sell us your container",
    desc: "Ready to sell your container? Provide all relevant details and we'll promptly present you with a fair proposal. Turn your surplus into profit effortlessly with us. We make selling containers as simple as a click. Share your info, get a quote - it's that straightforward!",
  },
];
function NewHomeBanner() {
  const navigate = useNavigate();
  const form1 = useRef(null);
  var payload = {};
  const handleChangeIndustry = (id) => {
    if (Number(id) > 0) {
      payload.IndustryId = Number(id);
      payload.IsGlobelSearch = true;
    }
  };
  const handleChangeCategory = (id) => {
    if (Number(id) > 0) {
      payload.CategoryId = Number(id);
      payload.IsGlobelSearch = true;
    }
  };
  const settings = {
    infinite: true,
    dots: false,
    autoplay: true,
    navs: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          navs: false,
          dots: true,
        },
      },
    ],
  };

  const handleSearchClickEvent = () => {
    navigate("/Containers");
  };
  return (
    <>
      <Slider {...settings} className="HomeBannerSlider">
        {bannerSlides.map((slid, index) => (
          <div className="Home_Banner_Wrap" key={index}>
            <div className="container">
              <div className="Home_Banner_Info">
                <h1>{slid.title}</h1>
                <p>{slid.desc}</p>
                <div className="HomeIndustries">
                  <Row>
                    <Col lg={5} md={6} sm={12} className="DropZindex">
                      <Industry
                        onChangeIndustry={handleChangeIndustry}
                      ></Industry>
                      <span style={{ fontSize: "0.7rem", fontWeight: "600" }}>
                        Note: Select* Container Type First, Then Container Size
                      </span>
                    </Col>
                    <Col lg={5} md={6} sm={12}>
                      <Category
                        onChangeCategory={handleChangeCategory}
                      ></Category>
                    </Col>
                    <Col lg={2}>
                      <Button
                        variant=""
                        onClick={handleSearchClickEvent}
                        className="btn-blue btn-homeSearch"
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="Home_Banner_Img">
              <div className="Home_Banner_item_img">
                <Image src={slid.image} alt="Slide" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default NewHomeBanner;
