import React from "react";
import { Col, Container, Row, Tab } from "react-bootstrap";
import HowWeWork from "./HowWeWork/HowWeWork";
import OurHistory from "./OurHistory/OurHistory";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./AboutusPage.css";
import useDocumentTitle from "../../DocumentTitle";

function ModularManufacturing() {
  useDocumentTitle("PC | Modular Manufacturing");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <div className="about-banner">
            <h3>Modular Manufacturing Page (DEMO)</h3>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <OurHistory />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <HowWeWork />
          <div className="SlogenText">
            <h3>
              An enterprise built upon Faith, family, relationships, and
              service.
            </h3>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default ModularManufacturing;
