import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ProductCard from "../../ProductCard/ProductCard";
import * as appConstants from "../../../constants";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import IndustryListView from "../../IndustyListView/IndustryListView";
import CX from "classnames";
import "./HomeProductSection.css";

const HomeProductSection = () => {
  const [recentProductList, setRecentProductList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    fetchRecentItems();
  }, []); // Empty dependency array will trigger the effect only once on mount

  const fetchRecentItems = () => {
    if (isLoading) return; // If API call is already in progress, do not make another call
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(appConstants.baseUrl + "GetRecentItems", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const sortedResult = result.sort((a, b) =>
          a.title.localeCompare(b.title)
        );

        setRecentProductList(sortedResult);
        console.log(result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  };

  const doToggleSidebar = () => {
    document.body.classList.toggle("showSidebar");
    setToggleSidebar((prevState) => !prevState);
  };

  return (
    <div className="HomeProductSection">
      <div className="product-wrap">
        <div className={CX("product-sidebar", { show: toggleSidebar })}>
          <h5>
            Browse by Category
            <Link to="#" onClick={() => doToggleSidebar()}>
              <Icon icon="ic:sharp-close" />
            </Link>
          </h5>
          <IndustryListView />
        </div>
        <div className="product-main-list">
          <div className="product-header-wrap">
            {/* TODO: ask whether this needs to change or not */}
            <h2>Our Containers</h2>
            <Link
              to="#"
              className="CloseSidebar"
              onClick={() => doToggleSidebar()}
            >
              <Icon icon="material-symbols:filter-list-rounded" />
            </Link>
          </div>
          <Row className="homePageRow">
            {recentProductList.map((item) => (
              <Col key={item.id} xl={4} lg={4} md={6}>
                <ProductCard RecentProductList={item} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default HomeProductSection;
