import React, { useState, useEffect, useRef } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import {
  Col,
  Row,
  Card,
  Table,
  Tab,
  Form,
  Alert,
  Spinner,
  Nav,
} from "react-bootstrap";
import { Icon } from "@iconify/react";
import axiosClient from "../../axiosClient";
import * as appConstants from "../../constants";
import axios from "axios";
import uuid from "react-uuid";
import Billings from "../Billings/Billings";
import SaveForLater from "../SaveForLater/SaveForLaters";
import Shippings from "../Shippings/Shippings";
import CompanyContacts from "../ComapanyContacts/CompanyContacts";
import PaymentPreferences from "../PaymentPreferences/PaymentPreferences";
import useDocumentTitle from "../../DocumentTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify";
import "./Profile.css";

function Profile() {
  const navigate = useNavigate();
  useDocumentTitle("PC | My Profile");
  const [user, setUser] = useState({});
  var userData = JSON.parse(localStorage.getItem("login"));
  const [isSubmit, setIsSubmit] = useState(false);
  const [isExamet, setIExamet] = useState(false);
  const formRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [fileResponse, setFileResponse] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  var fileRes = null;
  var data = {};

  // if (userData == null) {
  //   navigate("/login");
  // }

  const handleSubmitFileUrl = async (e) => {
    var fileData = {};
    setLoading(true);
    e.preventDefault();
    fileData.examet = isExamet;
    if (!file && isExamet == true) {
      toast.error("A file is required If check Tax Examet!");
      setLoading(false);
      return;
    }
    if (isExamet == true) {
      await uploadFile();
    }
    if ((fileRes && fileRes.status == 200) || isExamet == false) {
      if (fileRes && fileRes.status == 200) {
        fileData.fileUrl = fileRes.data.blob.uri;
        fileData.fileName = fileRes.data.blob.name;
        fileData.fullName = "";
        fileData.businessName = "";
        fileData.email = "";
        fileData.password = "";
        fileData.id = userData.user.id;
        data = userData.user;
        data.fileUrl = fileData.fileUrl;
        data.fileName = fileData.fileName;
        setError(null);
        await axiosClient
          .post("UpdateFileUrlUser", fileData)
          .then((res) => {
            let token = userData.token;
            localStorage.removeItem("login");
            localStorage.setItem(
              "login",
              JSON.stringify({
                login: true,
                token: token,
                user: data,
              })
            );
            //  setIsSubmit(true)
            setFileError(null);
            toast.success("File upload successfully!");
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            toast.error("Error! Something wrong.");
            setFileResponse("");
            setLoading(false);
          });
      }
    }
    if (isExamet == false) {
      fileData.fileUrl = "";
      fileData.fileName = "";
      fileData.fullName = "";
      fileData.businessName = "";
      fileData.email = "";
      fileData.password = "";
      fileData.id = userData.user.id;
      data = userData.user;
      data.fileUrl = "";
      data.fileName = "";
      setError(null);
      await axiosClient
        .post("UpdateFileUrlUser", fileData)
        .then((res) => {
          let token = userData.token;
          localStorage.removeItem("login");
          localStorage.setItem(
            "login",
            JSON.stringify({
              login: true,
              token: token,
              user: data,
            })
          );
          //  setIsSubmit(true)
          setFileError(null);
          toast.success("File upload successfully !");
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error! Something wrong.");
          setFileResponse("");
          setLoading(false);
        });
    }
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const form = formRef.current;
    data.id = userData.user.id;
    data.fullName = form["fullName"].value;
    data.businessName = form["businessName"].value;
    data.email = form["email"].value;
    data.password = "";
    try {
      var profileRes = await axiosClient.post("UpdateUserInfo", data);
      if (profileRes) {
        let token = userData.token;
        localStorage.removeItem("login");
        localStorage.setItem(
          "login",
          JSON.stringify({
            login: true,
            token: token,
            user: profileRes,
          })
        );
        userData = JSON.parse(localStorage.getItem("login"));
        setIsSubmit(true);
        setError(null);
        toast.success("Update Profile information.");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error! Something wrong.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userData && userData.user) {
      const form = formRef.current;
      form["fullName"].value = userData.user.fullName;
      // form["businessName"].value = userData.user.businessName;
      form["email"].value = userData.user.email;
      if (userData.user.examet != true) {
        setIExamet(false);
      } else {
        setIExamet(true);
      }
    }
  }, [isSubmit]);

  useEffect(() => {
    if (userData == null) {
      navigate("/login");
    } else {
      if (userData && userData.user) {
        if (userData.user.examet != true) {
          setIExamet(false);
        } else {
          setIExamet(true);
        }
        getorderHistoryCustomerQB();
      }
    }
  }, []);
  const handlExametChange = (event) => {
    if (event.target.checked) {
      setIExamet(true);
    } else {
      setIExamet(false);
    }
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  async function uploadFile() {
    const formData = new FormData();
    const fileName = `${file.name}-${Date.now()}-${uuid()}`;
    file.fileName = fileName;
    formData.append("file", file);
    fileRes = await axios({
      method: "post",
      url: appConstants.baseUrl + "Upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  let [xPos, setXpos] = useState(0);
  const [style, setStyle] = React.useState({
    transform: `translateX(${xPos}px)`,
  });
  const onClick = (direction) => {
    const check = direction === "left" ? (xPos -= 50) : (xPos += 50);
    if (check >= -904 && check <= 0) {
      setXpos(check);
      setStyle({ transform: `translateX(${check}px)` });
    }
  };
  //where: "{40.EX.'" + userData.user.customerNameQB + "'}",
  function getorderHistoryCustomerQB() {
    const data = JSON.stringify({
      from: appConstants.config.qbOrderTbl,
      select: [
        3, 40, 25, 6, 8, 230, 343, 281, 91, 339, 340, 336, 338, 337, 228, 115,
        114, 176,
      ],
      where: "{176.EX." + userData.user.customerKeyQB + "}",
      sortBy: [
        {
          fieldId: 3,
          order: "DESC",
        },
      ],
      options: {
        skip: 0,
        top: 0,
        compareWithAppLocalTime: false,
      },
    });
    axios
      .post(
        `https://api.quickbase.com/v1/records/query`,
        data,
        appConstants.config
      )
      .then((response) => {
        setOrders(response.data.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);
      if (v > 0) {
        return v;
      } else {
        return "0.00";
      }
    } else {
      return "0.00";
    }
  }
  return (
    <>
      <Header />
      <div className="d-margin">
        {user && (
          <div className="p-4">
            <h3>My Account</h3>
            <Row>
              <Col lg={12} className="OverFlowhidden">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="profile"
                >
                  <Nav
                    variant="pills"
                    style={style}
                    className="ProfileTabs mb-3"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="company_contacts">
                        Company Contacts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="order_history">
                        Order History
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="shipping_Address">
                        Shipping Address
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="billing_Address">
                        Billing Addresses
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="payment_preferences">
                        Payment Preferences
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tax_Exemption">Exemption</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="save_later">Save Later</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="buttonNav">
                    <button
                      type="button"
                      disabled={xPos === 0}
                      onClick={() => onClick("right")}
                    >
                      <Icon icon="ic:sharp-keyboard-arrow-left" />
                    </button>
                    <button
                      type="button"
                      disabled={xPos === -750}
                      onClick={() => onClick("left")}
                    >
                      <Icon icon="ic:sharp-keyboard-arrow-right" />
                    </button>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="profile">
                      <Card style={{ width: "100%" }} className="mb-4">
                        <div className="profileContainer">
                          <Card.Title> Profile</Card.Title>
                          <Form ref={formRef}>
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="form-input">
                                  <Form.Label>Customer Number #</Form.Label>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    value={
                                      userData &&
                                      userData.user &&
                                      userData.user.customerQBId
                                    }
                                    name={"customeQBId"}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form-input">
                                  <Form.Label>Business Name</Form.Label>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    value={
                                      userData &&
                                      userData.user &&
                                      userData.user.customerNameQB
                                    }
                                    name={"customerNameQB"}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form-input">
                                  <Form.Label>Full Name</Form.Label>
                                  <Form.Control type="text" name={"fullName"} />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="form-input">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control type="email" name={"email"} />
                                </Form.Group>
                              </Col>
                              <Col lg={12}>
                                <hr />
                                <h4 className="emailTitle">
                                  Email Communications
                                </h4>
                                <Form.Group>
                                  <Form.Check
                                    defaultChecked={true}
                                    type="checkbox"
                                    id="OrderDetailMail"
                                    label="Order Details"
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={12}>
                                <Form.Group>
                                  <Form.Check
                                    defaultChecked={true}
                                    type="checkbox"
                                    id="MarketingcheckboxMail"
                                    label="Marketing Emails"
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col lg={12}>
                                <br />
                                {loading ? (
                                  <button
                                    className="btn btn-primary btn-lg btn-block w-100 mt-3"
                                    disabled
                                    style={{ position: "relative" }}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      style={{
                                        color: "white",
                                        top: "calc(50% - 12px)",
                                        left: "calc(50% - 12px)",
                                      }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary btn-lg btn-block w-100 mt-3"
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </button>
                                )}
                              </Col> */}
                            </Row>
                          </Form>
                        </div>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="company_contacts">
                      <CompanyContacts />
                    </Tab.Pane>
                    <Tab.Pane eventKey="order_history">
                      <Card style={{ width: "100%" }} className="mb-4">
                        <Card.Body>
                          <Card.Title> Order History</Card.Title>
                          <div className="order-history TableResponsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Order # </th>
                                  <th>Order Date</th>
                                  <th>Ship to Address</th>
                                  <th>Order Status</th>
                                  <th>SO Terms</th>
                                  <th>Purchase </th>
                                  <th>Rent Price</th>
                                  {/* <th>Sub-Total</th> */}
                                  <th>Transportation</th>
                                  <th>Damage Waiver</th>
                                  <th>Loadout </th>
                                  <th>Transaction</th>
                                  <th>Taxes</th>
                                  <th>Payment Due</th>
                                  <th>Balance Owed Before Release</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => (
                                  <tr key={index}>
                                    <td>{order["6"].value}</td>
                                    <td>{order["8"].value}</td>
                                    <td>{order["25"].value}</td>
                                    <td>{order["230"].value}</td>
                                    <td>{order["343"].value}</td>
                                    <td>
                                      ${formattedValue(order["339"].value)}
                                    </td>
                                    <td>
                                      ${formattedValue(order["340"].value)}
                                    </td>
                                    {/* <td>
                                      ${formattedValue(order["91"].value)}
                                    </td> */}
                                    <td>
                                      ${formattedValue(order["281"].value)}
                                    </td>
                                    <td>
                                      ${formattedValue(order["336"].value)}
                                    </td>
                                    <td>
                                      ${formattedValue(order["338"].value)}
                                    </td>

                                    <td>
                                      ${formattedValue(order["337"].value)}
                                    </td>

                                    <td>
                                      ${formattedValue(order["228"].value)}
                                    </td>

                                    <td>
                                      ${formattedValue(order["115"].value)}
                                    </td>
                                    <td>
                                      ${formattedValue(order["114"].value)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="shipping_Address">
                      <Shippings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="billing_Address">
                      <Billings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="payment_preferences">
                      <PaymentPreferences />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tax_Exemption">
                      <Card style={{ width: "100%" }} className="mb-4">
                        <div className="profileContainer">
                          <Card.Title> Exemption</Card.Title>
                          <p>
                            To apply for a exemption on your account, please
                            upload your tax exempt form below. <br />
                            <br /> Once uploaded, please click the checkbox to
                            submit for approval to the administrative team.
                          </p>
                          <Form>
                            <Row className="align-items-center">
                              <Col lg={3}>
                                <Form.Group className="form-input">
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                </Form.Group>
                                {userData && userData.user.fileUrl && (
                                  <>
                                    <span>Already apply for a exemption.</span>
                                    <a
                                      href={userData.user.fileUrl}
                                      className="nav-link"
                                    >
                                      View Uploaded file
                                    </a>
                                  </>
                                )}
                              </Col>
                              <Col lg={3}>
                                <Form.Group className="">
                                  <Form.Check
                                    checked={isExamet}
                                    value={isExamet}
                                    onChange={handlExametChange}
                                    type="checkbox"
                                    label="Exemption"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            {loading ? (
                              <button
                                className="btn btn-primary btn-lg"
                                disabled
                                style={{ position: "relative" }}
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{
                                    color: "white",
                                    top: "calc(50% - 12px)",
                                    left: "calc(50% - 12px)",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-lg mt-3"
                                onClick={handleSubmitFileUrl}
                              >
                                Save
                              </button>
                            )}
                          </Form>
                        </div>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="save_later">
                      <SaveForLater />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
export default Profile;
