import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const AppLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const excludedPaths = [
    "/InventoryDetail",
    "/Containers",
    "/ContactUs",
    "/Selltous",
    "/ShoppingCart",
    "/PaymentCart",
    "/VerifyAccount",
    "/ForgotPassOtp",
    "Login",
    "/Signin",
    "/ForgotPassword",
    "/ResetPassword",
    "/Careers",
    "/Aboutus",
    "/wishlist",
    "/success",
    "/cancel",
    "/Profile",
    "/Appraisals",
    "/RentAContainer",
    "/ClimateControlledContainer",
    "/Gallery",
    "/ModularManufacturing",
    "/BuyAContainer",
    "/ContainerServices",
    "/FindersProgram",
    "/20ft",
    "/40ft",
    "/SpecializedContracts",
    "/PortableOffices",
    "/SurplusInventory",
    "/PrivacyPolicy",
    "/TermsOfService",
  ]; // Add paths to exclude here
  const shouldRenderAppLoader = !excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  useEffect(() => {
    // simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  return (
    <div>
      {shouldRenderAppLoader && loading ? (
        <div className="spinner">
          <div className="SpinnerIcon">
            <Image src="/assets/images/loading.webp" />
          </div>
        </div>
      ) : (
        <div>{/* Your actual content for InventoryDetail */}</div>
      )}
    </div>
  );
};

export default AppLoader;
