import React, { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { Alert, Spinner } from "react-bootstrap";
import useDocumentTitle from "../../DocumentTitle";
import { toast } from "react-toastify/dist/react-toastify";
import "./Signin.css";
import * as appConstants from "../../constants";

function Signin() {
  useDocumentTitle("PC | Signin");
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    businessName: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const signupPayload = {
    fullName: "",
    businessName: "",
    email: "",
    password: "",
    phoneNumber: "",
  };
  const validateForm = () => {
    signupPayload.fullName = formData.fullName;
    signupPayload.businessName = formData.businessName;
    signupPayload.email = formData.email;
    signupPayload.phoneNumber = formData.phoneNumber;
    signupPayload.password = formData.password;
    let formIsValid = true;
    let errors = {};

    if (!signupPayload.fullName) {
      formIsValid = false;
      errors["fullName"] = "Full Name is required";
    }

    if (!signupPayload.businessName) {
      formIsValid = false;
      errors["businessName"] = "Business Name is required";
    }

    if (!signupPayload.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "phoneNumber Name is required";
    }

    if (!signupPayload.email) {
      formIsValid = false;
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(signupPayload.email)) {
      formIsValid = false;
      errors["email"] = "Email is invalid";
    }
    if (!signupPayload.password) {
      formIsValid = false;
      errors["password"] = "Password is required";
    }
    setError(errors);
    return formIsValid;
  };

  const handleSignUpbtn = async () => {
    const welcomeEmail = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html
      xmlns="http://www.w3.org/1999/xhtml"
      xmlns:o="urn:schemas-microsoft-com:office:office"
    >
      <head>
        <meta charset="UTF-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="x-apple-disable-message-reformatting" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="telephone=no" name="format-detection" />
        <title>Welcome Template</title>
        <style type="text/css">
          .rollover:hover .rollover-first {
            max-height: 0px !important;
            display: none !important;
          }
          .rollover:hover .rollover-second {
            max-height: none !important;
            display: inline-block !important;
          }
          .rollover div {
            font-size: 0px;
          }
          u ~ div img + div > div {
            display: none;
          }
          #outlook a {
            padding: 0;
          }
          span.MsoHyperlink,
          span.MsoHyperlinkFollowed {
            color: inherit;
            mso-style-priority: 99;
          }
          a.es-button {
            mso-style-priority: 100 !important;
            text-decoration: none !important;
          }
          a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
          }
          .es-desk-hidden {
            display: none;
            float: left;
            overflow: hidden;
            width: 0;
            max-height: 0;
            line-height: 0;
            mso-hide: all;
          }
          .es-header-body a:hover {
            color: #666666 !important;
          }
          .es-content-body a:hover {
            color: #5c68e2 !important;
          }
          .es-footer-body a:hover {
            color: #333333 !important;
          }
          .es-infoblock a:hover {
            color: #cccccc !important;
          }
          .es-button-border:hover > a.es-button {
            color: #ffffff !important;
          }
          @media only screen and (max-width: 600px) {
            .es-m-p0r {
              padding-right: 0px !important;
            }
            *[class="gmail-fix"] {
              display: none !important;
            }
            p,
            a {
              line-height: 150% !important;
            }
            h1,
            h1 a {
              line-height: 120% !important;
            }
            h2,
            h2 a {
              line-height: 120% !important;
            }
            h3,
            h3 a {
              line-height: 120% !important;
            }
            h4,
            h4 a {
              line-height: 120% !important;
            }
            h5,
            h5 a {
              line-height: 120% !important;
            }
            h6,
            h6 a {
              line-height: 120% !important;
            }
            h1 {
              font-size: 36px !important;
              text-align: left;
            }
            h2 {
              font-size: 26px !important;
              text-align: left;
            }
            h3 {
              font-size: 20px !important;
              text-align: left;
            }
            h4 {
              font-size: 24px !important;
              text-align: left;
            }
            h5 {
              font-size: 20px !important;
              text-align: left;
            }
            h6 {
              font-size: 16px !important;
              text-align: left;
            }
            .es-header-body h1 a,
            .es-content-body h1 a,
            .es-footer-body h1 a {
              font-size: 36px !important;
            }
            .es-header-body h2 a,
            .es-content-body h2 a,
            .es-footer-body h2 a {
              font-size: 26px !important;
            }
            .es-header-body h3 a,
            .es-content-body h3 a,
            .es-footer-body h3 a {
              font-size: 20px !important;
            }
            .es-header-body h4 a,
            .es-content-body h4 a,
            .es-footer-body h4 a {
              font-size: 24px !important;
            }
            .es-header-body h5 a,
            .es-content-body h5 a,
            .es-footer-body h5 a {
              font-size: 20px !important;
            }
            .es-header-body h6 a,
            .es-content-body h6 a,
            .es-footer-body h6 a {
              font-size: 16px !important;
            }
            .es-menu td a {
              font-size: 12px !important;
            }
            .es-header-body p,
            .es-header-body a {
              font-size: 14px !important;
            }
            .es-content-body p,
            .es-content-body a {
              font-size: 14px !important;
            }
            .es-footer-body p,
            .es-footer-body a {
              font-size: 14px !important;
            }
            .es-infoblock p,
            .es-infoblock a {
              font-size: 12px !important;
            }
            .es-m-txt-c,
            .es-m-txt-c h1,
            .es-m-txt-c h2,
            .es-m-txt-c h3,
            .es-m-txt-c h4,
            .es-m-txt-c h5,
            .es-m-txt-c h6 {
              text-align: center !important;
            }
            .es-m-txt-r,
            .es-m-txt-r h1,
            .es-m-txt-r h2,
            .es-m-txt-r h3,
            .es-m-txt-r h4,
            .es-m-txt-r h5,
            .es-m-txt-r h6 {
              text-align: right !important;
            }
            .es-m-txt-j,
            .es-m-txt-j h1,
            .es-m-txt-j h2,
            .es-m-txt-j h3,
            .es-m-txt-j h4,
            .es-m-txt-j h5,
            .es-m-txt-j h6 {
              text-align: justify !important;
            }
            .es-m-txt-l,
            .es-m-txt-l h1,
            .es-m-txt-l h2,
            .es-m-txt-l h3,
            .es-m-txt-l h4,
            .es-m-txt-l h5,
            .es-m-txt-l h6 {
              text-align: left !important;
            }
            .es-m-txt-r img,
            .es-m-txt-c img,
            .es-m-txt-l img {
              display: inline !important;
            }
            .es-m-txt-r .rollover:hover .rollover-second,
            .es-m-txt-c .rollover:hover .rollover-second,
            .es-m-txt-l .rollover:hover .rollover-second {
              display: inline !important;
            }
            .es-m-txt-r .rollover div,
            .es-m-txt-c .rollover div,
            .es-m-txt-l .rollover div {
              line-height: 0 !important;
              font-size: 0 !important;
            }
            .es-spacer {
              display: inline-table;
            }
            a.es-button,
            button.es-button {
              font-size: 20px !important;
            }
            a.es-button,
            button.es-button {
              display: inline-block !important;
            }
            .es-button-border {
              display: inline-block !important;
            }
            .es-m-fw,
            .es-m-fw.es-fw,
            .es-m-fw .es-button {
              display: block !important;
            }
            .es-m-il,
            .es-m-il .es-button,
            .es-social,
            .es-social td,
            .es-menu {
              display: inline-block !important;
            }
            .es-adaptive table,
            .es-left,
            .es-right {
              width: 100% !important;
            }
            .es-content table,
            .es-header table,
            .es-footer table,
            .es-content,
            .es-footer,
            .es-header {
              width: 100% !important;
              max-width: 600px !important;
            }
            .adapt-img {
              width: 100% !important;
              height: auto !important;
            }
            .es-mobile-hidden,
            .es-hidden {
              display: none !important;
            }
            .es-desk-hidden {
              width: auto !important;
              overflow: visible !important;
              float: none !important;
              max-height: inherit !important;
              line-height: inherit !important;
            }
            tr.es-desk-hidden {
              display: table-row !important;
            }
            table.es-desk-hidden {
              display: table !important;
            }
            td.es-desk-menu-hidden {
              display: table-cell !important;
            }
            .es-menu td {
              width: 1% !important;
            }
            table.es-table-not-adapt,
            .esd-block-html table {
              width: auto !important;
            }
            .es-social td {
              padding-bottom: 10px;
            }
            .h-auto {
              height: auto !important;
            }
          }
        </style>
      </head>
      <body style="width: 100%; height: 100%; padding: 0; margin: 0">
        <div class="es-wrapper-color" style="background-color: #fafafa">
          <table
            class="es-wrapper"
            width="100%"
            cellspacing="0"
            cellpadding="0"
            style="
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              border-collapse: collapse;
              border-spacing: 0px;
              padding: 0;
              margin: 0;
              width: 100%;
              height: 100%;
              background-repeat: repeat;
              background-position: center top;
              background-color: #fafafa;
            "
          >
            <tr>
              <td valign="top" style="padding: 0; margin: 0">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-header"
                  align="center"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                    width: 100%;
                    table-layout: fixed !important;
                    background-color: transparent;
                    background-repeat: repeat;
                    background-position: center top;
                  "
                >
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          width: 600px;
                        "
                      >
                        <tr>
                          <td
                            align="left"
                            style="
                              margin: 0;
                              padding-top: 10px;
                              padding-right: 20px;
                              padding-bottom: 10px;
                              padding-left: 20px;
                            "
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  class="es-m-p0r"
                                  valign="top"
                                  align="center"
                                  style="padding: 0; margin: 0; width: 560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-bottom: 20px;
                                          font-size: 0px;
                                        "
                                      >
                                        <img
                                          src="https://szphly.stripocdn.email/content/guids/CABINET_4770742b523ade62dab5866a22101b932c675d4275b0fbdacdc50a76866fd4f8/images/newtmlogo.png"
                                          alt="Logo"
                                          style="
                                            display: block;
                                            font-size: 12px;
                                            border: 0;
                                            outline: none;
                                            text-decoration: none;
                                          "
                                          width="330"
                                          title="Logo"
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content"
                  align="center"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                    width: 100%;
                    table-layout: fixed !important;
                  "
                >
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          width: 600px;
                        "
                      >
                        <tr>
                          <td
                            align="left"
                            style="
                              padding: 0;
                              margin: 0;
                              padding-right: 20px;
                              padding-left: 20px;
                              padding-top: 15px;
                            "
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding: 0; margin: 0; width: 560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        class="es-m-txt-c"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-top: 15px;
                                          padding-bottom: 15px;
                                        "
                                      >
                                        <h1
                                          style="
                                            margin: 0;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            mso-line-height-rule: exactly;
                                            letter-spacing: 0;
                                            font-size: 46px;
                                            font-style: normal;
                                            font-weight: bold;
                                            line-height: 55px;
                                            color: #333333;
                                          "
                                        >
                                          Thanks for joining us!
                                        </h1>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-top: 10px;
                                          padding-bottom: 10px;
                                        "
                                      >
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 24px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 16px;
                                          "
                                        >
                                          Hello, ${formData.fullName} Thanks for
                                          joining us!
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="esdev-adapt-off"
                            align="left"
                            style="padding: 20px; margin: 0"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              class="esdev-mso-table"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                width: 560px;
                              "
                            >
                              <tr>
                                <td
                                  class="esdev-mso-td"
                                  valign="top"
                                  style="padding: 0; margin: 0"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="es-left"
                                    align="left"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                      float: left;
                                    "
                                  >
                                    <tr class="es-mobile-hidden">
                                      <td
                                        align="left"
                                        style="padding: 0; margin: 0; width: 146px"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          role="presentation"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          "
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              height="40"
                                              style="padding: 0; margin: 0"
                                            ></td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  class="esdev-mso-td"
                                  valign="top"
                                  style="padding: 0; margin: 0"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="es-left"
                                    align="left"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                      float: left;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="left"
                                        style="padding: 0; margin: 0; width: 121px"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          bgcolor="#e8eafb"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: separate;
                                            border-spacing: 0px;
                                            background-color: #e8eafb;
                                            border-radius: 10px 0 0 10px;
                                          "
                                          role="presentation"
                                        >
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  letter-spacing: 0;
                                                  color: #333333;
                                                  font-size: 14px;
                                                "
                                              >
                                                Login:
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  letter-spacing: 0;
                                                  color: #333333;
                                                  font-size: 14px;
                                                "
                                              >
                                                Password:
                                              </p>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  class="esdev-mso-td"
                                  valign="top"
                                  style="padding: 0; margin: 0"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="es-left"
                                    align="left"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                      float: left;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="left"
                                        style="padding: 0; margin: 0; width: 155px"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          bgcolor="#e8eafb"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: separate;
                                            border-spacing: 0px;
                                            background-color: #e8eafb;
                                            border-radius: 0 10px 10px 0;
                                          "
                                          role="presentation"
                                        >
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                                padding-left: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  letter-spacing: 0;
                                                  color: #333333;
                                                  font-size: 14px;
                                                "
                                              >
                                                <strong>${formData.email}</strong>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 10px;
                                                padding-left: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  letter-spacing: 0;
                                                  color: #333333;
                                                  font-size: 14px;
                                                "
                                              >
                                                <strong
                                                  >${formData.password}</strong
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  class="esdev-mso-td"
                                  valign="top"
                                  style="padding: 0; margin: 0"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="es-right"
                                    align="right"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                      float: right;
                                    "
                                  >
                                    <tr class="es-mobile-hidden">
                                      <td
                                        align="left"
                                        style="padding: 0; margin: 0; width: 138px"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          role="presentation"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          "
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              height="40"
                                              style="padding: 0; margin: 0"
                                            ></td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            style="
                              padding: 0;
                              margin: 0;
                              padding-right: 20px;
                              padding-bottom: 10px;
                              padding-left: 20px;
                            "
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding: 0; margin: 0; width: 560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: separate;
                                      border-spacing: 0px;
                                      border-radius: 5px;
                                    "
                                    role="presentation"
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-top: 10px;
                                          padding-bottom: 10px;
                                        "
                                      >
                                        <span
                                          class="es-button-border"
                                          style="
                                            border-style: solid;
                                            border-color: #2cb543;
                                            background: #0b1145;
                                            border-width: 0px;
                                            display: inline-block;
                                            border-radius: 6px;
                                            width: auto;
                                          "
                                          ><a
                                            href="https://procontainers.com"
                                            class="es-button"
                                            target="_blank"
                                            style="
                                              mso-style-priority: 100 !important;
                                              text-decoration: none !important;
                                              mso-line-height-rule: exactly;
                                              color: #ffffff;
                                              font-size: 20px;
                                              padding: 10px 30px 10px 30px;
                                              display: inline-block;
                                              background: #0b1145;
                                              border-radius: 6px;
                                              font-family: arial, 'helvetica neue',
                                                helvetica, sans-serif;
                                              font-weight: normal;
                                              font-style: normal;
                                              line-height: 24px !important;
                                              width: auto;
                                              text-align: center;
                                              letter-spacing: 0;
                                              mso-padding-alt: 0;
                                              mso-border-alt: 10px solid #0b1145;
                                              border-left-width: 30px;
                                              border-right-width: 30px;
                                            "
                                            >SHOP NOW</a
                                          ></span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-bottom: 10px;
                                          padding-top: 20px;
                                        "
                                      >
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 17px !important;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 14px;
                                          "
                                        >
                                          Got a question? Email us at
                                          <a
                                            href="mailto:support@procontainers.com"
                                            style="
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #5c68e2;
                                              font-size: 14px;
                                            "
                                            >support@procontainers.com</a
                                          >
                                          or give us a call at&nbsp;<a
                                            target="_blank"
                                            style="
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #5c68e2;
                                              font-size: 14px;
                                              line-height: 21px;
                                            "
                                            href=""
                                            >830-282-0000</a
                                          >.
                                        </p>
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 21px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 14px;
                                          "
                                        >
                                          <br />Thanks,
                                        </p>
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 21px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 14px;
                                          "
                                        >
                                          ProContainers
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 0; margin: 0">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          class="es-menu"
                                          role="presentation"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          "
                                        >
                                          <tr class="links">
                                            <td
                                              align="center"
                                              valign="top"
                                              width="25.00%"
                                              style="
                                                margin: 0;
                                                border: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-right: 5px;
                                                padding-left: 5px;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://procontainers.com/Aboutus"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  display: block;
                                                  color: #999999;
                                                  font-size: 14px;
                                                "
                                                >About us</a
                                              >
                                            </td>
                                            <td
                                              align="center"
                                              valign="top"
                                              width="25.00%"
                                              style="
                                                margin: 0;
                                                border: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-right: 5px;
                                                padding-left: 5px;
                                                border-left: 1px solid #cccccc;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://procontainers.com/PrivacyPolicy"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  display: block;
                                                  color: #999999;
                                                  font-size: 14px;
                                                "
                                                >Privacy Policy</a
                                              >
                                            </td>
                                            <td
                                              align="center"
                                              valign="top"
                                              width="25.00%"
                                              style="
                                                margin: 0;
                                                border: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-right: 5px;
                                                padding-left: 5px;
                                                border-left: 1px solid #cccccc;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://procontainers.com/TermsOfService"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  display: block;
                                                  color: #999999;
                                                  font-size: 14px;
                                                "
                                                >Terms of Services</a
                                              >
                                            </td>
                                            <td
                                              align="center"
                                              valign="top"
                                              width="25.00%"
                                              style="
                                                margin: 0;
                                                border: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-right: 5px;
                                                padding-left: 5px;
                                                border-left: 1px solid #cccccc;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://procontainers.com"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  display: block;
                                                  color: #999999;
                                                  font-size: 14px;
                                                "
                                                >Shop</a
                                              >
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-footer"
                  align="center"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                    width: 100%;
                    table-layout: fixed !important;
                    background-color: transparent;
                    background-repeat: repeat;
                    background-position: center top;
                  "
                >
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-footer-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          width: 640px;
                        "
                      >
                        <tr>
                          <td
                            align="left"
                            style="
                              margin: 0;
                              padding-right: 20px;
                              padding-left: 20px;
                              padding-bottom: 20px;
                              padding-top: 20px;
                            "
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  align="left"
                                  style="padding: 0; margin: 0; width: 600px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-top: 15px;
                                          padding-bottom: 15px;
                                          font-size: 0;
                                        "
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          class="es-table-not-adapt es-social"
                                          role="presentation"
                                          style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          "
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              valign="top"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-right: 40px;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://www.facebook.com/procontainersco"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #333333;
                                                  font-size: 12px;
                                                "
                                                ><img
                                                  title="Facebook"
                                                  src="https://szphly.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                  alt="Fb"
                                                  width="32"
                                                  style="
                                                    display: block;
                                                    font-size: 14px;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                  "
                                              /></a>
                                            </td>
                                            <td
                                              align="center"
                                              valign="top"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-right: 40px;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://www.youtube.com/@ProContainers?themeRefresh=1"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #333333;
                                                  font-size: 12px;
                                                "
                                                ><img
                                                  title="Youtube"
                                                  src="https://szphly.stripocdn.email/content/assets/img/social-icons/logo-black/youtube-logo-black.png"
                                                  alt="Yt"
                                                  width="32"
                                                  style="
                                                    display: block;
                                                    font-size: 14px;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                  "
                                              /></a>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          padding: 0;
                                          margin: 0;
                                          padding-bottom: 5px;
                                        "
                                      >
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 18px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 12px;
                                          "
                                        >
                                          © 2022 ProContainers. All rights reserved.
                                          Trademarks and brands belong to their
                                          respective owners.
                                        </p>
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 18px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 12px;
                                          "
                                        >
                                        112 US HWY 87 comfort 78913
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            style="
                              padding: 0;
                              margin: 0;
                              padding-right: 20px;
                              padding-left: 20px;
                              padding-top: 20px;
                            "
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  align="left"
                                  style="padding: 0; margin: 0; width: 560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="padding: 0; margin: 0"
                                      >
                                        <p
                                          style="
                                            margin: 0;
                                            mso-line-height-rule: exactly;
                                            font-family: arial, 'helvetica neue',
                                              helvetica, sans-serif;
                                            line-height: 18px;
                                            letter-spacing: 0;
                                            color: #333333;
                                            font-size: 12px;
                                          "
                                        >
                                          This email was sent by ProContainers If
                                          you don't want to receive this type of
                                          email in the future, please
                                          <a
                                            href="https://procontainers.com/Profile"
                                            style="
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #3d85c6;
                                              font-size: 12px;
                                            "
                                            >unsubscribe</a
                                          >
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content"
                  align="center"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                    width: 100%;
                    table-layout: fixed !important;
                  "
                >
                  <tr>
                    <td
                      class="es-info-area"
                      align="center"
                      style="padding: 0; margin: 0"
                    >
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          width: 600px;
                        "
                        bgcolor="#FFFFFF"
                      >
                        <tr>
                          <td align="left" style="padding: 20px; margin: 0">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                              "
                            >
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding: 0; margin: 0; width: 560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      border-spacing: 0px;
                                    "
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="padding: 0; margin: 0; display: none"
                                      ></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
    `;
    const UserRegesteredEmail = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html
      xmlns="http://www.w3.org/1999/xhtml"
      xmlns:o="urn:schemas-microsoft-com:office:office"
    >
      <head>
        <meta charset="UTF-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="x-apple-disable-message-reformatting" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="telephone=no" name="format-detection" />
        <title></title>
      </head>
      <body>
        <div class="es-wrapper-color">
          <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td class="esd-email-paddings" valign="top">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    class="es-header"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td
                          class="esd-stripe esd-synchronizable-module"
                          align="center"
                        >
                          <table
                            bgcolor="#ffffff"
                            class="es-header-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                                  align="left"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="560"
                                          class="es-m-p0r esd-container-frame"
                                          valign="top"
                                          align="center"
                                        >
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="center"
                                                  class="esd-block-image es-p20b"
                                                  style="font-size: 0px"
                                                >
                                                  <a target="_blank"
                                                    ><img
                                                      src="https://szphly.stripocdn.email/content/guids/CABINET_35879128602597269d75a0b1316701f20cec7301b71fe13c55765527cc028e58/images/newtmlogo.png"
                                                      alt="Logo"
                                                      style="
                                                        display: block;
                                                        font-size: 12px;
                                                      "
                                                      width="330"
                                                      title="Logo"
                                                  /></a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    class="es-content"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td class="esd-stripe" align="center">
                          <table
                            bgcolor="#ffffff"
                            class="es-content-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="esd-structure es-p15t es-p20r es-p20l"
                                  align="left"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="560"
                                          class="esd-container-frame"
                                          align="center"
                                          valign="top"
                                        >
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="center"
                                                  class="esd-block-text es-p15t es-p15b es-m-txt-c"
                                                  esd-links-underline="none"
                                                >
                                                  <h2>
                                                    New User Registered (Internal
                                                    Only)
                                                  </h2>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  align="center"
                                                  class="esd-block-text es-p10t es-p10b"
                                                >
                                                  <p style="font-size: 16px"></p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="esd-structure es-p20t es-p20r es-p20l"
                                  align="left"
                                >
                                  <table cellpadding="0" cellspacing="0">
                                    <tbody>
                                      <tr>
                                        <td
                                          width="560"
                                          class="esd-container-frame"
                                          align="left"
                                        >
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            role="presentation"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="left"
                                                  class="esd-block-text"
                                                >
                                                  <p>
                                                    <strong style="color: #666666"
                                                      >USER DETAILS</strong
                                                    >
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="esd-structure es-p20t es-p20r es-p20l"
                                  align="left"
                                >
                                  <table
                                    width="560"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="esdev-mso-table"
                                  >
                                    <tbody>
                                      <tr>
                                        <td class="esdev-mso-td" valign="top">
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            align="left"
                                            class="es-left"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  width="295"
                                                  class="esd-container-frame"
                                                  align="left"
                                                >
                                                  <table
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    width="100%"
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="left"
                                                          class="esd-block-text"
                                                        >
                                                          <p>
                                                            <strong
                                                              >Full Name :</strong
                                                            >
                                                          </p>
                                                          <p>
                                                            <strong>Email :</strong>
                                                          </p>
                                                          <p>
                                                            <strong
                                                              >Business Name
                                                              :</strong
                                                            >
                                                          </p>
                                                          <p>
                                                            <strong
                                                              >Phone Number
                                                              :</strong
                                                            >
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td width="20"></td>
                                        <td class="esdev-mso-td" valign="top">
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            class="es-right"
                                            align="right"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  width="245"
                                                  class="esd-container-frame"
                                                  align="left"
                                                >
                                                  <table
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    width="100%"
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="right"
                                                          class="esd-block-text"
                                                        >
                                                          <p>
                                                            ${formData.fullName}
                                                          </p>
                                                          <p>${formData.email}</p>
                                                          <p>
                                                            ${formData.businessName}
                                                          </p>
                                                          <p>
                                                            ${formData.phoneNumber}
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
    
                              <tr>
                                <td
                                  class="esd-structure es-p10b es-p20r es-p20l"
                                  align="left"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="560"
                                          class="esd-container-frame"
                                          align="center"
                                          valign="top"
                                        >
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                            style="
                                              border-radius: 5px;
                                              border-collapse: separate;
                                            "
                                          ></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
    
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    class="es-content esd-footer-popover"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td
                          class="esd-stripe es-info-area esd-synchronizable-module"
                          align="center"
                        >
                          <table
                            class="es-content-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            width="600"
                            style="background-color: transparent"
                            bgcolor="rgba(0, 0, 0, 0)"
                          >
                            <tbody>
                              <tr>
                                <td class="esd-structure es-p20" align="left">
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="560"
                                          class="esd-container-frame"
                                          align="center"
                                          valign="top"
                                        >
                                          <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  align="center"
                                                  class="esd-empty-container"
                                                  style="display: none"
                                                ></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
    </html>
    `;
    if (validateForm() == true) {
      try {
        setLoading(true);
        const res = await axiosClient.post("Register", signupPayload);

        if (res && res.isAlreadyRegistor) {
          toast.error("Account already exists.");
          setLoading(false);
        } else {
          if (res && res.email != "") {
            localStorage.setItem(
              "login",
              JSON.stringify({
                login: true,
                token: res.accessToken,
                user: res,
              })
            );
            setLoading(false);
            setShowAlert(true);

            toast.success(
              "Thank you for joining our community! Prepare for liftoff in 3...2...1... Redirecting!"
            );

            // debugger;
            fetch(appConstants.LogicAppEmailUrlOffer, {
              method: "POST",
              body: JSON.stringify({
                body: UserRegesteredEmail,
                subject: "New User Registered (Internal Only)",
                to: appConstants.emailToAddress,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((response) => {})
              .catch((error) => {
                console.error(error);
              });

            fetch(appConstants.LogicAppEmailUrlOffer, {
              method: "POST",
              body: JSON.stringify({
                body: welcomeEmail,
                subject: "Welcome to ProContainers",
                to: formData.email,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((response) => {})
              .catch((error) => {
                console.error(error);
              });

            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            setLoading(false);
            setShowAlert(false);
          }
        }
      } catch (error) {
        setLoading(false);
        setShowAlert(false);
      }
    } else {
      setLoading(false);
      setShowAlert(false);
      return true;
    }
  };

  return (
    <>
      <div className="main-login-wrap">
        <Row className="m-0 bg-light-gray">
          <Col lg={6} className="bg-white">
            <div className="logoInfo">
              <div className="login-logo">
                <Image src="/assets/images/logo.webp" />
              </div>
              <p>
                "Commit your work to the Lord, and your plans will be
                established." - Proverbs 16:3
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="login-box">
              {showAlert == true && (
                <Alert
                  variant="success"
                  onClose={() => setShowAlert(null)}
                  dismissible
                >
                  <Alert.Heading>Register Successful !</Alert.Heading>
                  <p>You have successfully Register .</p>
                </Alert>
              )}
              {showAlert == false && (
                <Alert
                  variant="danger"
                  onClose={() => setShowAlert(null)}
                  dismissible
                >
                  <Alert.Heading>
                    Please review all the details you provided and try
                    registering again.
                  </Alert.Heading>
                </Alert>
              )}
              <h3>Create New Account</h3>
              <div className="login-input-width">
                <div className="form-input">
                  <label>Enter Your Full Name</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name={"fullName"}
                  />
                  {showAlert == false && (
                    <span className="errorMSg">
                      {error.fullName && <p>{error.fullName}</p>}
                    </span>
                  )}
                </div>
                <div className="form-input">
                  <label>Enter Your Business Name</label>
                  <input
                    type="text"
                    name={"businessName"}
                    onChange={handleChange}
                  />
                  {showAlert == false && (
                    <span className="errorMSg">
                      {error.businessName && <p>{error.businessName}</p>}
                    </span>
                  )}
                </div>
                <div className="form-input">
                  <label>Enter Your Email</label>
                  <input type="email" onChange={handleChange} name={"email"} />
                  {showAlert == false && (
                    <span className="errorMSg">
                      {error.email && <p>{error.email}</p>}
                    </span>
                  )}
                </div>

                <div className="form-input">
                  <label>Enter Your Phone Number</label>
                  <input
                    type="phoneNumber"
                    onChange={handleChange}
                    name={"phoneNumber"}
                  />
                  {showAlert == false && (
                    <span className="errorMSg">
                      {error.phoneNumber && <p>{error.phoneNumber}</p>}
                    </span>
                  )}
                </div>

                <div className="form-input">
                  <label>Enter Your Password</label>
                  <div className="input-icon">
                    <input
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      value={formData.password}
                      name={"password"}
                    />
                    <a onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <Icon icon="ant-design:eye-filled" />
                      ) : (
                        <Icon icon="mdi:eye-off" />
                      )}
                    </a>
                  </div>
                  {showAlert == false && (
                    <span className="errorMSg">
                      {error.password && <p>{error.password}</p>}
                    </span>
                  )}
                </div>
                {loading ? (
                  <button
                    className="btn btn-blue d-block w-100 btn-sign"
                    disabled
                    style={{ position: "relative" }}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        top: "calc(50% - 12px)",
                        left: "calc(50% - 12px)",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    className="btn btn-blue d-block w-100 btn-sign"
                    type="button"
                    onClick={handleSignUpbtn}
                  >
                    Sign Up
                  </button>
                )}
              </div>
              <h6 className="signup-link">
                Already have an account?<Link to="/login"> Sign In</Link>{" "}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Signin;
