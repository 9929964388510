import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import "./ResetPassword.css";

function ResetPassword() {
  useDocumentTitle("PC | Reset Password");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="main-login-wrap">
      <Row className="m-0 bg-light-gray">
        <Col lg={6} className="bg-white">
          <div className="logoInfo">
            <div className="login-logo">
              <Image src="/assets/images/logo.webp" />
            </div>
            <p>
              "Unlock boundless possibilities with just one click. Explore our
              container wonderland and find the perfect box to hold your
              dreams."
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="login-box">
            <h3 className="mb-2">Reset Password</h3>
            <p className="max-100">Please Enter your new password</p>
            <div className="login-input-width">
              <div className="form-input">
                <label>Enter Your New Password</label>
                <div className="input-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name={"password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <a onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <Icon icon="ant-design:eye-filled" />
                    ) : (
                      <Icon icon="mdi:eye-off" />
                    )}
                  </a>
                </div>
              </div>
              <div className="form-input">
                <label>Confirm Your Password</label>
                <div className="input-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name={"password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <a onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <Icon icon="ant-design:eye-filled" />
                    ) : (
                      <Icon icon="mdi:eye-off" />
                    )}
                  </a>
                </div>
              </div>
              <Link to="/" className="btn btn-blue d-block w-100 btn-sign mt-5">
                {" "}
                Submit
              </Link>
            </div>
            <h6 className="signup-link">
              Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ResetPassword;
