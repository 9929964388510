import React from "react";
import { Container } from "react-bootstrap";
import ContactForm from "./ContactForm/ContactForm";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import useDocumentTitle from "../../DocumentTitle";
import "./ContactUs.css";

function ContactUs() {
  useDocumentTitle("PC | Contact Us");
  return (
    <>
      <div>
        <Header />
        <div className="d-margin pt-2">
          <Container>
            <div className="contactBanner contactBanner2">
              <h3>Contact Us</h3>
            </div>
            <ContactForm />
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
