import { Icon } from "@iconify/react";
import React from "react";
import "./HowitWorks.css";

function HowitWorks() {
  return (
    <>
      <div className="rentprocess p-0">
        {/* <h4>How to sell your equipment to us</h4> */}
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
        <ul className="rentprocess-box">
          <li>
            <p>
              <strong>Contact Us: </strong>
              <br></br>
              Reach out to our team via phone, email, or our online contact
              form. Provide us with details about the containers you wish to
              sell.
            </p>
          </li>
          <li>
            <p>
              <strong>Evaluation </strong>
              <br></br>
              We'll assess the information provided and offer you a fair and
              competitive price.
            </p>
          </li>
          <li>
            <p>
              <strong>Pickup and Payment: </strong>
              <br></br>
              Once you accept our offer, we'll arrange for pickup of your
              containers at your convenience. After pickup, we'll promptly
              process your payment.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default HowitWorks;
