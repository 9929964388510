import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import useDocumentTitle from "../../DocumentTitle";
import "./Carrier.css";
import { Helmet } from "react-helmet";

function Carrier() {
  useDocumentTitle("PC | Careers");
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://kingsurplus.com/InventoryDetail/Careers`}
        />
      </Helmet>
      <Header />
      <div className="d-margin">
        <Container className="pt-5">
          <div className="carrierInfo">
            <h3>Pro Container Careers</h3>
            <p>
              At Pro Container, we're proud to be a family-owned and operated
              business since 1970. We understand the importance of family values
              and bringing that into the workplace. That's why we treat our
              employees like family, and we believe that it's the foundation of
              our success.
            </p>
            <p>
              Working with us means you're not just a number, but an integral
              part of our family. You'll work in an environment that fosters
              collaboration, creativity, and a strong sense of community. We
              offer a supportive and friendly workplace where employees feel
              valued and respected.
            </p>
            <p>
              We're always on the lookout for talented individuals who share our
              passion for excellence and want to grow with us. Whether you're
              just starting out in your career or looking to take the next step,
              we have opportunities that will challenge and reward you.
            </p>
            <p>
              As a family-owned and operated business, we take pride in our work
              and in providing the best possible service to our customers. We're
              committed to growing into becoming the worldwide leader in Surplus
              and Asset Management Solutions, and we need your help to achieve
              that goal.
            </p>
            <p>
              f you're looking for a fulfilling career with a company that
              values its employees and is committed to success, then
              ProContainers is the place for you. Apply now and become part of
              our family!
            </p>
          </div>
          <div className="FeaturedJobsForm">
            <h3>Job Application Form</h3>
            <h5>Personal Information</h5>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Full Name"
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Phone Number"
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email Address"
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Department Applied For</Form.Label>
                  <Form.Select>
                    <option>Warehousing </option>
                    <option>Office</option>
                    <option>Accounting</option>
                    <option>Operations</option>
                    <option>Executive</option>
                    <option>Logistics</option>
                    <option>Sales</option>
                    <option>Customer Service</option>
                    <option>Client Services</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Salary Requirements</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Salary Requirements"
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Education"
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Highest Degree Earned</Form.Label>
                  <Form.Select>
                    <option>Diploma or Similar </option>
                    <option>Some College </option>
                    <option>College Degree</option>
                    <option>Masters Degree</option>
                    <option>Doctorates Degree</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>School</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your School" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Major</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Major" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Graduation Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Your Graduation Date"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Address" />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <h5>Employment History</h5>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Current or Most Recent Employer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed Start Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed End Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Leaving</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Previous Employer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed Start Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed End Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Leaving</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Previous Employer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed Start Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed End Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Leaving</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Previous Employer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed Start Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed End Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Leaving</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Previous Employer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed Start Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Employed End Date</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Leaving</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Phone Number</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Email Address</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Relationship</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Phone Number</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Email Address</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Relationship</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Phone Number</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Email Address</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Relationship</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <hr />
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Name</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Phone Number</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Email Address</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Reference Relationship</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <h5>Additional Information</h5>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="d-block">
                    Are you legally authorized to work in the United States?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="UsWork"
                    inline
                    id="USworkYes"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="UsWork"
                    inline
                    id="USworkNo"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="d-block">
                    Have you ever been convicted of a felony?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="felony"
                    inline
                    id="felonyYes"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="felony"
                    inline
                    id="felonyNo"
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Please upload your resume and cover letter:
                  </Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="By submitting this application, I certify that the information provided is true and complete to the best of my knowledge. I understand that any false statements may disqualify me from employment, or result in my termination if I am already employed."
                    name="Terms"
                    id="terms"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button className="btn btn-blue" variant="">
              Submit Form
            </Button>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Carrier;
