import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Col, Button, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify/dist/react-toastify";
import "./addtocart.css";
import { useDispatch } from "react-redux";
import { getTotals, handleAddCart } from "../Slices/CartSlice";

function AddToCartOrCheckOut(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginForm = useRef(null);
  const [showAlert, setShowAlert] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  var payload = {};

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (props.show != null) {
      setShow(true);
    }
  }, [props.show]);

  const handleLoginbtn = async () => {
    setLoading(true);
    const form = loginForm.current;
    payload.email = form["email"].value;
    payload.password = form["password"].value;
    localStorage.removeItem("cart_items");
    try {
      const res = await axiosClient.post("Login", payload);
      if (res && res.email != "") {
        localStorage.removeItem("login");
        localStorage.setItem(
          "login",
          JSON.stringify({
            login: true,
            token: res.accessToken,
            user: res,
          })
        );
        setShowAlert(true);
        setLoading(false);
        toast.success("You have successfully logged in.");

        dispatch(handleAddCart(props.item));
        dispatch(getTotals());
        handleClose();
        if (props.isBuyNow) {
          navigate("/ShoppingCart");
        }
        // else {
        //   window.location.reload();
        // }
      } else {
        setShowAlert(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setShowAlert(false);
      toast.error("Please check your email and password and try again.");
      setLoading(false);
    }
  };
  return (
    <>
      {props.show != null && (
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Container Added Successfully to cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <Row className="m-0 bg-light-gray">
                <Col lg={12}>
                  <div className="login-box">
                    <button
                      className="btn btn-blue d-block w-100 mb-5 btn-sign"
                      onClick={handleClose}
                    >
                      Continue Shopping
                    </button>
                    <button
                      className="btn btn-blue d-block w-100 btn-sign"
                      onClick={() => navigate("/ShoppingCart")}
                    >
                      Checkout
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default AddToCartOrCheckOut;
