import React from "react";
import { Image } from "react-bootstrap";
import './Appraisalsinfo.css';

function Appraisalsinfo() {
  return (
    <section className='sellwithusSection pb100 pt-4'>
      <div className='d-flex g-5 DesktopInnerText'>
        <div className='sellwithusInfo RecyclingInfo ps-0'>
          <h4 className="mb-lg-4">Maximize your asset's worth, minimize your workload: Let us appraise, buy, and remove.</h4>
          <p className='mb-3'>As a family-owned company, we understand the importance of accurate and reliable assessments when it comes to knowing the value of your assets. Our approach is flexible, allowing you to choose only the sections that are relevant to your needs. If you simply want to determine the value of your assets, we can provide that information for you. However, if you are looking to sell the asset and remove it from your property, we can also assist with that process. We can handle all aspects of the transaction.</p>
          <p className='mb-3'>We specialize in appraising all types of industrial assets, from large machines to smaller tools and parts. Our team provides a thorough appraisal that takes into account a variety of factors to determine the true value of your assets. Our fees vary based on the Final Asset Value (FAV) of the item being appraised. With years of experience in the field and the latest technology and industry knowledge, we provide comprehensive and insightful reports.</p>
          <p className='mb-3'>One of the key benefits of working with us is the potential financial gain that can be achieved. Before scrapping or dismantling an asset, it's important to know its full aftermarket value. Our appraisals can help you discover the true worth of your equipment, which is substantially more than what you will be offered through traditional scrap value.</p>
          <p className='mb-3'>But our services don't stop at appraisals. Once you've received your assessment, we offer a one-stop-shop for the sales of your surplus industrial equipment or fixed assets. We are willing to buy the asset from you, and our team has the resources to handle all aspects of the transaction, from negotiating the best price to handling the logistics of removal and transportation.</p>
          <p>Whether you're looking to sell individual assets or entire facilities, we are committed to helping you get the best return on your investment. We pride ourselves on offering personalized service to all of our clients, and we aim to provide a seamless and stress-free experience every step of the way.</p>
        </div>
        <div className='sellwithusImg RecyclingImgs'>
          <Image src="/assets/images/Appraisals.png" />
        </div>
      </div>
      <div className="MobileTextInfo">
        <h4>Maximize your asset's worth, minimize your workload: Let us appraise, buy, and remove.</h4>
        <div className="MobileDesInfo">
          <p >As a family-owned company, we understand the importance of accurate and reliable assessments when it comes to knowing the value of your assets. Our approach is flexible, allowing you to choose only the sections that are relevant to your needs. If you simply want to determine the value of your assets, we can provide that information for you. However, if you are looking to sell the asset and remove it from your property, we can also assist with that process. We can handle all aspects of the transaction.</p>
          <Image src="/assets/images/Appraisals.png" />
          <p>We specialize in appraising all types of industrial assets, from large machines to smaller tools and parts. Our team provides a thorough appraisal that takes into account a variety of factors to determine the true value of your assets. Our fees vary based on the Final Asset Value (FAV) of the item being appraised. With years of experience in the field and the latest technology and industry knowledge, we provide comprehensive and insightful reports.</p>
          <p>One of the key benefits of working with us is the potential financial gain that can be achieved. Before scrapping or dismantling an asset, it's important to know its full aftermarket value. Our appraisals can help you discover the true worth of your equipment, which is substantially more than what you will be offered through traditional scrap value.</p>
          <p>But our services don't stop at appraisals. Once you've received your assessment, we offer a one-stop-shop for the sales of your surplus industrial equipment or fixed assets. We are willing to buy the asset from you, and our team has the resources to handle all aspects of the transaction, from negotiating the best price to handling the logistics of removal and transportation.</p>
          <p>Whether you're looking to sell individual assets or entire facilities, we are committed to helping you get the best return on your investment. We pride ourselves on offering personalized service to all of our clients, and we aim to provide a seamless and stress-free experience every step of the way.</p>
        </div>
      </div>
      <div className="clearfix"></div>
    </section>
  );
}

export default Appraisalsinfo;
