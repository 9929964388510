import React from "react";
import { Icon } from "@iconify/react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../../public/assets/images/logo.webp";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4}>
            <Link to="/" className="f-logo">
              <Image src={Logo} />
            </Link>
            <p>ProContainers.com - Making Space Work for You!</p>
          </Col>
          <Col lg={4}>
            <div className="footer-link-wrap">
              <div className="f-link-50">
                <h6>Quick Links</h6>
                <ul>
                  <li>
                    <a href="/Containers">Container Inventory</a>
                  </li>
                  <li>
                    <a href="/Aboutus">About Us</a>
                  </li>
                  <li>
                    <a href="/RecyclingServices">Container Services</a>
                  </li>
                  <li>
                    <a href="/Selltous">Sell to us</a>
                  </li>
                  <li>
                    <a href="/ContactUs">Contact us</a>
                  </li>
                  {/* <li>
                    <a href="/Gallery">Gallery</a>
                  </li> */}
                </ul>
              </div>
              <div className="f-link-50">
                <h6>Support Links</h6>
                <ul>
                  <li>
                    <a href="/TermsOfService">Terms of services</a>
                  </li>
                  <li>
                    <a href="/PrivacyPolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/Careers">Careers</a>
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/Signin">Signup</a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <h6>Get in touch</h6>
            <ul className="contact-links">
              <li>
                <a href="mailto:support@procontainers.com">
                  <Icon icon="fluent:mail-16-filled" />{" "}
                  support@procontainers.com
                </a>
              </li>
              <li>
                {/* TODO : Update telephone number when avaialble */}
                <a href="tel:830-282-0000">
                  <span>
                    <Icon icon="fluent:call-16-filled" />
                  </span>{" "}
                  830-282-0000
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/King+Surplus/@29.9864958,-98.9042339,15z/data=!4m6!3m5!1s0x865bf3259adf637d:0x79383dc7818f09a7!8m2!3d29.9864958!4d-98.9042339!16s%2Fg%2F11s8hzpm_2"
                >
                  <Icon icon="carbon:location-filled" /> OFFICE: 110 US HWY 87
                  COMFORT TEXAS 78013
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/King+Surplus/@29.9864958,-98.9042339,15z/data=!4m6!3m5!1s0x865bf3259adf637d:0x79383dc7818f09a7!8m2!3d29.9864958!4d-98.9042339!16s%2Fg%2F11s8hzpm_2"
                >
                  <Icon icon="carbon:location-filled" /> TRUCK ENTRANCE: 112 US
                  HWY 87 COMFORT TEXAS 78013
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <div className="CopyrightWrap">
          <p className="copyright">
            © 2022 ProContainers. All rights reserved. Trademarks and brands
            belong to their respective owners.
          </p>
          <ul className="social-ul">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/procontainersco"
              >
                <Icon icon="bxl:facebook-circle" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@ProContainers" // TODO : Update youtube channel link
              >
                <Icon icon="ant-design:youtube-filled" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/pro-container/"
              >
                <Icon icon="bxl:linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
