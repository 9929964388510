import React from "react";
import CountUp from "react-countup";
import { Container } from "react-bootstrap";
import "./ProjectCounter.css";

function ProjectCounter() {
  return (
    <div className="ProjectCounter">
      <Container>
        <div className="ProjectCounterTitle">
          <h4>Welcome to ProContainers</h4>
          <p>
            We're not just reshaping the secondary shipping container market,
            we're revolutionizing it with our commitment to honesty, trust, and
            transparency. Unlike others, we only deal in our own inventory.
            There are no middlemen, no time-wasting phone calls, no waiting
            periods. What you see is what we own and what's readily available to
            you. <br></br> <br></br> We strive to provide an exceptional,
            refreshing experience, with inventory that's accessible right at
            your fingertips. More than just transactions, we believe in building
            authentic, lasting relationships with our customers. At
            ProContainers, there are no hidden tricks, agendas, or lengthy
            waiting periods. <br></br> Experience a straightforward checkout
            process and dependable delivery when you choose us. Explore our
            inventory today and witness firsthand how we're redefining container
            trading with an uncomplicated approach, rooted in simplicity and
            integrity.
          </p>
        </div>
        {/* <ul>
                <li>
                    <h3><CountUp end={800} />+</h3>
                    <h6>Categories</h6>
                </li>
                <li>
                    <h3><CountUp end={50} />+</h3>
                    <h6>Years In Business</h6>
                </li>
                <li>
                    <h3><CountUp end={10000} />+</h3>
                    <h6>Satisfied Customer</h6>
                </li>
                <li>
                    <h3><CountUp end={20} />+</h3>
                    <h6>Years In E-Commerce</h6>
                </li>
            </ul> */}
      </Container>
    </div>
  );
}

export default ProjectCounter;
