import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ContactForm.css";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";
import { template } from "lodash";

function ContactForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyname: "",
    cellphoneNumber: "",
    city: "",
    postalcode: "",
    subject: "",
    replybyphone: false,
    replybyemail: false,
    noreply: false,
    message: "",
  });

  const UserContactEmailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <meta charset="UTF-8" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta name="x-apple-disable-message-reformatting" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta content="telephone=no" name="format-detection" />
      <title></title>
    </head>
    <body>
      <div class="es-wrapper-color">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td class="esd-email-paddings" valign="top">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-header"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="esd-stripe esd-synchronizable-module"
                        align="center"
                      >
                        <table
                          bgcolor="#ffffff"
                          class="es-header-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="es-m-p0r esd-container-frame"
                                        valign="top"
                                        align="center"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image es-p20b"
                                                style="font-size: 0px"
                                              >
                                                <a target="_blank"
                                                  ><img
                                                    src="https://szphly.stripocdn.email/content/guids/CABINET_35879128602597269d75a0b1316701f20cec7301b71fe13c55765527cc028e58/images/newtmlogo.png"
                                                    alt="Logo"
                                                    style="
                                                      display: block;
                                                      font-size: 12px;
                                                    "
                                                    width="330"
                                                    title="Logo"
                                                /></a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td class="esd-stripe" align="center">
                        <table
                          bgcolor="#ffffff"
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p15t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p15t es-p15b es-m-txt-c"
                                                esd-links-underline="none"
                                              >
                                                <h2>
                                                  New Contact Us Submission
                                                  (Internal Only)
                                                </h2>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p10t es-p10b"
                                              >
                                                <p style="font-size: 16px"></p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="left"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          role="presentation"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text"
                                              >
                                                <p>
                                                  <strong style="color: #666666"
                                                    >USER DETAILS</strong
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  width="560"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="esdev-mso-table"
                                >
                                  <tbody>
                                    <tr>
                                      <td class="esdev-mso-td" valign="top">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          class="es-left"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                width="295"
                                                class="esd-container-frame"
                                                align="left"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  role="presentation"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        class="esd-block-text"
                                                      >
                                                        <p>
                                                          <strong
                                                            >First Name:
                                                            ${formData.firstName}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Last Name:
                                                            ${formData.lastName}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Email:
                                                            ${formData.email}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Company Name:
                                                            ${formData.companyname}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Phone Number:
                                                            ${formData.cellphoneNumber}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >City:
                                                            ${formData.city}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Postal Code:
                                                            ${formData.postalcode}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Subject:
                                                            ${formData.subject}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Reply By Phone:
                                                            ${formData.replybyphone}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Reply By Email:
                                                            ${formData.replybyemail}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >No Reply:
                                                            ${formData.noreply}</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Message:
                                                            ${formData.message}</strong
                                                          >
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td width="20"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
  
                            <tr>
                              <td
                                class="esd-structure es-p10b es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          style="
                                            border-radius: 5px;
                                            border-collapse: separate;
                                          "
                                        ></table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
  
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content esd-footer-popover"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="esd-stripe es-info-area esd-synchronizable-module"
                        align="center"
                      >
                        <table
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                          style="background-color: transparent"
                          bgcolor="rgba(0, 0, 0, 0)"
                        >
                          <tbody>
                            <tr>
                              <td class="esd-structure es-p20" align="left">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-empty-container"
                                                style="display: none"
                                              ></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
  </html>
  `;

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if email and cellphoneNumber are empty
    if (!formData.email || !formData.cellphoneNumber) {
      // Show toast message if either email or cellphoneNumber is empty
      toast.error("Email and Phone Number are required!");
      return;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: UserContactEmailTemplate,
        subject: "Contact Us Form Submission (Internal Only)",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      companyname: "",
      cellphoneNumber: "",
      city: "",
      postalcode: "",
      subject: "",
      replybyphone: false,
      replybyemail: false,
      noreply: false,
      message: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  return (
    <div className="contact-form">
      <h4>Send us a Message</h4>
      <p>
        One of our friendly customer care representatives will be in touch with
        you shortly.
      </p>
      <Row>
        <Col lg={9}>
          <form className="row" onSubmit={handleSubmit}>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData({ ...formData, firstName: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormData({ ...formData, lastName: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  value={formData.companyname}
                  onChange={(e) => {
                    setFormData({ ...formData, companyname: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  value={formData.cellphoneNumber}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      cellphoneNumber: e.target.value,
                    });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-input">
                <label htmlFor="postalcode">Postal/Zip Code</label>
                <input
                  type="text"
                  value={formData.postalcode}
                  onChange={(e) => {
                    setFormData({ ...formData, postalcode: e.target.value });
                    // console.log(formData);
                  }}
                  required
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-input d-flex align-items-center flex-wrap">
                <label className="mb-2 me-3" htmlFor="reply">
                  {" "}
                  <strong>How Can We Reply :</strong>{" "}
                </label>
                <div className="d-flex flex-wrap mb-2">
                  <label className="custom-checkbox" htmlFor="email">
                    <input
                      type="checkbox"
                      id="email"
                      name="replybyemail"
                      checked={formData.replybyemail}
                      onChange={handleCheckboxChange}
                    />
                    <span>
                      <Icon icon="charm:tick" />
                    </span>
                    <p>email</p>
                  </label>
                  <label className="custom-checkbox" htmlFor="phone">
                    <input
                      type="checkbox"
                      id="phone"
                      name="replybyphone"
                      checked={formData.reoklybyphone}
                      onChange={handleCheckboxChange}
                    />
                    <span>
                      <Icon icon="charm:tick" />
                    </span>
                    <p>Phone</p>
                  </label>
                  <label className="custom-checkbox" htmlFor="required">
                    <input
                      type="checkbox"
                      id="required"
                      name="noreply"
                      checked={formData.noreply}
                      onChange={handleCheckboxChange}
                    />
                    <span>
                      <Icon icon="charm:tick" />
                    </span>
                    <p>No Reply Required</p>
                  </label>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-input">
                <label htmlFor="subject">Subject</label>
                <select
                  value={formData.subject}
                  onChange={(e) => {
                    setFormData({ ...formData, subject: e.target.value });
                    // console.log(formData);
                  }}
                >
                  <option></option>
                  <option>Buy A Container</option>
                  <option>Rent A Container</option>
                  <option>Climate Controlled Containers</option>
                  <option>Portable Offices</option>
                  <option>Sell us your container</option>
                  {/* <option>Modular Manufacturing</option> */}
                  <option>Customer Service</option>
                  <option>HR / Employment Request</option>
                  <option>Accounting </option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-input">
                <label htmlFor="postalcode">Your Comment or Question</label>
                <textarea
                  rows="4"
                  value={formData.message}
                  onChange={(e) => {
                    setFormData({ ...formData, message: e.target.value });
                    // console.log(formData);
                  }}
                  required
                ></textarea>
              </div>
            </Col>
            <Col lg={12}>
              <Link
                onClick={handleSubmit}
                className="btn btn-blue btn-arrow mt-4"
                type="submit"
              >
                Submit <Icon className="ms-2" icon="akar-icons:arrow-right" />
              </Link>
            </Col>
          </form>
        </Col>
        <Col lg={3}>
          <ul className="contact-info">
            <li>
              <a href="mailto:sales@procontainers.com">
                <span>
                  <Icon icon="fluent:mail-20-filled" />
                </span>{" "}
                sales@procontainers.com
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href=" https://www.facebook.com/procontainersco"
              >
                <span>
                  <Icon icon="bxl:facebook-circle" />
                </span>{" "}
                procontainercompany
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/King+Surplus/@29.9864958,-98.9042339,15z/data=!4m6!3m5!1s0x865bf3259adf637d:0x79383dc7818f09a7!8m2!3d29.9864958!4d-98.9042339!16s%2Fg%2F11s8hzpm_2"
              >
                <Icon icon="carbon:location-filled" /> OFFICE: 110 US HWY 87
                COMFORT TEXAS 78013
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/King+Surplus/@29.9864958,-98.9042339,15z/data=!4m6!3m5!1s0x865bf3259adf637d:0x79383dc7818f09a7!8m2!3d29.9864958!4d-98.9042339!16s%2Fg%2F11s8hzpm_2"
              >
                <Icon icon="carbon:location-filled" /> TRUCK ENTRANCE: 112 US
                HWY 87 COMFORT TEXAS 78013
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/King+Surplus/@29.3613014,-100.9130569,17z/data=!3m1!4b1!4m6!3m5!1s0x86f5eb41b0888ad5:0x517d7501410f352a!8m2!3d29.3613014!4d-100.9104766!16s%2Fg%2F11dyzkq39t"
              >
                <span>
                  <Icon icon="carbon:location-filled" />
                </span>{" "}
                100 Ave T #4564, Del Rio, TX 78840, United States
              </a>
            </li> */}
            {/* TODO: Update telephone number if available */}
            <li>
              <a href="tel:830-282-0000">
                <span>
                  <Icon icon="fluent:call-16-filled" />
                </span>{" "}
                830-282-0000
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default ContactForm;
