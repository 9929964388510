import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";
import "./RecyclingServicesInfoForm.css";

function RecyclingServicesInfoForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const handleMultipleImages = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const [formData, setFormData] = useState({
    FullName: "",
    CompanyName: "",
    WorkNumber: "",
    CellPhoneNumber: "",
    EmailAddress: "",
    MailingAddress: "",
    MaterialTypes: "",
    MaterialVolume: "",
    MaterialStorage: "",
    RecyclingRequirements: "",
    OnSiteCollection: "",
    Shipping: "",
    CollectionTime: "",
    AccessRestrictions: "",
    Containers: "",
    CollectionSchedule: "",
    AdditionalInformation: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataEntries = Object.entries(formData);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "Recycling Services",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        setFormData({
          FullName: "",
          CompanyName: "",
          WorkNumber: "",
          CellPhoneNumber: "",
          EmailAddress: "",
          MailingAddress: "",
          MaterialTypes: "",
          MaterialVolume: "",
          MaterialStorage: "",
          RecyclingRequirements: "",
          OnSiteCollection: "",
          Shipping: "",
          CollectionTime: "",
          AccessRestrictions: "",
          Containers: "",
          CollectionSchedule: "",
          AdditionalInformation: "",
        });
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  };

  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>Reduce, Reuse, Recycle </h3>
          <p>
            Join our circle – close the loop and reduce waste with the circular
            economy
          </p>
        </div>
        <Link to="#" onClick={handleShow} className="btn btn-white btn-icon ">
          Join our circle{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="RecyclingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Container Services Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sellToUsPopup">
            <h6>
              Take the first step towards a better tomorrow – fill out the form
              now.
            </h6>
            <Form className="row">
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="FullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    name={"FullName"}
                    onChange={handleChange}
                    value={formData.FullName}
                    type="text"
                    placeholder="Enter Your Full Name"
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="CompanyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    name={"CompanyName"}
                    onChange={handleChange}
                    value={formData.CompanyName}
                    type="text"
                    placeholder="Enter Your Company Name"
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="WorkNumber">
                  <Form.Label>Work Number</Form.Label>
                  <Form.Control
                    name={"WorkNumber"}
                    onChange={handleChange}
                    value={formData.WorkNumber}
                    type="text"
                    placeholder="Enter Your Work Number"
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="CellPhoneNumber">
                  <Form.Label>Cell Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name={"CellPhoneNumber"}
                    onChange={handleChange}
                    value={formData.CellPhoneNumber}
                    placeholder="Enter Your Cell Phone Number"
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="EmailAddress">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    name={"EmailAddress"}
                    onChange={handleChange}
                    value={formData.EmailAddress}
                    placeholder="Enter Your Email Address"
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="MailingAddress">
                  <Form.Label>Mailing Address</Form.Label>
                  <Form.Control
                    type="text"
                    name={"MailingAddress"}
                    onChange={handleChange}
                    value={formData.MailingAddress}
                    placeholder="Enter Your Mailing Address"
                  />
                </Form.Group>
              </Col>
              <hr />
              <h6>Recycling Needs </h6>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="MaterialTypes">
                  <Form.Label>
                    What type(s) of materials do you need recycled?
                  </Form.Label>
                  <Form.Control
                    name={"MaterialTypes"}
                    onChange={handleChange}
                    value={formData.MaterialTypes}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="OnSiteCollection">
                  <Form.Label>
                    What is the approximate volume of materials you need
                    recycled per week/month?
                  </Form.Label>
                  <Form.Control
                    name={"OnSiteCollection"}
                    onChange={handleChange}
                    value={formData.OnSiteCollection}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="MaterialStorage">
                  <Form.Label>
                    How are the materials currently being stored?
                  </Form.Label>
                  <Form.Control
                    name={"MaterialStorage"}
                    onChange={handleChange}
                    value={formData.MaterialStorage}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="RecyclingRequirements">
                  <Form.Label>
                    Do you have any specific requirements for the recycling
                    process?
                  </Form.Label>
                  <Form.Control
                    name={"RecyclingRequirements"}
                    onChange={handleChange}
                    value={formData.RecyclingRequirements}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <hr />
              <h6>Collection & Logistics</h6>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="MaterialVolume">
                  <Form.Label>
                    Do you require on-site collection of materials?
                  </Form.Label>
                  <Form.Control
                    name={"MaterialVolume"}
                    onChange={handleChange}
                    value={formData.MaterialVolume}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="Shipping">
                  <Form.Label>Can you ship this material?</Form.Label>
                  <Form.Control
                    name={"Shipping"}
                    onChange={handleChange}
                    value={formData.Shipping}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="CollectionTime">
                  <Form.Label>
                    If so, what is the best time for collections to occur?
                  </Form.Label>
                  <Form.Control
                    name={"CollectionTime"}
                    onChange={handleChange}
                    value={formData.CollectionTime}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="AccessRestrictions">
                  <Form.Label>
                    Are there any access restrictions at your location (e.g.
                    parking, loading dock, elevator)?
                  </Form.Label>
                  <Form.Control
                    name={"AccessRestrictions"}
                    onChange={handleChange}
                    value={formData.AccessRestrictions}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="Containers">
                  <Form.Label>
                    Do you require any specific containers for storing the
                    materials?
                  </Form.Label>
                  <Form.Control
                    name={"Containers"}
                    onChange={handleChange}
                    value={formData.Containers}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="CollectionSchedule">
                  <Form.Label>
                    Will you require regular scheduled collections, or on an
                    as-needed basis?
                  </Form.Label>
                  <Form.Control
                    name={"CollectionSchedule"}
                    onChange={handleChange}
                    value={formData.CollectionSchedule}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <hr />
              <h6>Additional Information</h6>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="AdditionalInformation">
                  <Form.Label>
                    Is there any other information you would like to provide
                    about your recycling needs or goals?
                  </Form.Label>
                  <Form.Control
                    name={"AdditionalInformation"}
                    onChange={handleChange}
                    value={formData.AdditionalInformation}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit Form
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RecyclingServicesInfoForm;
