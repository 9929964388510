import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify";
import * as appConstants from "../../../constants";
import "./RentForm.css";

function RentForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const handleMultipleImages = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    workNumber: "",
    cellPhoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    assetType: "",
    manufacturer: "",
    model: "",
    serialNumber: "",
    yearOfManufacture: "",
    currentCondition: "New",
    assetDescription: "",
    originalOwner: "Yes",
    lengthOfOwnership: "",
    lastMaintenanceDate: "",
    askingPrice: "",
    reasonForSelling: "",
    additionalComments: "",
    canShip: "Yes",
    requirePickup: "Yes",
    packagingAndShippingDetails: "",
    preferredCarrier: "",
    specialPackagingRequirements: "",
    estimatedShippingWeight: "",
    estimatedShippingLength: "",
    estimatedShippingWidth: "",
    estimatedShippingHeight: "",
    shippingInstructions: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const UserContactEmailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <meta charset="UTF-8" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta name="x-apple-disable-message-reformatting" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta content="telephone=no" name="format-detection" />
      <title></title>
    </head>
    <body>
      <div class="es-wrapper-color">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td class="esd-email-paddings" valign="top">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-header"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="esd-stripe esd-synchronizable-module"
                        align="center"
                      >
                        <table
                          bgcolor="#ffffff"
                          class="es-header-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="es-m-p0r esd-container-frame"
                                        valign="top"
                                        align="center"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image es-p20b"
                                                style="font-size: 0px"
                                              >
                                                <a target="_blank"
                                                  ><img
                                                    src="https://szphly.stripocdn.email/content/guids/CABINET_35879128602597269d75a0b1316701f20cec7301b71fe13c55765527cc028e58/images/newtmlogo.png"
                                                    alt="Logo"
                                                    style="
                                                      display: block;
                                                      font-size: 12px;
                                                    "
                                                    width="330"
                                                    title="Logo"
                                                /></a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td class="esd-stripe" align="center">
                        <table
                          bgcolor="#ffffff"
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p15t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p15t es-p15b es-m-txt-c"
                                                esd-links-underline="none"
                                              >
                                                <h2>
                                                  New Contact Us Submission
                                                  (Internal Only)
                                                </h2>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p10t es-p10b"
                                              >
                                                <p style="font-size: 16px"></p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="left"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          role="presentation"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text"
                                              >
                                                <p>
                                                  <strong style="color: #666666"
                                                    >USER DETAILS</strong
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  width="560"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="esdev-mso-table"
                                >
                                  <tbody>
                                    <tr>
                                      <td class="esdev-mso-td" valign="top">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          class="es-left"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                width="295"
                                                class="esd-container-frame"
                                                align="left"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  role="presentation"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="left"
                                                        class="esd-block-text"
                                                      >
                                                        <p>
                                                          <strong
                                                            >Full Name :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Company Name
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Work Number :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Cell Phone Number
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong>Email :</strong>
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Address :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong>City :</strong>
                                                        </p>
                                                        <p>
                                                          <strong>State :</strong>
                                                        </p>
                                                        <p>
                                                          <strong>Zip :</strong>
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Country :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Asset Type :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Manufacturer
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong>Model :</strong>
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Serial Number
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Year Of Manufacture
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Current
                                                            Condition:</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Asset Description
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Can Ship :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Require Pickup
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Packaging And
                                                            Shipping Details
                                                            :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Special Packaging
                                                            Requirements :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Estimated Shipping
                                                            Weight :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Estimated Shipping
                                                            Length :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Estimated Shipping
                                                            Width :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Estimated Shipping
                                                            Height :</strong
                                                          >
                                                        </p>
                                                        <p>
                                                          <strong
                                                            >Shipping Instructions
                                                            :</strong
                                                          >
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td width="20"></td>
                                      <td class="esdev-mso-td" valign="top">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          class="es-right"
                                          align="right"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                width="245"
                                                class="esd-container-frame"
                                                align="left"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  role="presentation"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="right"
                                                        class="esd-block-text"
                                                      >
                                                        <p>
                                                          ${formData.fullName}
                                                        </p>
                                                        <p>
                                                          ${formData.companyName}
                                                        </p>
                                                        <p>
                                                          ${formData.workNumber}
                                                        </p>
                                                        <p>
                                                          ${formData.cellPhoneNumber}
                                                        </p>
                                                        <p>${formData.email}</p>
                                                        <p>${formData.address}</p>
                                                        <p>${formData.city}</p>
                                                        <p>${formData.state}</p>
                                                        <p>${formData.zip}</p>
                                                        <p>${formData.country}</p>
                                                        <p>
                                                          ${formData.assetType}
                                                        </p>
                                                        <p>
                                                          ${formData.manufacturer}
                                                        </p>
                                                        <p>${formData.model}</p>
                                                        <p>
                                                          ${formData.serialNumber}
                                                        </p>
                                                        <p>
                                                          ${formData.yearOfManufacture}
                                                        </p>
                                                        <p>
                                                          ${formData.currentCondition}
                                                        </p>
                                                        <p>
                                                          ${formData.assetDescription}
                                                        </p>
                                                        <p>${formData.canShip}</p>
                                                        <p>
                                                          ${formData.requirePickup}
                                                        </p>
                                                        <p>
                                                          ${formData.packagingAndShippingDetails}
                                                        </p>
                                                        <p>
                                                          ${formData.specialPackagingRequirements}
                                                        </p>
                                                        <p>
                                                          ${formData.estimatedShippingWeight}
                                                        </p>
                                                        <p>
                                                          ${formData.estimatedShippingLength}
                                                        </p>
                                                        <p>
                                                          ${formData.estimatedShippingWidth}
                                                        </p>
                                                        <p>
                                                        ${formData.estimatedShippingHeight}
                                                      </p>
                                                        <p>
                                                          ${formData.shippingInstructions}
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
  
                            <tr>
                              <td
                                class="esd-structure es-p10b es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                          style="
                                            border-radius: 5px;
                                            border-collapse: separate;
                                          "
                                        ></table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
  
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content esd-footer-popover"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="esd-stripe es-info-area esd-synchronizable-module"
                        align="center"
                      >
                        <table
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                          style="background-color: transparent"
                          bgcolor="rgba(0, 0, 0, 0)"
                        >
                          <tbody>
                            <tr>
                              <td class="esd-structure es-p20" align="left">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-empty-container"
                                                style="display: none"
                                              ></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
  </html>
  `;

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: UserContactEmailTemplate,
        subject: "Pro Container Sell Your Assets Form (Internal Only)",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        setFormData({
          fullName: "",
          companyName: "",
          workNumber: "",
          cellPhoneNumber: "",
          email: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          assetType: "",
          manufacturer: "",
          model: "",
          serialNumber: "",
          yearOfManufacture: "",
          currentCondition: "New",
          assetDescription: "",
          originalOwner: "Yes",
          lengthOfOwnership: "",
          lastMaintenanceDate: "",
          askingPrice: "",
          reasonForSelling: "",
          additionalComments: "",
          canShip: "Yes",
          requirePickup: "Yes",
          packagingAndShippingDetails: "",
          preferredCarrier: "",
          specialPackagingRequirements: "",
          estimatedShippingWeight: "",
          estimatedShippingLength: "",
          estimatedShippingWidth: "",
          estimatedShippingHeight: "",
          shippingInstructions: "",
        });
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  };

  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>
            Selling with Trust <sup>TM</sup> 
          </h3>
          <p>
            Our commitment to transparency and fair deals ensures a hassle-free
            experience for you.
          </p>
        </div>
        <Link to="#" onClick={handleShow} className="btn btn-white btn-icon ">
          Sell Now{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="RecyclingModal"
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Sell Your Assets Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sellToUsPopup">
              <small>
                Please complete the form below to provide us with the necessary
                information about the container you wish to sell. Once we
                receive your submission, our team of experts will review it and
                contact you to discuss the next steps.
              </small>
              <h6 className="mt-3">Seller's Information</h6>
              <div className="row">
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="fullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name={"fullName"}
                      onChange={handleChange}
                      value={formData.fullName}
                      type="text"
                      placeholder="Enter Your Full Name"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Company Name (if applicable)</Form.Label>
                    <Form.Control
                      name={"companyName"}
                      onChange={handleChange}
                      value={formData.companyName}
                      type="text"
                      placeholder="Enter Your Company Name (if applicable)"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="workNumber">
                    <Form.Label>Work Number</Form.Label>
                    <Form.Control
                      name={"workNumber"}
                      onChange={handleChange}
                      value={formData.workNumber}
                      type="number"
                      placeholder="Enter Your Work Number"
                    />
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="cellPhoneNumber">
                    <Form.Label>Cell Phone Number</Form.Label>
                    <Form.Control
                      name={"cellPhoneNumber"}
                      onChange={handleChange}
                      value={formData.cellPhoneNumber}
                      type="number"
                      placeholder="Enter Your Cell Phone Number"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name={"email"}
                      onChange={handleChange}
                      value={formData.email}
                      type="email"
                      placeholder="Enter Your Email"
                    />
                  </Form.Group>
                </Col>
                <Col lg={8}>
                  <Form.Group className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      name={"address"}
                      onChange={handleChange}
                      value={formData.address}
                      type="text"
                      placeholder="Enter Your Address"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      name={"city"}
                      onChange={handleChange}
                      value={formData.city}
                      type="text"
                      placeholder="Enter Your City"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      name={"state"}
                      onChange={handleChange}
                      value={formData.state}
                      type="text"
                      placeholder="Enter Your State"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="zip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      name={"zip"}
                      onChange={handleChange}
                      value={formData.zip}
                      type="text"
                      placeholder="Enter Your Zip"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      name={"country"}
                      onChange={handleChange}
                      value={formData.country}
                      type="text"
                      placeholder="Enter Your Country"
                    />
                  </Form.Group>
                </Col>
                <hr />
                <h6>Container Information</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="assetType">
                    <Form.Label>Container Type etc.)</Form.Label>
                    <Form.Control
                      name={"assetType"}
                      onChange={handleChange}
                      value={formData.assetType}
                      type="text"
                      placeholder="Container Type"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="manufacturer">
                    <Form.Label>Manufacturer</Form.Label>
                    <Form.Control
                      name={"manufacturer"}
                      onChange={handleChange}
                      value={formData.manufacturer}
                      type="text"
                      placeholder="Manufacturer"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="model">
                    <Form.Label>Model</Form.Label>
                    <Form.Control
                      name={"model"}
                      onChange={handleChange}
                      value={formData.model}
                      type="text"
                      placeholder="Model"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="serialNumber">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control
                      name={"serialNumber"}
                      onChange={handleChange}
                      value={formData.serialNumber}
                      type="text"
                      placeholder="Serial Number"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="yearOfManufacture">
                    <Form.Label>Year of Manufacture</Form.Label>
                    <Form.Control
                      name={"yearOfManufacture"}
                      onChange={handleChange}
                      value={formData.yearOfManufacture}
                      type="text"
                      placeholder="Year of Manufacture"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="currentCondition">
                    <Form.Label>Current Condition</Form.Label>
                    <Form.Select
                      name={"currentCondition"}
                      onChange={handleChange}
                      value={formData.currentCondition}
                    >
                      <option>New</option>
                      <option>Used</option>
                      <option>Refurbished</option>
                      <option>Parts Unit</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="assetDescription">
                    <Form.Label>
                      Detailed Description of the Container (please include any
                      special features, upgrades, or modifications)
                    </Form.Label>
                    <Form.Control
                      name={"assetDescription"}
                      onChange={handleChange}
                      value={formData.assetDescription}
                      type="text"
                      placeholder="Detailed Description of the Asset (please include any special features, upgrades, or modifications)"
                    />
                  </Form.Group>
                </Col>
                <hr />
                {/* <h6>Ownership and Usage Information</h6>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="originalOwner">
                    <Form.Label>Original Owner?</Form.Label>
                    <Form.Select
                      name={"originalOwner"}
                      onChange={handleChange}
                      value={formData.originalOwner}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="lengthOfOwnership">
                    <Form.Label>Length of Ownership</Form.Label>
                    <Form.Control
                      name={"lengthOfOwnership"}
                      onChange={handleChange}
                      value={formData.lengthOfOwnership}
                      type="text"
                      placeholder="Length of Ownership"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="lastMaintenanceDate">
                    <Form.Label>Last Maintenance/Service Date</Form.Label>
                    <Form.Control
                      name={"lastMaintenanceDate"}
                      onChange={handleChange}
                      value={formData.lastMaintenanceDate}
                      type="date"
                      placeholder="Last Maintenance/Service Date"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="askingPrice">
                    <Form.Label>Asking Price</Form.Label>
                    <Form.Control
                      name={"askingPrice"}
                      onChange={handleChange}
                      value={formData.askingPrice}
                      type="text"
                      placeholder="Asking Price"
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="reasonForSelling">
                    <Form.Label>Reason for Selling</Form.Label>
                    <Form.Control
                      name={"reasonForSelling"}
                      onChange={handleChange}
                      value={formData.reasonForSelling}
                      type="text"
                      placeholder="Reason for Selling"
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="additionalComments">
                    <Form.Label>
                      Please provide any additional information or comments
                      regarding your industrial machinery asset
                    </Form.Label>
                    <Form.Control
                      name={"additionalComments"}
                      onChange={handleChange}
                      value={formData.additionalComments}
                      type="text"
                      placeholder="Please provide any additional information or comments regarding your industrial machinery asset"
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-0" controlId="file">
                    <Form.Label>
                      Photos of Asset (optional){" "}
                      <small className="d-block">
                        Please attach clear, high-resolution photographs of your
                        industrial machinery asset, including close-ups of any
                        identifying marks or labels, as well as any relevant
                        documentation (e.g., manuals, maintenance records,
                        etc.).
                      </small>
                    </Form.Label>
                    <Form.Control name={"file"} type="file" multiple />
                    <div className="ModalImagePopup">
                      <Image className="UploadImg" src={images} />
                    </div>
                  </Form.Group>
                </Col> */}
                <hr />
                <h6>Transportation and Shipping Information</h6>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="canShip">
                    <Form.Label>Do you want us to pick this unit up</Form.Label>
                    <Form.Select
                      name={"canShip"}
                      onChange={handleChange}
                      value={formData.canShip}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="requirePickup">
                    <Form.Label>
                      Does your container have surplus assets you wish to sell?
                    </Form.Label>
                    <Form.Select
                      name={"requirePickup"}
                      onChange={handleChange}
                      value={formData.requirePickup}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <small className="d-block mb-3">
                  Please note that if we need to handle the packaging and
                  shipping in-house, a sales price discount will be applied to
                  compensate for the additional costs incurred.
                </small>
                <Col lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="packagingAndShippingDetails"
                  >
                    <Form.Label>
                      Packaging and Shipping Details (if applicable)
                    </Form.Label>
                    <Form.Control
                      name={"packagingAndShippingDetails"}
                      onChange={handleChange}
                      value={formData.packagingAndShippingDetails}
                      type="text"
                      placeholder="Packaging and Shipping Details (if applicable)"
                    />
                  </Form.Group>
                </Col>
                {/* <Col lg={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Preferred Carrier (if any)</Form.Label>
                    <Form.Control
                      name={"preferredCarrier"}
                      onChange={handleChange}
                      value={formData.preferredCarrier}
                      type="text"
                      placeholder="Preferred Carrier (if any)"
                    />
                  </Form.Group>
                </Col> */}
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Special Packaging Requirements (if any)
                    </Form.Label>
                    <Form.Control
                      name={"specialPackagingRequirements"}
                      onChange={handleChange}
                      value={formData.specialPackagingRequirements}
                      type="text"
                      placeholder="Special Packaging Requirements (if any)"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Estimated Shipping Weight <i>(LBS)</i>
                    </Form.Label>
                    <Form.Control
                      name={"estimatedShippingWeight"}
                      onChange={handleChange}
                      value={formData.estimatedShippingWeight}
                      type="text"
                      placeholder="Estimated Shipping Weight"
                    />
                  </Form.Group>
                </Col>
                <Col lg={9}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Estimated Shipping Dimensions (L x W x H) <i>inches</i>
                    </Form.Label>
                    <div className="d-flex gap-3">
                      <Form.Control
                        name={"estimatedShippingLength"}
                        onChange={handleChange}
                        value={formData.estimatedShippingLength}
                        type="text"
                        placeholder="Length "
                      />
                      <Form.Control
                        name={"estimatedShippingWidth"}
                        onChange={handleChange}
                        value={formData.estimatedShippingWidth}
                        type="text"
                        placeholder="Width"
                      />
                      <Form.Control
                        name={"estimatedShippingHeight"}
                        onChange={handleChange}
                        value={formData.estimatedShippingHeight}
                        type="text"
                        placeholder="Hight"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Please provide any additional information or instructions
                      related to the packaging and shipping of your asset
                    </Form.Label>
                    <Form.Control
                      name={"shippingInstructions"}
                      onChange={handleChange}
                      value={formData.shippingInstructions}
                      type="text"
                      placeholder="Please provide any additional information or instructions related to the packaging and shipping of your asset"
                    />
                  </Form.Group>
                </Col>
              </div>
              <small>
                By submitting this form, I hereby confirm that the information
                provided is accurate and complete to the best of my knowledge. I
                also understand that submitting this form does not guarantee a
                sale or specific price for my asset.
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="button" variant="primary" onClick={handleSubmit}>
              Submit Form
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default RentForm;
