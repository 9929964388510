import React, { useState, useEffect } from "react";
import CheckOutPreferencesForm from "./CheckOutPreferencesForm";
import { Modal } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import * as appConstants from "../../constants";
const stripePromise = loadStripe(appConstants.stripePublishedKey);
function CreatePaymentPreference(props) {

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => {
    setShow(true)
  };

  const SetValFromPb = (val) =>{
    props.SetValFromPb(val);
  }

  useEffect(() => {
    if (props.show != null) {
      setShow(true)
    }
  }, [props.show]);

  const setShowStatus = () => {
    props.refreshData();
    setShow(false)
    
};
  return (

    <>
      <form >
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Add Bank Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='scroll70 px-2'>
              <div>
                <Elements stripe={stripePromise}>
                  <CheckOutPreferencesForm  
                    refreshData={setShowStatus}
                    SetValFromPb={SetValFromPb}
                    isFromPB ={props.isFromPB}
                     />
                </Elements>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </form></>
  );
}

export default CreatePaymentPreference;
