import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";
import "./Success.css";

function Success() {
  useDocumentTitle("PC | Payment Success");
  const navigate = useNavigate();
  const handleReturnBack = () => {
    navigate("/Profile");
  };

  return (
    <div className="MainContainer">
      <Link to="/">
        <Image src="/assets/images/logo.webp" />
      </Link>
      <div className="success-container">
        <span>
          <Icon icon="mdi:check-decagram" color="#3b945e" />
        </span>
        <h2 className="success-title">Payment Successful!</h2>
        <p className="success-message">
          Thank you for your purchase. Your payment has been processed
          successfully.
        </p>
        <button className="btn btn-blue" onClick={handleReturnBack}>
          Return Back
        </button>
      </div>
    </div>
  );
}

export default Success;
