import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Col, Button, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify/dist/react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import { useDispatch } from "react-redux";
import { getTotals, handleAddCart } from "../Slices/CartSlice";

function LoginModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginForm = useRef(null);
  const [showAlert, setShowAlert] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  var payload = {};

  const handleClose = () => {
    setShow(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (props.show != null) {
      setShow(true);
    }
  }, [props.show]);

  const handleLoginbtn = async () => {
    setLoading(true);
    const form = loginForm.current;
    payload.email = form["email"].value;
    payload.password = form["password"].value;
    localStorage.removeItem("cart_items");
    try {
      const res = await axiosClient.post("Login", payload);
      if (res && res.email != "") {
        localStorage.removeItem("login");
        localStorage.setItem(
          "login",
          JSON.stringify({
            login: true,
            token: res.accessToken,
            user: res,
          })
        );
        setShowAlert(true);
        setLoading(false);
        toast.success("You have successfully logged in.");
        // console.log(props.item);
        dispatch(handleAddCart(props.item));
        dispatch(getTotals());
        handleClose();
        if (props.isBuyNow === false) {
          // navigate("/ShoppingCart");
          window.location.reload();
        }
        // else {
        //   window.location.reload();
        // }
      } else {
        setShowAlert(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setShowAlert(false);
      toast.error("Please check your email and password and try again.");
      setLoading(false);
    }
  };
  return (
    <>
      {props.show != null && (
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <Row className="m-0 bg-light-gray">
                <Col lg={12}>
                  <div className="login-box">
                    <form ref={loginForm}>
                      {/* <h3>Welcome Back </h3> */}
                      <div className="login-input-width">
                        <div className="form-input">
                          <label>Enter Your Email</label>
                          <input type="email" name={"email"} />
                        </div>
                        <div className="form-input">
                          <label>Enter Your Password</label>
                          <div className="input-icon">
                            <input
                              type={showPassword ? "text" : "password"}
                              name={"password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <a onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <Icon icon="ant-design:eye-filled" />
                              ) : (
                                <Icon icon="mdi:eye-off" />
                              )}
                            </a>
                          </div>
                        </div>
                        <Link
                          className="forgot-password-link"
                          to="/ForgotPassword"
                        >
                          Forgot Password?
                        </Link>
                        {loading ? (
                          <button
                            className="btn btn-blue d-block w-100 btn-sign"
                            disabled
                            style={{ position: "relative" }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{
                                color: "white",
                                top: "calc(50% - 12px)",
                                left: "calc(50% - 12px)",
                              }}
                            />
                          </button>
                        ) : (
                          <button
                            className="btn btn-blue d-block w-100 btn-sign"
                            onClick={handleLoginbtn}
                          >
                            Sign In
                          </button>
                        )}
                      </div>
                    </form>
                    <h6 className="signup-link">
                      Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
                    </h6>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default LoginModal;
