import React from "react";
import { Col, Container, Row, Tab } from "react-bootstrap";
import HowWeWork from "./HowWeWork/HowWeWork";
import OurHistory from "./OurHistory/OurHistory";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Gallery.css";
import useDocumentTitle from "../../DocumentTitle";

function Gallery() {
  useDocumentTitle("PC | Gallery");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <div className="about-banner">
            <h3>Gallery</h3>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              {/* <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <OurHistory />
                  </Tab.Pane>
                </Tab.Content>
              </Col> */}
              <div class="parent">
                <div class="div1">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div2">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div3">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div4">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div5">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div6">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div7">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div8">
                  <img src="/assets/images/h1.webp"></img>{" "}
                </div>
                <div class="div9"> 9</div>
                <div class="div10">10 </div>
                <div class="div11"> 11</div>
                <div class="div12">12 </div>
                <div class="div13">13 </div>
                <div class="div14">14 </div>
                <div class="div15">15 </div>
                <div class="div16"> 16</div>
              </div>
            </Row>
          </Tab.Container>
          {/* <HowWeWork /> */}
          <div className="SlogenText">
            <h3>
              An enterprise built upon Faith, family, relationships, and
              service.
            </h3>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Gallery;
