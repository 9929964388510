import React from "react";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import "./EquipmentServicingInfo.css";

function EquipmentServicingInfo() {
  return (
    <section className="sellwithusSection pt-0 pb100">
      <div className="PageText">
        <h3>
          A distribution relationship or finder's fee could involve an
          individual or a company.
        </h3>
        <Row>
          <Col lg={12}>
            <div className="PageCard">
              <p>
                At ProContainers, we understand that many companies have a
                reliable source for their supply chain, and that it can be
                difficult to replace a customer once they've been acquired.
                That's why we offer a confidential program to help businesses
                monetize their unused or surplus assets, without disrupting
                their existing operations.
              </p>
              <p>
                Unlike most companies, our business is focused on the buy side
                of the distribution network. We specialize in buying and
                managing assets for our clients, with sales as a natural outcome
                of our process. We love all of our customers, but we live for
                our clients.
              </p>
              <p>
                Our program offers a financial incentive to individuals or
                companies who can help us identify valuable opportunities and
                assets. Many of our "finders" have become regular distribution
                relationships, who see us as an additional revenue stream to
                their ongoing activities. Fees paid have ranged from $100 to
                $10,000, depending on the value of the assets discovered.
              </p>
              <p>
                We pride ourselves on our discretion and professionalism, and
                we're committed to building long-term relationships with our
                partners. If you have located fixed assets for us before, or are
                considering doing so in the future, we would love to work with
                you. Contact us today to learn more about our program and how we
                can help you monetize your unused assets.
              </p>
              <Accordion defaultActiveKey="0">
                <Accordion.Item className="mb-0" eventKey="0">
                  <Accordion.Header>
                    Turn your industry insights into cash – if you have
                    knowledge of these items, you could be a profitable finder
                  </Accordion.Header>
                  <Accordion.Body className="pb-0">
                    <ul className="mb-0">
                      <li>
                        Companies with assets left or being left behind from a
                        move
                      </li>
                      <li>Surplus inventory</li>
                      <li>Surplus assets</li>
                      <li>Companies buying new equipment to replace old</li>
                      <li>Companies going out of business</li>
                      <li>Rumors of companies downsizing or selling.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <hr />
                <Accordion.Item className="mb-0" eventKey="1">
                  <Accordion.Header>
                    Partner with us and profit – we offer finder's fees to a
                    diverse range of companies and individuals.
                  </Accordion.Header>
                  <Accordion.Body className="pb-0">
                    <p>
                      At ProContainers, our primary goal is to establish
                      long-term relationships with the entities and individuals
                      in our network. We strive to provide them with financial
                      incentives for identifying valuable opportunities and
                      assets, and we're committed to ensuring their continued
                      success.
                    </p>
                    <p>
                      Even if you don't fit into our existing categories, we
                      still want to hear from you. We recognize that the global
                      economy can be complex, and we welcome all leads and
                      information that could be of interest to us.
                    </p>
                    <p>
                      Our success is built on the strength of our network and
                      the relationships we establish. If you have any knowledge
                      or insights that could help us identify valuable
                      opportunities or assets, we encourage you to reach out to
                      us today. Contact us to learn more about our program and
                      how you can benefit from our extensive network and
                      expertise.
                    </p>
                    <ul className="mb-0">
                      <li>Business owners</li>
                      <li>Managers</li>
                      <li>Employees</li>
                      <li>Landlords</li>
                      <li>Leasing companies</li>
                      <li>Rental companies</li>
                      <li>Crane companies and operators</li>
                      <li>Trucking companies and truck drivers</li>
                      <li>
                        Professionals such as lawyers, CPAs, bankers, engineers,
                        and architects
                      </li>
                      <li>
                        Non-profit organizations including charities, churches,
                        chambers of commerce, and fundraisers.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default EquipmentServicingInfo;
