import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./RentAContainer.css";

function RentAContainer() {
  return (
    <section className="sellwithusSection pb100 pt-5">
      <Tab.Container id="ClientTabs" defaultActiveKey="first">
        <Row>
          <Col lg={3} className="mb-3">
            <Nav variant="pills" className="flex-column about-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Welcome to ProContainers</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  Convenience Delivered to Your Doorstep
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">
                  Flexible Storage for Every Need  
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">
                  Straightforward, Transparent Pricing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">
                  Easy and Secure Payment Options
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">Why Choose ProContainers?</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seven">
                  Ready to Rent a ProContainer?
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="bg-white d-block">
                  <h4 className="about-title">Welcome to ProContainers</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Have you ever wished for more space? A place where you
                          could store everything that matters? Whether it's a
                          corner for your seasonal clothes, a spot for your
                          bulky sports gear, or a whole new stockroom for your
                          booming business, ProContainers is your trusted
                          partner.
                        </p>
                        <p>
                          <strong>Why Do You Need a Shipping Container?</strong>
                          <br></br>
                          <br></br>
                          <strong>Home Storage: </strong>
                          Declutter your home without parting with your
                          cherished possessions. Our containers are perfect for
                          storing furniture, personal items, or any other home
                          goods during renovations or just to free up some
                          space.
                          <br></br>
                          <strong>Business Inventory: </strong>
                          Make room for your expanding business. ProContainers
                          can serve as a secure, on-site stockroom for your
                          products, equipment, or tools.
                          <br></br>
                          <strong>Construction Sites: </strong>
                          Keep your equipment safe, dry, and on-hand. Our
                          containers offer a robust, secure solution for storing
                          construction tools and materials.
                          <br></br>
                          <strong> Ranches: </strong>
                          Manage your farming and ranching equipment better.
                          ProContainers are perfect for storing tractors, feeds,
                          tack, and other ranch equipment, keeping them secure
                          and easily accessible.
                          <br></br>
                          <strong>Events: </strong>
                          Hosting a big event and need somewhere to store your
                          equipment or merchandise? ProContainers are a perfect
                          temporary solution.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div div className="bg-white d-block">
                  <h4 className="about-title">
                    Convenience Delivered to Your Doorstep
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Forget about the hassles of transportation; we bring
                          the storage solution to you! We drop off the container
                          of your choice right at your location. Our experienced
                          delivery team ensures it's placed exactly where you
                          want it, taking all the stress out of your hands.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    Flexible Storage for Every Need
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Our containers are the perfect fit for any
                          requirement, temporary or permanent. You can choose
                          from a variety of sizes and features to suit your
                          unique needs. Our range includes everything from
                          10-foot personal units to 40-foot commercial
                          containers. With ProContainers, you'll always find the
                          perfect match.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    Straightforward, Transparent Pricing
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          We believe in simplicity and honesty. Our clear
                          pricing structure ensures you only pay for what you
                          need and when you need it. There are no hidden charges
                          or confusing contracts, just straightforward and fair
                          pricing.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    Easy and Secure Payment Options
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Payment is effortless with our secure online system.
                          We accept all major credit cards and offer an
                          automatic billing option for long-term rentals. Your
                          security is paramount to us, and our payment gateway
                          is encrypted to provide utmost safety.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="six">
                <div className="bg-white d-block">
                  <h4 className="about-title">Why Choose ProContainers?</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <br></br>
                          <strong>Ease: </strong>
                          Our delivery team does all the heavy lifting. Your job
                          is to simply point us in the right direction.
                          <br></br>
                          <br></br>
                          <strong>Flexibility: </strong>
                          Short term or long term, big or small, we've got a
                          container that suits your needs. Affordability: Our
                          competitive rates and transparent pricing give you the
                          best value for your money.
                          <br></br>
                          <br></br>
                          <strong>Security: </strong>
                          Our containers are robust, weather-resistant, and
                          secure. Your possessions are always safe with
                          ProContainers. Customer Service: Our dedicated team is
                          available 24/7 to answer your queries and resolve any
                          issues promptly.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="seven">
                <div className="bg-white d-block">
                  <h4 className="about-title">Ready to Rent a ProContainer?</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Renting a ProContainer is as simple as choosing your
                          container size, specifying your location, and
                          selecting your rental duration. Click the button below
                          to start the process. If you have any questions, our
                          friendly customer service team is just a call or email
                          away. Let ProContainers make your life more spacious!
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </section>
  );
}

export default RentAContainer;
