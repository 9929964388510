import React from "react";
import { Container, Image } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import EquipmentServicingInfo from "./EquipmentServicingInfo/EquipmentServicingInfo";
import EquipmentServicingForm from "./EquipmentServicingForm/EquipmentServicingForm";
import useDocumentTitle from "../../DocumentTitle";

function EquipmentServicing() {
  useDocumentTitle("PC | Finders Program");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-0">
          <div className="contactBanner EquipmentServicingBanner DesktopInnerText">
            <span>Our program is 100% CONFIDENTIAL!</span>
            <h3>
              {" "}
              Finders Fee Program <sup>TM</sup>
            </h3>
            <p>
              Introducing our innovative Finders Fee program for industrial
              assets. We specialize in asset management and surplus disposition,
              and understand the value of acquiring assets that meet our
              clients' needs. Our program rewards those who refer qualified
              industrial assets, expanding our inventory and providing added
              value to clients. Leverage your network and earn a commission by
              partnering with us. Join now to earn and help us grow.
            </p>
          </div>
          <div className=" EquipmentServicingBanner MobileTextInfo">
            <span>Our program is 100% CONFIDENTIAL!</span>
            <h4>
              Finders Fee Program <sup>TM</sup>
            </h4>
            <div className="MobileDesInfo">
              <Image src="/assets/images/FindersFeeProgram-01.jpg" />
              <p>
                Introducing our innovative Finders Fee program for industrial
                assets. We specialize in asset management and surplus
                disposition, and understand the value of acquiring assets that
                meet our clients' needs. Our program rewards those who refer
                qualified industrial assets, expanding our inventory and
                providing added value to clients. Leverage your network and earn
                a commission by partnering with us. Join now to earn and help us
                grow.
              </p>
            </div>
          </div>
          <div className="clearfix"></div>
          <EquipmentServicingInfo />
          <EquipmentServicingForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default EquipmentServicing;
