import React, { useState, useEffect } from 'react';
import { Card, Button, Table } from "react-bootstrap";
import { Icon } from '@iconify/react';
import CreateCompanyContact from "../ComapanyContacts/CreateCompanyContact/CreateCompanyContact";
import axiosClient from "../../axiosClient";

function CompanyContacts() {
    const [show, setShow] = useState(null);
    const [contacts, setContacts] = useState([]);
    const handleShow = () => {
        if (show == null) {
            if (show == null) {
                setShow(true)
            }
        }
        else {
            setShow(!show)
        }
    };

    useEffect(() => {
        getCustomerContacts();
    }, []);

    const refreshData = () => { 
        getCustomerContacts();
    };
    return (
        <>
            <Card style={{ width: "100%" }} className="mb-4">
                <Card.Body>
                    <div className='flex-between'>
                        <div>
                            <Card.Title>Company Contacts</Card.Title>
                        </div>
                        {/* <div className='pr-3 pb-3'>
                            <Button onClick={handleShow} type="button" className=" btn btn-primary pt-1" >
                                <Icon icon="material-symbols:add" /> Add
                            </Button >
                        </div> */}
                    </div>
                    <div className='TableResponsive'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Contact Type </th>
                                <th>Company Name</th>
                                <th>Email</th>
                                <th>Contact Name</th>
                                <th>Phone Number</th>
                                <th>Address 1</th>
                                <th>Address 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip / Postal Code</th>
                                <th>Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts && contacts.map(contact => (
                                <tr key={contact.id}>
                                    <td>{contact.contactDivision}</td>
                                    <td>{contact.companyName}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.customerName}</td>
                                    <td>{contact.phoneNumber}</td>
                                    <td>{contact.address1}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    </div>
                </Card.Body>
            </Card>
            <CreateCompanyContact show={show}  refreshData={refreshData}  /> 
        </>
    );

    function getCustomerContacts() {
     const userData = JSON.parse(localStorage.getItem('login'));
       if(userData && userData.user && userData.user.customerKeyQB > 0){
        axiosClient.post("findCustomerContacts?customerNumber="+userData.user.customerKeyQB)
        .then(res => {
            setContacts(res);
        })
        .catch(error => {
            console.error(error);
        });
       }
      
    }
}
export default CompanyContacts;
