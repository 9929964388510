import { Container, Image } from "react-bootstrap";
import React from "react";
import "./SellWithUsBanner.css";
import Logo from "../../public/assets/images/logo.webp";

function SellWithUsBanner() {
  return (
    <section className="sellwithusSection InventoryBottomBanner">
      <Container>
        <div className="InventoryBanner">
          <div className="sellwithusInfo">
            <h5>
              Divine Abundance: Multiplying Talents, Elevating Blessings{" "}
              <sup>TM</sup>
            </h5>
            <p>
              Unleashing God's Gifts to Transform Lives and Create Value through
              Faith-Driven Purpose and Vision.
            </p>
          </div>
          <div className="sellwithusImg">
            {/* TODO : Need round logo */}
            <Image src={Logo} />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default SellWithUsBanner;
