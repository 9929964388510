import React, { useState, useEffect } from 'react';
import { Card, Button, Table } from "react-bootstrap";
import { Icon } from '@iconify/react';
import axiosClient from "../../axiosClient";
import CreateBillingAddress from "../Billings/createBillingAddress";
import EditBillingAddressfunc from "../Billings/editBillingAddressComponent";
import { toast } from "react-toastify/dist/react-toastify";

function Billings() {
    const [show, setShow] = useState(null);
    const [editShow, setEditShow] = useState(null);
    const [billing, setBilling] = useState([]);
    const [editData, setEditData] = useState(null);


    const handleEditShow = () => {
        if (editShow == null) {
            if (editShow == null) {
                setEditShow(true)
            }
        }
        else {
            setEditShow(!editShow)
        }
    };

    const edit = (item) => {
        setEditData(item);
    }

    useEffect(() => {
      
        if(editData != undefined && editData != null){
            handleEditShow();
        }
        
    }, [editData])
    

    const handleShow = () => {
        if (show == null) {
            if (show == null) {
                setShow(true)
            }
        }
        else {
            setShow(!show)
        }
    };

    useEffect(() => {
        getBillings();
    }, []);

    const refreshData = () => {
        getBillings();
    };

    return (
        <>
            <Card style={{ width: "100%" }} className="mb-4">
                <Card.Body>
                    <div className='flex-between'>
                        <div>
                            <Card.Title>Billing Addresses</Card.Title>
                        </div>
                        <div className='pr-3 pb-3'>
                            <Button onClick={handleShow} type="button" className=" btn btn-primary pt-1" >
                                <Icon icon="material-symbols:add" /> Add
                            </Button >
                        </div>
                    </div>
                    <div className="TableResponsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nickname</th>
                                    
                                    <th>Address 1</th>
                                    <th>Address 2</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Zip / Postal Code</th>
                                    <th>Country</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billing && billing.map(billItem => (
                                    <tr key={billItem.id}>
                                        <td>{billItem.nameAlias}</td>
                                        
                                        <td>{billItem.address1}</td>
                                        <td>{billItem.address2}</td>
                                        <td>{billItem.city}</td>
                                        <td>{billItem.state}</td>
                                        <td>{billItem.zip_PostalCode}</td>
                                        <td>{billItem.country}</td>
                                        <td>
                                            <div className='d-flex'>
                                                <Button type="button"  onClick={() => { edit(billItem) }} className=" btn btn-primary btn-sm ">
                                                    <Icon icon="material-symbols:edit" />
                                                </Button >
                                                <Button type="button" className=" btn btn-primary btn-sm ms-1"
                                                    onClick={() => { deleteBillingItem(billItem.id) }}  >
                                                    <Icon icon="ic:baseline-delete" />
                                                </Button >
                                            </div>
                                        </td>
                                    </tr>
                                )
                                )
                                }
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            <CreateBillingAddress show={show} refreshData={refreshData} />
            {
                editData != undefined && editData != null 
                &&  <EditBillingAddressfunc show={editShow}  refreshData={refreshData}  editData ={editData} />

            }
        </>
    );

    function getBillings() {
        const userData = JSON.parse(localStorage.getItem('login'));
        if(userData && userData.user){
            axiosClient.get("getBillingsInfoByUserId/" + Number(userData.user.id))
            .then(res => {
                setBilling(res);
            })
            .catch(error => {
                console.error(error);
            });
        }
      
    }

    function deleteBillingItem(id) {
        axiosClient.get("deleteBilling/" + id)
            .then(res => {
                getBillings();
                toast.success("Billing Addresses removed successfully");
            })
            .catch(error => {
                console.error(error);
            });
    }
}
export default Billings;
