import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Modal, Button } from "react-bootstrap";
import axiosClient from "../../axiosClient";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify/dist/react-toastify";
import { setBillingAddress } from "../Slices/CartSlice";

function CreateBillingAddress(props) {
  const userData = JSON.parse(localStorage.getItem("login"));
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (props.show != null) {
      setShow(true);
    }
  }, [props.show]);

  const us_states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [formValues, setFormValues] = useState({
    nameAlias: "",
    // companyName: "",
    // email: "",
    // contactName: "",
    // phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  });
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [companyerrorMessage, setCompanyErrorMessage] = useState("");
  const [emailerrorMessage, setEmailErrorMessage] = useState("");
  const [contacterrorMessage, setContacterrorMessage] = useState("");
  const [phoneerrorMessage, setPhoneerrorMessage] = useState("");
  const [adresserrorMessage, setAdressErrorMessage] = useState("");
  const [cityerrorMessage, setCityErrorMessage] = useState("");
  const [stateerrorMessage, setStateErrorMessage] = useState("");
  const [ziperrorMessage, setZipErrorMessage] = useState("");

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (formValues.nameAlias == "") {
      setErrorMessage("Please enter a Name Aliad ");
      setLoading(false);
    } else {
      setErrorMessage("");
      setLoading(false);
    }

    // if (formValues.companyName == "") {
    //   setCompanyErrorMessage("Please enter a company name ");
    //   setLoading(false);
    // } else {
    //   setCompanyErrorMessage("");
    //   setLoading(false);
    // }

    // if (validateEmail(formValues.email) == false) {
    //   setEmailErrorMessage("Please enter a valid email ");
    //   setLoading(false);
    // } else {
    //   setEmailErrorMessage("");
    //   setLoading(false);
    // }
    // if (formValues.contactName == "") {
    //   setContacterrorMessage("Please enter a valid phonenumber ");
    //   setLoading(false);
    // } else {
    //   setContacterrorMessage("");
    //   setLoading(false);
    // }

    // if (formValues.phoneNumber == "") {
    //   setPhoneerrorMessage("Please enter a phoneNumber ");
    //   setLoading(false);
    // } else {
    //   setPhoneerrorMessage("");
    //   setLoading(false);
    // }

    if (formValues.address1 == "") {
      setAdressErrorMessage("Please enter a address1 ");
      setLoading(false);
    } else {
      setAdressErrorMessage("");
      setLoading(false);
    }

    if (formValues.city == "") {
      setCityErrorMessage("Please enter a city ");
      setLoading(false);
    } else {
      setCityErrorMessage("");
      setLoading(false);
    }

    if (formValues.state == "") {
      setStateErrorMessage("Please enter a state ");
      setLoading(false);
    } else {
      setStateErrorMessage("");
      setLoading(false);
    }

    if (formValues.zip == "") {
      setZipErrorMessage("Please enter a zip / postcode code ");
      setLoading(false);
    } else {
      setZipErrorMessage("");
      setLoading(false);
    }

    if (
      formValues.nameAlias !== "" &&
      // formValues.companyName !== "" &&
      // validateEmail(formValues.email) == true &&
      // formValues.contactName !== "" &&
      // formValues.phoneNumber !== "" &&
      formValues.address1 !== "" &&
      formValues.city !== "" &&
      formValues.state !== "" &&
      formValues.zip !== ""
    ) {
      await savBillingInfo();
    }
  };

  async function savBillingInfo() {
    var body = {
      id: 0,
      nameAlias: formValues.nameAlias,
      // companyName: formValues.companyName,
      // email: formValues.email,
      // contactName: formValues.contactName,
      // phoneNumber: formValues.phoneNumber,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip_PostalCode: formValues.zip,
      country: formValues.country,
      userId: userData.user.id,
    };

    try {
      await axiosClient
        .post("saveBillingInfo", body)
        .then((res) => {
          if (res == "") {
            toast.error("Failed! Please enter a valid address.");
            return false;
          }
          if (res != null && props.isFromPB) {
            var billData = [];
            billData.push(res);
            dispatch(setBillingAddress(billData));

            var selectOption = {};
            selectOption.value = res.id;
            selectOption.label = res.nameAlias;

            props.SetValFromPb(selectOption);
          }
          props.refreshData();
          resetForm();
          setLoading(false);
          toast.success("Billing Addresses added successfully");
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });

      setShow(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);
    // setErrorMessage('')
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleStateChange = (value) => {
    setFormValues({
      ...formValues,
      ["state"]: value,
    });
  };
  return (
    <form>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Billing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll70 px-2">
            <div>
              <Row>
                <Col lg={12}>
                  <div className="form-input">
                    <label> Nickname</label>
                    <input
                      type="text"
                      name="nameAlias"
                      value={formValues.nameAlias}
                      onChange={handleChange}
                    />
                    {errorMessage && (
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    )}
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="form-input">
                    <label>Company Name</label>
                    <input
                      type="text"
                      name="companyName"
                      value={formValues.companyName}
                      onChange={handleChange}
                    />
                    {companyerrorMessage && <span style={{ color: 'red' }}>{companyerrorMessage}</span>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    {emailerrorMessage && <span style={{ color: 'red' }}>{emailerrorMessage}</span>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Contact Name</label>
                    <input
                      type="text"
                      name="contactName"
                      value={formValues.contactName}
                      onChange={handleChange}
                    />
                    {contacterrorMessage && <span style={{ color: 'red' }}>{contacterrorMessage}</span>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                    />
                    {phoneerrorMessage && <span style={{ color: 'red' }}>{phoneerrorMessage}</span>}
                  </div>
                </Col> */}
                <Col lg={12}>
                  <div className="form-input">
                    <label>Address 1</label>
                    <input
                      type="text"
                      name="address1"
                      value={formValues.address1}
                      onChange={handleChange}
                    />
                    {adresserrorMessage && (
                      <span style={{ color: "red" }}>{adresserrorMessage}</span>
                    )}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-input">
                    <label>Address 2 (optional)</label>
                    <input
                      type="text"
                      name="address2"
                      value={formValues.address2}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      value={formValues.city}
                      onChange={handleChange}
                    />
                    {cityerrorMessage && (
                      <span style={{ color: "red" }}>{cityerrorMessage}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <br></br>
                  <select
                    className="input-form"
                    value={formValues.state}
                    onChange={(event) => handleStateChange(event.target.value)}
                  >
                    <option value={""} key={""}>
                      Select State
                    </option>
                    {us_states &&
                      us_states.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                  {stateerrorMessage && (
                    <span style={{ color: "red" }}>{stateerrorMessage}</span>
                  )}
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Zip / Postal Code</label>
                    <input
                      type="text"
                      name="zip"
                      value={formValues.zip}
                      onChange={handleChange}
                    />
                    {ziperrorMessage && (
                      <span style={{ color: "red" }}>{ziperrorMessage}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Country</label>
                    <input disabled value={"US"} type="text" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading == false && (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          )}
          {loading ? (
            <Button variant="primary" disabled style={{ position: "relative" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  color: "white",
                  top: "calc(50% - 12px)",
                  left: "calc(50% - 12px)",
                }}
              />
            </Button>
          ) : (
            <Button variant="primary" type="button" onClick={handleFormSubmit}>
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </form>
  );

  function resetForm() {
    setFormValues({
      nameAlias: "",
      companyName: "",
      email: "",
      contactName: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    });
  }
}

export default CreateBillingAddress;
