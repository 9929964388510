import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { getTotals, handleAddCart } from "../Slices/CartSlice";
import { useDispatch } from "react-redux";
import { setCategory } from "../Slices/FilterSlice";
import MakeOffer from "../MakeOffer/MakeOffer";
import LoginModal from "../LoginPage/LoginModal";
import { toast } from "react-toastify/dist/react-toastify";
import "./ProductCard.css";
import AddToCartOrCheckOut from "../AddtoCartOrCheckout/addtocart";

function ProductCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setshow] = useState(null);
  const [showLogin, setShowLogin] = useState(null);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [showCheckoutPopUp, setshowCheckoutPopUp] = useState(null);
  const [comfortDropPrice, setComfortDropPrice] = useState(null);
  const [comfortPrice, setComfortPrice] = useState(null);
  const [delRioDropPrice, setDelRioDropPrice] = useState(null);
  const [delRioPrice, setDelRioPrice] = useState(null);
  const [houstonDropPrice, setHoustonDropPrice] = useState(null);
  const [houstonPrice, setHoustonPrice] = useState(null);
  const [lowestPrice, setLowestPrice] = useState(null);

  const handleshow = () => {
    if (show === null) {
      if (show === null) {
        setshow(true);
      }
    } else {
      setshow(!show);
    }
  };

  useEffect(() => {
    setComfortDropPrice(props.RecentProductList.comfortDropPrice);
    setComfortPrice(props.RecentProductList.comfortPrice);
    setDelRioDropPrice(props.RecentProductList.delRioDropPrice);
    setDelRioPrice(props.RecentProductList.delRioPrice);
    setHoustonDropPrice(props.RecentProductList.houstonDropPrice);
    setHoustonPrice(props.RecentProductList.houstonPrice);
  });

  const calculateLowestPrice = () => {
    const prices = [
      comfortDropPrice,
      comfortPrice,
      delRioDropPrice,
      delRioPrice,
      houstonDropPrice,
      houstonPrice,
    ].filter((price) => !isNaN(price) && price !== 0);

    if (prices.length > 0) {
      const minPrice = Math.min(...prices);

      setLowestPrice(minPrice.toLocaleString("en-US"));
    } else {
      setLowestPrice(null);
    }
  };

  useEffect(() => {
    calculateLowestPrice();
  }, [props.RecentProductList]);

  const handleShowLogin = () => {
    if (showLogin === null) {
      if (showLogin === null) {
        setShowLogin(true);
      }
    } else {
      setShowLogin(!showLogin);
    }
  };

  function buyNow(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData === null) {
      setIsBuyNow(true);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      navigate("/ShoppingCart");
    }
  }
  function detailPage() {
    const queryParams = itemQuaryParams();
    window.open(
      `/InventoryDetail/${
        props.RecentProductList.id
      }?${queryParams.toString()}`,
      "_blank"
    );
  }
  function detailPageMobile() {
    const queryParams = itemQuaryParams();
    window.open(
      `/InventoryDetail/${
        props.RecentProductList.id
      }?${queryParams.toString()}`,
      "_blank"
    );
    console.log("Function called");
  }

  // console.log(props.RecentProductList);

  function itemQuaryParams() {
    const item = {
      industry: props.RecentProductList.industryName,
      category: props.RecentProductList.categoryName,
      type: props.RecentProductList.typeName,
      description: props.RecentProductList.description,
      assetNo: props.RecentProductList.assetNumber,
      location: props.RecentProductList.location,
      model: props.RecentProductList.model,
      manufacturer: props.RecentProductList.menufacturer,
    };
    const queryParams = new URLSearchParams();
    queryParams.set("item", JSON.stringify(item));
    return queryParams;
  }

  function inventoryPage() {
    var selectItem = {
      id: props.RecentProductList.RelatedCategoryId,
      name: props.RecentProductList.categoryName,
    };
    dispatch(setCategory(selectItem));
    navigate("/Containers");
  }

  function addCart(selectedItem) {
    var loginData = JSON.parse(localStorage.getItem("login"));
    if (loginData === null) {
      setIsBuyNow(false);
      handleShowLogin();
    } else {
      dispatch(handleAddCart(selectedItem));
      dispatch(getTotals());
      setshowCheckoutPopUp(true);
      toast.success("Container Added to Cart");
    }
  }

  return (
    <>
      <div className="ProductCardItem DesktopProduct">
        <div
          className="ProductImg"
          onClick={() => {
            detailPage();
          }}
        >
          {(() => {
            if (props.RecentProductList.coverImageURL) {
              return (
                <div
                  className="img-fluid"
                  style={{ height: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: props.RecentProductList.coverImageURL,
                  }}
                />
              );
            } else {
              return <Image src="/assets/images/not-found.png" />;
            }
          })()}
          <div className="for-rent">
            <div>
              {props.RecentProductList.isRental == true && (
                <a
                  className="rentTag"
                  onClick={() => {
                    inventoryPage();
                  }}
                >
                  {/* {props.RecentProductList.categoryName} */}
                  Available For Rent
                </a>
              )}
            </div>
            {/* <div>
              {props.RecentProductList.categoryName !== "" &&
                props.RecentProductList.categoryName !== null && (
                  <a
                    className="whiteTag"
                    onClick={() => {
                      inventoryPage();
                    }}
                  >
                    {props.RecentProductList.categoryName}
                  </a>
                )}
            </div> */}
          </div>
        </div>
        <div className="ProductInfo">
          <h5
            className="title title_link"
            onClick={() => {
              detailPage();
            }}
          >
            {props.RecentProductList.description.toUpperCase()}
          </h5>
          <div className="for-rent">
            <h6 className="lowprice">Starting at: ${lowestPrice}</h6>

            {/* {props.RecentProductList.isRental == true && (
              // <Image src={RentImg} className="rentImg" height={20} />
              <h7>Available For Rent</h7>
            )} */}
          </div>
          <div className="ProductBtnWrap">
            {/* <a
              type="button"
              className="btn btn-blue btnFullCart"
              onClick={() => addCart(props.RecentProductList)}
            >
              Add To Cart
            </a> */}
            {/* <a
              onClick={() => buyNow(props.RecentProductList)}
              className="btn btn-blue"
            >
              Buy Now
            </a> */}
            <a onClick={handleshow} className="btn btn-blue btnFullCart">
              Get a Quote
            </a>
            <a href="tel:830-282-0000" className="btn btn-blue btn-arrow">
              <Icon className="me-2" icon="fluent:call-16-filled" /> Call Us
            </a>
            <a
              href={
                "mailto:sales@procontainers.com?subject=" +
                props.RecentProductList.title +
                " - " +
                props.RecentProductList.assetNumber
              }
              className="btn btn-blue btn-arrow"
            >
              <Icon className="me-2" icon="ic:round-email" /> Email Us
            </a>
          </div>
        </div>
      </div>
      <div className="ProductCardItem MobileProduct">
        <div
          className="ProductImg"
          onClick={() => {
            detailPageMobile();
            console.log("Function CLicked");
          }}
        >
          {(() => {
            if (props.RecentProductList.coverImageURL) {
              return (
                <div
                  className="img-fluid"
                  style={{ height: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: props.RecentProductList.coverImageURL,
                  }}
                />
              );
            } else {
              return <Image src="/assets/images/not-found.png" />;
            }
          })()}
          {/* {props.RecentProductList.categoryName !== "" &&
            props.RecentProductList.categoryName !== null && (
              <a
                className="whiteTag"
                onClick={() => {
                  inventoryPage();
                }}
              >
                {props.RecentProductList.categoryName}
              </a>
            )} */}
          <div className="for-rent">
            <div>
              {props.RecentProductList.isRental == true && (
                <a
                  className="rentTag"
                  onClick={() => {
                    inventoryPage();
                  }}
                >
                  {/* {props.RecentProductList.categoryName} */}
                  Available For Rent
                </a>
              )}
            </div>
            {/* <div>
              {props.RecentProductList.categoryName !== "" &&
                props.RecentProductList.categoryName !== null && (
                  <a
                    className="whiteTag"
                    onClick={() => {
                      inventoryPage();
                    }}
                  >
                    {props.RecentProductList.categoryName}
                  </a>
                )}
            </div> */}
          </div>
        </div>
        <div className="ProductInfo">
          <h5
            className="title title_link"
            onClick={() => {
              detailPageMobile();
            }}
          >
            {props.RecentProductList.description}
          </h5>
          {/* <h6>Starting at: ${props.RecentProductList.salePrice}</h6> */}
          <div className="ProductBtnWrap">
            {/* <a
              type="button"
              className="btn btn-blue btnFullCart"
              onClick={() => addCart(props.RecentProductList)}
            >
              Add To Cart
            </a> */}
            <a onClick={handleshow} className="btn btn-blue btnFullCart">
              Get a Quote
            </a>
            <a
              href={
                "mailto:sales@procontainers.com?subject=" +
                props.RecentProductList.title +
                " - " +
                props.RecentProductList.assetNumber
              }
              className="btn btn-blue  "
            >
              <Icon className="me-2" icon="ic:round-email" /> Email Us
            </a>
            {/* <a
              onClick={() => buyNow(props.RecentProductList)}
              className="btn btn-blue"
            >
              Buy Now
            </a> */}

            <a href="tel:830-282-0000" className="btn btn-blue btn-arrow">
              <Icon className="me-2" icon="fluent:call-16-filled" /> Call Us
            </a>
          </div>
        </div>
        {show != null && (
          <MakeOffer item={props.RecentProductList} show={show} />
        )}

        <AddToCartOrCheckOut
          item={props.productDetail}
          show={showCheckoutPopUp}
          isBuyNow={isBuyNow}
        />
        <LoginModal
          show={showLogin}
          item={props.RecentProductList}
          handleClose={handleShowLogin}
          isBuyNow={isBuyNow}
        />
      </div>
    </>
  );
}
export default ProductCard;
