import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import HomePage from "./Component/Home/HomePage";
import Inventory from "./Component/Inventory/Inventory";
import InventoryDetail from "./Component/InventoryDetail/InventoryDetail";
import ContactUs from "./Component/ContactUs/ContactUs";
import SwitchtoSell from "./Component/SwitchtoSell/SwitchtoSell";
import ShoppingCart from "./Component/ShoppingCart/ShoppingCart";
import PaymentCart from "./Component/PaymentCart/PaymentCart";
import Login from "./Component/LoginPage/Login";
import Signin from "./Component/SigninPage/Signin";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
import VerifyAccount from "./Component/VerifyAccount/VerifyAccount";
import ForgotPassOtp from "./Component/ForgotPassOtp/ForgotPassOtp";
import ResetPassword from "./Component/ResetPassword/ResetPassword";
import Carrier from "./Component/Carrier/Carrier";
import AboutusPage from "./Component/AboutusPage/AboutusPage";
import Success from "./Component/Stripe/Success";
import Cancel from "./Component/Stripe/Cancel";
import WishListPage from "./Component/WishListPage/WishListPage";
import store from "./Component/Store";
import Profile from "./Component/Profile/Profile";
import EquipmentServicing from "./Component/EquipmentServicing/EquipmentServicing";
import SpecializedContracts from "./Component/SpecializedContracts/SpecializedContracts";
import SurplusInventory from "./Component/SurplusInventory/SurplusInventory";
import Appraisals from "./Component/Appraisals/Appraisals";
import AppLoader from "./AppLoader/AppLoader";
import TermsOfService from "./Component/TermsOfService/TermsOfService";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SiteMapLoader from "./Component/SiteMapLoader";
import axios from "axios";
import "./App.css";
import ClimateControlContainer from "./Component/ClimateControlledContainers/ClimateControlledContainer";
import ModularManufacturing from "./Component/ModularManufacturing/ModularManufacturing";
import RentAContainer from "./Component/RentAContainer/RentAContainer";
import BuyAContainer from "./Component/BuyAContainer/BuyAContainer";
import ContainerServices from "./Component/ContainerServices/ContainerServices";
import Gallery from "./Component/Gallery/Gallery";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "./Component/ScrollToTop/Scroll";
import FourtyFeet from "../src/Component/FourtyFeet/FourtyFeet";
import TweentyFeet from "../src/Component/TweentyFeet/TweentyFeet";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [0]);

  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgb(24 24 29)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",
      bg: "rgb(249 249 255)",
      footer_bg: "#0a1435",
      btn: "#1a2c55",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: { mobile: "768px", tab: "998px" },
  };

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <SiteMapLoader />
            <AppLoader />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Containers" element={<Inventory />} />
              <Route
                path="/InventoryDetail/:id"
                element={<InventoryDetail />}
              />
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/Selltous" element={<SwitchtoSell />} />
              <Route path="/ShoppingCart" element={<ShoppingCart />} />
              <Route path="/PaymentCart" element={<PaymentCart />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Signin" element={<Signin />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/VerifyAccount/:email" element={<VerifyAccount />} />
              <Route path="/ForgotPassOtp" element={<ForgotPassOtp />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              <Route path="/Careers" element={<Carrier />} />
              <Route path="/Aboutus" element={<AboutusPage />} />
              <Route path="/wishlist" element={<WishListPage />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/Appraisals" element={<Appraisals />} />
              {/* <Route path="/PortableOffices" element={<PortableOffice />} /> */}
              <Route path="/RentAContainer" element={<RentAContainer />} />
              <Route
                path="/ClimateControlledContainer"
                element={<ClimateControlContainer />}
              />
              <Route path="/Gallery" element={<Gallery />}></Route>

              <Route
                path="/ModularManufacturing"
                element={<ModularManufacturing />}
              />
              {/* <Route path="/PortableOffice" element={<PortableOffice />} /> */}
              <Route path="/BuyAContainer" element={<BuyAContainer />} />
              <Route
                path="/ContainerServices"
                element={<ContainerServices />}
              />
              <Route path="/FindersProgram" element={<EquipmentServicing />} />
              <Route path="/40ft" element={<FourtyFeet />} />
              <Route path="/20ft" element={<TweentyFeet />} />
              {/* <Route path="/200ft" element={<FourtyFeet />} /> */}
              <Route
                path="/SpecializedContracts"
                element={<SpecializedContracts />}
              />
              <Route path="/SurplusInventory" element={<SurplusInventory />} />
              <Route path="/TermsOfService" element={<TermsOfService />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
