import React from "react";
import { Accordion, Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import useDocumentTitle from "../../DocumentTitle";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  useDocumentTitle("PC | Privacy Policy");
  return (
    <>
      <Header />
      <div className="d-margin">
        <Container>
          <div className="Terms_Info">
            <h2>Privacy Policy</h2>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Introduction</Accordion.Header>
                <Accordion.Body>
                  <p>
                    {" "}
                    At ProContainers, we take your privacy very seriously. This
                    Privacy Policy is intended to inform you about how we
                    collect, use, and protect your personal information. By
                    using our website, you are consenting to the terms of this
                    Privacy Policy.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. Information we Collect</Accordion.Header>
                <Accordion.Body>
                  <p>
                    We collect personal information from you when you place an
                    order, create an account, fill out a form, or otherwise
                    communicate with us. The personal information we collect may
                    include your name, address, email address, phone number,
                    payment information, and any other information you provide
                    to us.
                  </p>
                  <p>
                    We also collect non-personal information, such as your
                    browser type, IP address, and other technical information
                    about your device and how you interact with our website.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. Use of Information</Accordion.Header>
                <Accordion.Body>
                  <p>
                    We use the information we collect to provide you with our
                    products and services, to process and fulfill your orders,
                    to communicate with you about your orders and our products
                    and services, and to improve our website and your
                    experience.
                  </p>
                  <p>
                    We may also use your information to send you promotional
                    materials, updates, and newsletters about our products and
                    services. You can opt-out of these communications at any
                    time by following the instructions provided in the email.
                  </p>
                  <p>
                    We do not sell or share your personal information with third
                    parties for their marketing purposes.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>4. Security of Information</Accordion.Header>
                <Accordion.Body>
                  <p>
                    We take reasonable precautions to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, and destruction. We use industry-standard
                    security measures such as encryption, firewalls, and secure
                    servers to protect your information.
                  </p>
                  <p>
                    However, no method of transmission over the internet or
                    method of electronic storage is 100% secure, and we cannot
                    guarantee the absolute security of your personal
                    information.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>5. Third Party Services</Accordion.Header>
                <Accordion.Body>
                  <p>
                    We use third-party services, such as payment processors,
                    shipping carriers, and marketing services, to help us
                    provide our products and services. These services may
                    collect and use your personal information on our behalf.
                  </p>
                  <p>
                    We only work with reputable third-party services that have
                    their own privacy policies that comply with applicable laws
                    and regulations.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  6. Cookies and Other Tracking Technologies
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We use cookies and other tracking technologies to collect
                    information about your browsing behavior and preferences on
                    our website. Cookies are small data files that are stored on
                    your device when you visit our website. They help us
                    remember your preferences and enhance your experience.
                  </p>
                  <p>
                    You can control cookies through your browser settings, but
                    disabling cookies may limit your ability to use certain
                    features of our website.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>7. Children's Privacy</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our website is not intended for children under the age of
                    18, and we do not knowingly collect personal information
                    from children. If you are a parent or guardian and believe
                    that your child has provided us with personal information,
                    please contact us immediately.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>8. Changes to this Policy</Accordion.Header>
                <Accordion.Body>
                  <p>
                    We may update this Privacy Policy from time to time. If we
                    make any material changes to this Policy, we will notify you
                    by email or by posting a notice on our website.
                  </p>
                  <p>
                    Your continued use of our website after we make changes to
                    this Policy will constitute your acceptance of the changes.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>9. Contact Us</Accordion.Header>
                <Accordion.Body>
                  <p>
                    If you have any questions or concerns about our Privacy
                    Policy, please contact us at{" "}
                    <a href="mailto:info@procontainers.com">
                      info@procontainers.com
                    </a>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
