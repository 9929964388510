import { Image } from "react-bootstrap";
import React from "react";
import RentProcess from "../RentProcess/RentProcess";
import "./SellMyEquipment.css";
import HowitWorks from "../HowItWorks/HowitWorks";

function SellMyEquipment() {
  return (
    <section className="sellwithusSection SellBannerFix">
      <div className="bg-white white-0 DesktopInnerText">
        <div className="sellwithusInfo ps-0">
          <h4>Sell Your Containers to Us - Boost Your Cash Flow</h4>
          <p className="mb-3">
            Selling your container to us is a straightforward and convenient
            process. Simply reach out to our team, and we will guide you through
            the necessary steps. We understand that time is valuable, so we
            strive to provide a quick and streamlined experience.
          </p>
        </div>
        <div className="sellwithusImg">
          <Image src="/assets/images/rentacontainerimg.webp" />
        </div>
      </div>
      <div className="MobileTextInfo">
        <h4>Sell your assets</h4>
        <div className="MobileDesInfo">
          <Image
            className="MixBlendImg"
            src="/assets/images/rentacontainerimg.webp"
          />
          <p>
            In the competitive landscape of modern business, every organization
            strives to maximize the value of its assets and improve its bottom
            line. One way to achieve this is through the efficient management
            and disposal of used equipment or fixed assets. This is where our
            company comes in, offering you an unparalleled experience in
            maximizing value and increasing your bottom line when you sell us
            your assets.
          </p>
        </div>
      </div>
      <br />
      <div className="clearfix"></div>
      <div className="sellToUsBoxMain">
        <h4>Why Sell Your Containers to Us?</h4>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Simplify and Profit</h6>
              <p>
                We value your time as much as you do. We've developed a
                streamlined process that allows you to sell your containers to
                us without the hassle. No need to waste time finding individual
                buyers or negotiating prices. We offer competitive rates,
                ensuring you get the most from your excess container.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>We Come to You</h6>
              <p>
                No matter where you're located, we'll come to you. Our pickup
                service ensures a convenient, worry-free process. We handle the
                logistics, meaning you can focus on your core business
                operations while we take care of your containers.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>We Take Care of The Details</h6>
              <p>
                Have a container that's still full? No problem! We offer a
                comprehensive service that includes emptying your containers.
                This unique feature removes an additional burden from your
                shoulders, making the selling process even more convenient.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Benefit from Our Sister Companies' Services</h6>
              <p>
                If your containers are filled with supplies, we won't just empty
                them — we'll buy those too. Through partnerships with our sister
                companies, we can purchase all supplies within your containers,
                creating an additional revenue stream for your business. This
                holistic approach ensures we can be your one-stop solution for
                managing all your assets.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>End-to-End Management</h6>
              <p>
                Our comprehensive ProContainers management solutions guarantee a
                smooth and hassle-free journey when it comes to selling your
                used equipment. From the first consultation and assessment to
                the ultimate sale and transportation, we take care of every
                single detail involved in the transaction. With a dedicated team
                of professionals, we will support you throughout the entire
                process, providing constant updates and ensuring your active
                participation at every stage. Rest assured, with our services,
                your experience will be seamless and stress-free.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Sustainable Solutions</h6>
              <p>
                At procontainers, we prioritize sustainable business practices
                and are dedicated to promoting environmentally responsible
                solutions. When you choose to sell your used equipment to us,
                you actively participate in the circular economy by granting a
                second life to assets that could otherwise be discarded in
                landfills. Our unwavering commitment to sustainability not only
                has a positive impact on the environment but also enhances your
                corporate social responsibility profile. By partnering with us,
                you contribute to a greener future while aligning your business
                with responsible practices.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-white p-3">
              <h6>Competitive Pricing</h6>
              <p>
                At ProContainers, we recognize the importance of maximizing the
                value of your used equipment to positively impact your bottom
                line. Our pricing model is designed to be competitive, ensuring
                that you achieve the highest possible returns on your fixed
                assets. Leveraging our extensive network and industry expertise,
                we skillfully negotiate on your behalf, securing the best deals
                available in the market. We understand that your financial
                success is a priority, and our dedicated efforts are aimed at
                delivering optimal outcomes for your business when it comes to
                selling your equipment.
              </p>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="bg-white bg-boxwhite">
              <h3>How Does it Work?</h3>
              <h5 className="p-center">
                Selling your containers to us is as easy as 1, 2, 3!
              </h5>
              <HowitWorks />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="bg-white bg-boxwhite">
              <h6>
                Partner with us and experience the best in container services in
                the industry.
              </h6>
              <RentProcess />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SellMyEquipment;
