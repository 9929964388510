import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as appConstants from "../../../constants";
import { toast } from "react-toastify/dist/react-toastify";
import "./AppraisalsForm.css";

function AppraisalsForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    companyName: "",
    contactPerson: "",
    phoneNumber: "",
    emailAddress: "",
    workNumber: "",
    mailingAddress: "",
    assetType: "",
    manufacturer: "",
    modelNumber: "",
    serialNumber: "",
    age: "",
    condition: "",
    additionalInformation: "",
    appraisalService: "",
    finalAssetValueEstimateRequired: "Asset Valuation",
    requestedDateOfAppraisal: "",
    preferredMethodOfContact: "Phone",
    assetPhotos: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataEntries = Object.entries(formData);
    const data = {};
    for (let [key, value] of formDataEntries.entries()) {
      data[key] = value;
    }

    fetch(appConstants.LogicAppEmailUrl, {
      method: "POST",
      body: JSON.stringify({
        body: JSON.stringify(data),
        subject: "REQUEST FOR INDUSTRIAL ASSET APPRAISAL",
        to: appConstants.emailToAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success("Form submitted successfully!");
        setFormData({
          companyName: "",
          contactPerson: "",
          phoneNumber: "",
          emailAddress: "",
          workNumber: "",
          mailingAddress: "",
          assetType: "",
          manufacturer: "",
          modelNumber: "",
          serialNumber: "",
          age: "",
          condition: "",
          additionalInformation: "",
          appraisalService: "Asset Valuation",
          finalAssetValueEstimateRequired: "",
          requestedDateOfAppraisal: "",
          preferredMethodOfContact: "Phone",
          assetPhotos: [],
        });
        handleClose();
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  };

  return (
    <>
      <div className="rentFormMain">
        <div>
          <h3>
            Unlock the hidden value in your facilities <sup>TM</sup>
          </h3>
          <p>Discover the true worth of your assets with us.</p>
        </div>
        <Link to="#" onClick={handleShow} className="btn btn-white btn-icon ">
          Request Appraisals{" "}
          <Icon
            className="ms-2"
            icon="material-symbols:arrow-right-alt-rounded"
          />
        </Link>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="RecyclingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>REQUEST FOR INDUSTRIAL ASSET APPRAISAL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sellToUsPopup">
            <Form className="row">
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="companyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Company Name"
                    name={"companyName"}
                    onChange={handleChange}
                    value={formData.companyName}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="contactPerson">
                  <Form.Label>Contact Person</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Contact Person"
                    name={"contactPerson"}
                    onChange={handleChange}
                    value={formData.contactPerson}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Phone Number"
                    name={"phoneNumber"}
                    onChange={handleChange}
                    value={formData.phoneNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="emailAddress">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email Address"
                    name={"emailAddress"}
                    onChange={handleChange}
                    value={formData.emailAddress}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="workNumber">
                  <Form.Label>Work Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Work Number"
                    name={"workNumber"}
                    onChange={handleChange}
                    value={formData.workNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="mailingAddress">
                  <Form.Label>Mailing Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Mailing Address"
                    name={"mailingAddress"}
                    onChange={handleChange}
                    value={formData.mailingAddress}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="type">
                  <Form.Label>Asset Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Asset Type"
                    name={"type"}
                    onChange={handleChange}
                    value={formData.type}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="manufacturer">
                  <Form.Label>Manufacturer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Manufacturer"
                    name={"manufacturer"}
                    onChange={handleChange}
                    value={formData.manufacturer}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="modelNumber">
                  <Form.Label>Model Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Model Number"
                    name={"modelNumber"}
                    onChange={handleChange}
                    value={formData.modelNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="serialNumber">
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Serial Number"
                    name={"serialNumber"}
                    onChange={handleChange}
                    value={formData.serialNumber}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="age">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Age"
                    name={"age"}
                    onChange={handleChange}
                    value={formData.age}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3" controlId="condition">
                  <Form.Label>Condition</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Condition"
                    name={"condition"}
                    onChange={handleChange}
                    value={formData.condition}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="additionalInformation">
                  <Form.Label>Additional Information</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Additional Information"
                    name={"additionalInformation"}
                    onChange={handleChange}
                    value={formData.additionalInformation}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="appraisalService">
                  <Form.Label>
                    Please select the following appraisal service(s)
                  </Form.Label>
                  <Form.Select
                    name={"appraisalService"}
                    onChange={handleChange}
                    value={formData.appraisalService}
                  >
                    <option>Asset Valuation</option>
                    <option>Asset Valuation, Purchase, and Removal</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className="mb-3"
                  controlId="finalAssetValueEstimateRequired"
                >
                  <Form.Label>
                    Final Asset Value (FAV) Estimate Required
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Final Asset Value (FAV) Estimate Required"
                    name={"finalAssetValueEstimateRequired"}
                    onChange={handleChange}
                    value={formData.finalAssetValueEstimateRequired}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className="mb-3"
                  controlId="requestedDateOfAppraisal"
                >
                  <Form.Label>Requested Date of Appraisal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter  Requested Date of Appraisal"
                    name={"requestedDateOfAppraisal"}
                    onChange={handleChange}
                    value={formData.requestedDateOfAppraisal}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group
                  className="mb-3"
                  controlId="preferredMethodOfContact"
                >
                  <Form.Label>Preferred Method of Contact</Form.Label>
                  <Form.Select
                    name={"preferredMethodOfContact"}
                    onChange={handleChange}
                    value={formData.preferredMethodOfContact}
                  >
                    <option>Phone</option>
                    <option>Email</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Label>
                    Please attach any relevant documents or photos of the asset
                    to help us better understand its condition and value
                  </Form.Label>
                  <Form.Control type="file" multiple />
                  <div className="ModalImagePopup">
                    <Image className="UploadImg" src={images} />
                  </div>
                </Form.Group>
              </Col>
            </Form>
            <small>
              Our team offers various appraisal services, including asset
              valuation and asset valuation, purchase, and removal. We will
              review your request and contact you within 24-48 hours to schedule
              an appointment and discuss any further details. Thank you for
              considering our appraisal services.
            </small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit Form
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppraisalsForm;
