import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import _ from 'lodash';
import CarrierFreightQuote from "./CarrierFreightQuote";
import CarrierQuote from "./CarrierQuote";
import { EstimatedConditionWeight } from '../../constants';
import { setTotalShippingAmount } from "../Slices/CartSlice";
import { useDispatch } from "react-redux";
import "./CarrierFreightQuote.css"
import "./ShipeEgine.css"

function ShipeEngineQuotes(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [freightQuote, setFreightQuote] = useState(null);
  const [postalQuote, setPostalQuote] = useState(null);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [isShippingTotal, setIsShippingTotal] = useState(false);
  var cartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
  const havePostItems = cartItems.some(item => isNullNumber(item.estimatedTotalWeight) > 0 && isNullNumber(item.estimatedTotalWeight) < EstimatedConditionWeight.maxPostalShipping);
  const haveFrighItems = cartItems.some(item => isNullNumber(item.estimatedTotalWeight) > EstimatedConditionWeight.maxPostalShipping);
  const handleClose = () => {
    setShow(false);
    props.close();
  };

  useEffect(() => {
    if (props.show) {
      setPostalQuote(null);
      setFreightQuote(null);
      setIsShippingTotal(false);
      setShow(true);
    }
  }, [props.show]);
  useEffect(() => {
    checkConfirmShippingValidation();
  }, [freightQuote]);

  useEffect(() => {
    checkConfirmShippingValidation();
  }, [postalQuote]);

  const checkConfirmShippingValidation = () => {

    setIsShippingTotal(false);

    let total = 0;
    setTotalShippingCost(0);

    if (havePostItems && !haveFrighItems) {
      if (postalQuote && postalQuote.data) {
        setIsShippingTotal(true);
      }
    }
    if (!havePostItems && haveFrighItems) {
      if (freightQuote && freightQuote.amount) {
        setIsShippingTotal(true);
      }
    }
    if (havePostItems && haveFrighItems) {
      if (postalQuote && postalQuote.data && freightQuote && freightQuote.amount) {
        setIsShippingTotal(true);
      }
    }
    if (postalQuote && postalQuote.data) {
      total = total + isNullNumber(postalQuote.data.shipping_amount.amount);
    }
    if (freightQuote && freightQuote.amount) {
      total = total + isNullNumber(freightQuote.amount);
    }
    setTotalShippingCost(total);
  }

  const cofirmPostQuote = (rate) => {
    setPostalQuote(rate);
  }

  const cofirmFreight = (rate) => {
    setFreightQuote(rate)
  }

  const handleConfirmShipping = () => {
    dispatch(setTotalShippingAmount(totalShippingCost));
    handleClose();
  }

  function isNullNumber(value) {
    if (value && Number(value) > 0) {
      return Number(value);
    }
    else {
      return 0;
    }
  }

  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);
      if (v > 0) {
        return "$" + v + " USD";
      }
      else {
        return "$0.00 USD";
      }
    }
    else {
      return "$0.00 USD";
    }
  }
  return (
    <Modal
      className="my-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      animation={false}>
      <Modal.Header closeButton>
        Freight & Parcel Shipping Selection
      </Modal.Header>
      <Modal.Body>
        <Row>
          { haveFrighItems &&
            <Col lg={6} sm={12} className="mb-3">
              <CarrierFreightQuote cofirmFreight={cofirmFreight} item={props.item} />
            </Col>
          }
          {havePostItems &&
            <Col lg={6} sm={12} className="mb-3" >
              <CarrierQuote cofirmPostQuote={cofirmPostQuote} item={props.item} />
            </Col>
          }
        </Row>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h4 className="text-center mb-3 mt-2">Shipping Confirmation</h4>
          <p className="text-center mb-3">
             {freightQuote && 
            <div>
             Freight Quote = 
              {formattedValue(freightQuote.amount)}
            </div>
            } 
            {postalQuote && postalQuote.data && <div>
              Postal Quote =
              {formattedValue(postalQuote.data.shipping_amount.amount)}
               </div>  }  
            Total Shipping Cost = {totalShippingCost && formattedValue(totalShippingCost)}
          </p>
          <Button
            variant="success"
            className="btn-lg"
            disabled={!isShippingTotal}
            onClick={handleConfirmShipping}
          >
            Confirm Shipping
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  );

}

export default ShipeEngineQuotes;
