import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import { Icon } from "@iconify/react";
import { Modal, Button } from "react-bootstrap";
import "./Cancel.css";

function Cancel(props) {
  useDocumentTitle("PC | Payment Cancel");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (props.show != null) {
      setShow(true);
    }
  }, [props.show]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment Failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div
            className="error-container success-container"
            style={{ maxWidth: "100%" }}
          >
            <span>
              <Icon icon="ep:circle-close-filled" color="#b32424" />
            </span>
            <h2 className="error-title">Payment Failed</h2>
            <p className="error-message">
              We're sorry, but there was an issue processing your payment.
              Please try again or contact our support team for assistance.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Cancel;
