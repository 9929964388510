import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./ClientManagemenInfo.css";

function BuyAContainer() {
  return (
    <section className="sellwithusSection pb100 pt-5">
      <Tab.Container id="ClientTabs" defaultActiveKey="first">
        <Row>
          <Col lg={3} className="mb-3">
            <Nav variant="pills" className="flex-column about-column">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  Welcome to ProContainer: Your Gateway to Premium Container
                  Solutions!
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  1.Sustainable and Economical
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">2.Robust and Reliable </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">
                  3.Versatility in Applications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">
                  4.Mobility for Dynamic Businesses
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">
                  5.Unique Aesthetic & Branding Opportunities
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seven">6.Speedy Deployment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="eight">7.Security</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="nine">
                  8.Networking & Community Building
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    Welcome to ProContainer: Your Gateway to Premium Container
                    Solutions!
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Ladies and gentlemen, business magnates, ranch owners,
                          and visionaries of industry, have you ever thought of
                          integrating the might and versatility of shipping
                          containers into your operations? Today, we pull back
                          the curtain on a realm teeming with potential. Let's
                          unveil the compelling reasons why shipping containers
                          could be the power move your business or ranch has
                          been waiting for.
                        </p>
                        {/* <p>
                          Catering to a diverse clientele, our expertise
                          encompasses a wide array of tangible and intangible
                          resources. From managing inventory, fixed assets, and
                          intellectual property to nurturing relationships,
                          optimizing websites, and even handling entire business
                          transitions.
                        </p>
                        <p>
                          Our all-encompassing approach extends to plant
                          decommissioning and dismantling, with an unwavering
                          commitment to environmental stewardship. In Mexico, we
                          navigate the intricacies of the liquidation process
                          seamlessly, including employee settlements,
                          import/export regulations, transportation logistics,
                          and pedimentos.
                        </p>
                        <p>
                          With a legacy dating back to 1970, our extensive
                          experience spans various industries and market
                          segments, establishing us as esteemed authorities in
                          the United States and worldwide.
                        </p>
                        <p>
                          We take immense pride in our ability to minimize
                          liability while maximizing returns through the
                          strategic redeployment of capital assets in the
                          marketplace. Our aim is to ensure efficient,
                          professional, and sustainable asset management that
                          brings value to our clients
                        </p> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div div className="bg-white d-block">
                  <h4 className="about-title">2.Sustainable and Economical</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          In today's fast-paced economy, efficiency is key.
                          Repurposing shipping containers not only champions the
                          green revolution but is also cost-efficient. By
                          tapping into this resource, you’re recycling thousands
                          of pounds of steel, thereby aligning your brand with
                          eco-consciousness and saving money simultaneously.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="bg-white d-block">
                  <h4 className="about-title">3.Robust and Reliable</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Crafted to withstand the high seas and the fiercest of
                          elements, these containers guarantee durability. For
                          businesses or ranchers who require sturdy storage,
                          workshops, or operational spaces, the container is
                          your steadfast solution.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="bg-white d-block">
                  <h4 className="about-title">4.Versatility in Applications</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          <strong>Business Storage & Warehousing: </strong>
                          Quick, secure, and customizable spaces for inventory,
                          equipment, or supplies.
                          <br></br>
                          <br></br>
                          <strong> Ranching Needs: </strong>
                          Ideal for secure feed storage, tack rooms, or even as
                          shelter for livestock during extreme conditions.
                          <br></br>
                          <br></br>
                          <strong>Office Spaces: </strong> Be it a temporary
                          project site or a permanent office, shipping
                          containers provide a distinct and modern workspace.
                          <br></br>
                          <br></br>
                          <strong>Pop-up Shops & Retail: </strong>
                          Create an iconic brand presence wherever you go with
                          portable retail spaces.
                          <br></br>
                          <br></br>
                          <strong> Agricultural Storage: </strong>
                          Perfect for ranches, these containers can store
                          machinery, tools, or harvest, safeguarding against
                          weather and pests.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    5.Mobility for Dynamic Businesses
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          The world of business is ever-evolving. Shipping
                          containers can move with you, offering dynamic
                          solutions for businesses that need to pivot, relocate,
                          or expand quickly.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="six">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    6.Unique Aesthetic & Branding Opportunities
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Shipping containers, with their industrial edge, can
                          act as a branding statement. Customize them to
                          resonate with your brand ethos, ensuring you remain
                          unforgettable in the market landscape.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="seven">
                <div className="bg-white d-block">
                  <h4 className="about-title">7.Speedy Deployment</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Time-sensitive projects no longer need months of
                          construction. Modular containers can be repurposed in
                          record time, ensuring your business stays ahead of the
                          curve.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="eight">
                <div className="bg-white d-block">
                  <h4 className="about-title">8.Security</h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Time-sensitive projects no longer need months of
                          construction. Modular containers can be repurposed in
                          record time, ensuring your business stays ahead of the
                          curve.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="nine">
                <div className="bg-white d-block">
                  <h4 className="about-title">
                    8.Networking & Community Building
                  </h4>
                  <div className="histroy-info">
                    <Row>
                      <Col lg={12}>
                        <p>
                          Imagine a business park or ranching community entirely
                          made of shipping containers, fostering collaboration,
                          exchange of ideas, and mutual growth.
                        </p>
                        <p>
                          In a world where adaptation and resilience are the
                          keys to success, shipping containers symbolize both.
                          For businesses and ranchers alike, they offer
                          solutions that are not only practical but also
                          visionary.
                          <br></br>
                          Embrace the future, redefine your operations, and
                          harness the power of shipping containers. Your
                          business and ranching revolution starts here!
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </section>
  );
}

export default BuyAContainer;
