import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Slider from "react-slick";
import "./OurServices.css";

function OurServices() {
  var ClientLogo = {
    dots: false,
    navs: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-white clientLogosHidden">
      {/* <Container>
                <Slider className='client-logosMobile client-logos' {...ClientLogo}>
                    <div className='client-logos-Items'><Image src='/assets/images/gsa.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/udh.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/ubp.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/metro.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-02.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-03.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-04.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-06.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-07.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-08.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-09.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-10.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-11.png' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-12.svg' /></div>
                    <div className='client-logos-Items'><Image src='/assets/images/client-logo-13.png' /></div>
                </Slider>
            </Container> */}
      <div className="OurServices-Section">
        <Container>
          <h2>Our Services</h2>
          <Row class="row">
            <Col lg={4} md={6}>
              <div className="service-box">
                <h6>Buy a Container</h6>
                <p>
                  Overpricing, shady dealings, delayed deliveries, and stockouts
                  leaving you frustrated? Your search ends here! We prioritize
                  value, punctuality, and constant availability in our shipping
                  container sales. We own all our containers, eliminating
                  'callback' waits. Let us transform your experience in the
                  secondary container market!
                </p>
                <a href="/BuyAContainer">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box">
                <h6>Rent a Container</h6>
                <p>
                  Tired of hidden fees, delays, unavailable or distant rentals?
                  We've got you covered! We offer transparent pricing - what you
                  see is what you get. Our containers are perfect for job sites,
                  ranches, or anywhere they fit. Rent hassle-free, local
                  containers with us and never look back!
                </p>
                <a href="/RentAContainer">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box">
                <h6>Climate Controlled Containers</h6>
                <p>
                  Explore the versatility of our climate-controlled containers!
                  Perfect for any purpose, these multi-use units adapt to your
                  needs, ensuring optimal conditions regardless of your project.
                  Unlock endless possibilities with our all-climate solution in
                  a container!
                </p>
                <a href="/ClimateControlledContainer">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box">
                <h6>Portable Offices</h6>
                <p>
                  Transform the way you work with our portable office
                  containers. Climate-controlled, multi-office, or open – tailor
                  your space for any purpose. These multi-use units redefine
                  versatility, providing a custom work solution right where you
                  need it. Step into your portable office haven!
                </p>
                <a href="/PortableOffices">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col>
            {/* <Col lg={4} md={6}>
              <div className="service-box-squized">
                <h6>Modular Manufacturing</h6>
                <p>
                  We pride ourselves on being one of the most agile teams in the
                  Industry. We can build and manage the process itself, the
                  software, or even provide the staffing. Designed based on your
                  needs as a company, irrelevant of Industry. We are
                  professionals in process design and business management.
                </p>
                <a href="/ModularManufacturing">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col> */}
            <Col lg={4} md={6}>
              <div className="service-box">
                <h6>Sell us your container</h6>
                <p>
                  Ready to sell your container? Provide all relevant details and
                  we'll promptly present you with a fair proposal. Turn your
                  Containers into profit effortlessly with us. We make selling
                  containers as simple as a click. Share your info, get a quote
                  - it's that straightforward!
                </p>
                <a href="/Selltous">
                  Learn More <Icon icon="material-symbols:arrow-right-alt" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OurServices;
