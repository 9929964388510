import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./OurHistory.css";

function OurHistory() {
  return (
    <>
      <div className="bg-white ">
        <div className="histroy-info">
          <Row className="align-items-center mb-4">
            <Col lg={6}>
              <p>
                Established in 1970, our company has been steadfastly dedicated to delivering exceptional services to our valued clients and customers. As a fourth-generation family-owned business, we are committed to preserving and advancing this legacy for generations to come.
              </p>
              <p>
                We take pride in offering comprehensive solutions for the acquisition, exchange, management, storage, and recycling of your corporate surplus and industrial assets. Our expertise extends to all aspects of value, including tangible and intangible assets, such as inventory, fixed assets, intellectual property, business relationships, websites, and even entire businesses. At the core of our approach is the perfect balance of corporate professionalism and a family-oriented ethos, ensuring a seamless and supportive experience for all our stakeholders.
              </p>
            </Col>
            <Col lg={6}>
              <div className="histroy-img">
                <Image src="/assets/images/about-img-01.png" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="histroy-img histroy-img2">
                <Image src="/assets/images/about-img-02.png" />
              </div>
            </Col>
            <Col lg={6}>
              <p>We take pride in providing comprehensive decommissioning and dismantling services for our clients plants and facilities, while diligently managing all aspects of the process, including environmental concerns. Since our establishment in 1970, our expertise has expanded across a vast array of industries and asset market segments. We excel in minimizing liabilities and maximizing returns through the strategic redeployment of capital assets to the marketplace. Our commitment to upholding a corporate yet familial approach ensures seamless collaboration and long-lasting partnerships with our valued clients.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default OurHistory;
