import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  industry: {},
  type: {},
  category: {},
  colors: [],
  accessorials: [],
  tRange: -1,
  priceEndRange: -1,
  brandNameList: [],
  conditionList: [],
  locations: [],
  queryText: "",
  // ddIndustry:{},
  selectedCategory: {},
  selectedType: {},
  saleType: {},
  isBuy: null, // Initial state for Buy checkbox
  isRent: null, // Initial state for Rent checkbox
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setBuyOption: (state, action) => {
      state.isBuy = action.payload;
    },

    setRentOption: (state, action) => {
      state.isRent = action.payload;
    },

    setIndustry(state, param) {
      state.industry = param.payload;
    },
    setCategory(state, param) {
      state.category = param.payload;
    },

    setSaleType(state, param) {
      state.saleType = param.payload;
    },

    setType(state, param) {
      state.type = param.payload;
    },

    industryRemove(state, param) {
      state.industry = {};
    },

    categoryRemove(state, param) {
      state.category = {};
    },

    saleTypeRemove(state, param) {
      state.saleType = {};
    },

    typeRemove(state, param) {
      state.type = {};
    },

    setPriceRange(state, param) {
      state.priceStartRange = param.payload.minPrice;
      state.priceEndRange = param.payload.maxPrice;
    },
    removeStartPrice(state, param) {
      state.priceStartRange = -1;
    },

    removeisBuy(state, param) {
      state.isBuy = null;
    },

    removeisRent(state, param) {
      state.isRent = null;
    },

    removeEndPrice(state, param) {
      state.priceEndRange = -1;
    },

    clearFilters(state, para) {
      state.industry = {};
      state.category = {};
      state.type = {};
      state.priceStartRange = -1;
      state.priceEndRange = -1;
      state.brandNameList = [];
      state.conditionList = [];
      state.colors = [];
      state.accessorials = {};
      state.locations = [];
      state.queryText = "";
      // state.ddIndustry ={};
      state.selectedCategory = {};
      state.selectedType = {};
      state.saleType = {};
      state.isBuy = true;
      state.isRent = true;
    },

    handelRemoveSelectedCategory(state, para) {
      // state.ddIndustry ={};
    },

    setItemLocation(state, param) {
      if (param.payload !== undefined && param.payload !== null) {
        state.locations = param.payload;
      }
    },

    setColors(state, param) {
      if (param.payload !== undefined && param.payload !== null) {
        state.colors = param.payload;
      }
    },

    setAccessorials(state, param) {
      if (param.payload !== undefined && param.payload !== null) {
        state.accessorials = param.payload;
      }
    },

    accessorialsRemove(state, param) {
      if (state.accessorials.length > 0) {
        var index = _.findIndex(state.accessorials, function (o) {
          return o === param.payload;
        });
        if (index === -1) {
          state.accessorials.push(param.payload);
        } else {
          _.remove(state.accessorials, param.payload);
          state.accessorials.splice(index, 1);
        }
      } else {
        state.accessorials.push(param.payload);
      }
    },

    colorsRemove(state, param) {
      if (state.colors.length > 0) {
        var index = _.findIndex(state.colors, function (o) {
          return o === param.payload;
        });
        if (index === -1) {
          state.colors.push(param.payload);
        } else {
          _.remove(state.colors, param.payload);
          state.colors.splice(index, 1);
        }
      } else {
        state.colors.push(param.payload);
      }
    },

    removeItemLocation(state, param) {
      if (state.locations.length > 0) {
        var index = _.findIndex(state.locations, function (o) {
          return o === param.payload;
        });
        if (index === -1) {
          state.locations.push(param.payload);
        } else {
          _.remove(state.locations, param.payload);
          state.locations.splice(index, 1);
        }
      } else {
        state.locations.push(param.payload);
      }
    },

    setItemCondition(state, param) {
      if (param.payload !== undefined && param.payload != null) {
        state.conditionList = param.payload;
      }
    },

    removeItemCondition(state, param) {
      if (state.conditionList.length > 0) {
        var index = _.findIndex(state.conditionList, function (o) {
          return o === param.payload;
        });
        if (index === -1) {
          state.conditionList.push(param.payload);
        } else {
          _.remove(state.conditionList, param.payload);
          state.conditionList.splice(index, 1);
        }
      } else {
        state.conditionList.push(param.payload);
      }
    },

    setItemBrands(state, param) {
      state.brandNameList = param.payload;
    },

    removeItemBrands(state, param) {
      if (state.brandNameList.length > 0) {
        var index = _.findIndex(state.brandNameList, function (o) {
          return o === param.payload;
        });
        if (index === -1) {
          state.brandNameList.push(param.payload);
        } else {
          _.remove(state.brandNameList, param.payload);
          state.brandNameList.splice(index, 1);
        }
      } else {
        state.brandNameList.push(param.payload);
      }
    },

    setSelectedIndustry(state, param) {
      //   state.ddIndustry = param.payload;
      state.industry = param.industry;
    },

    setSelectedCategory(state, param) {
      state.selectedCategory = param.payload;
    },

    setSelectedType(state, param) {
      state.selectedType = param.payload;
    },

    setQueryText(state, param) {
      state.queryText = param.payload;
    },

    emptyQueryText(state, param) {
      state.queryText = "";
    },
  },
});

//getCategory
export const {
  setCategory,
  setSaleType,
  setIndustry,
  setAccessorials,
  setColors,
  industryRemove,
  setType,
  clearFilters,
  typeRemove,
  categoryRemove,
  setPriceRange,
  removeStartPrice,
  removeEndPrice,
  setItemLocation,
  setItemCondition,
  setSelectedIndustry,
  setSelectedCategory,
  setSelectedType,
  handelRemoveSelected,
  setQueryText,
  emptyQueryText,
  setItemBrands,
  removeItemBrands,
  removeItemCondition,
  removeItemLocation,
  accessorialsRemove,
  removeisBuy,
  removeisRent,
  setBuyOption,
  setRentOption,
  colorsRemove,
} = filterSlice.actions;

export default filterSlice.reducer;
