import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../DocumentTitle";
import "./ForgotPassword.css";
import { toast } from "react-toastify/dist/react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosClient from "../../axiosClient";
import { Spinner } from "react-bootstrap";
function ForgotPassword() {
  useDocumentTitle("PC | Forgot Password");

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      toast.error("Please enter your email");
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email");
      setLoading(false);
      return;
    }
    try {
      const response = await axiosClient.get("ResetPasswordRequest/" + email);
      if (response) {
        setLoading(false);
        toast.success("The OTP  code has been sent to your email.");
        navigate(`/VerifyAccount/${email}`, { state: { email: email } });
      } else {
        setLoading(false);
        toast.error("Email does not exist");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div className="main-login-wrap">
      <Row className="m-0 bg-light-gray">
        <Col lg={6} className="bg-white">
          <div className="logoInfo">
            <div className="login-logo">
              <Image src="/assets/images/logo.webp" />
            </div>
            <p>
              Forgot your password? Welcome to the club! We meet every time you
              try to log in.
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="login-box">
            <h3 className="mb-2">Forgot Password</h3>
            <p className="max-100">
              Please enter your email address, we will send you a one time OTP
              to reset your password.
            </p>
            <div className="login-input-width">
              <form>
                <div className="form-input">
                  <label>Enter Your Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>

                {loading ? (
                  <button
                    type="submit"
                    className="btn btn-blue d-block w-100 btn-sign mt-5"
                    disabled
                    style={{ position: "relative" }}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        top: "calc(50% - 12px)",
                        left: "calc(50% - 12px)",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-blue d-block w-100 btn-sign mt-5"
                  >
                    {" "}
                    Submit
                  </button>
                )}
              </form>
            </div>
            <h6 className="signup-link">
              Don’t have an account?<Link to="/Signin"> Sign up</Link>{" "}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;
