import React from "react";
import { Container, Image } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ClientManagemenInfo from "./ClientManagemenInfo/BuyAContainer";
import ClientManagemenForm from "./ClientManagemenForm/ClientManagemenForm";
import useDocumentTitle from "../../DocumentTitle";
import { Helmet } from "react-helmet";

function BuyAContainer() {
  useDocumentTitle("PC | Buy A Container");
  return (
    <div>
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={`https://kingsurplus.com/InventoryDetail/BuyAContainer`}
        />
      </Helmet>
      <div className="d-margin">
        <Container className="pt-2">
          <div className="contactBanner ClientBanner DesktopInnerText">
            <h3>Buy a Container </h3>
            <p>
              Discover the Boundless Business and Ranching Opportunities with
              Shipping Containers!
            </p>
          </div>
          <div className="MobileTextInfo">
            <h4>Client Asset Management </h4>
            <div className="MobileDesInfo">
              {/* <Image src="/assets/images/clientManagement.webp" /> */}
              <p>
                Empowering Success: Your Assets, Our Expertise <sup>TM</sup>
              </p>
            </div>
          </div>
          <div className="clearfix"></div>
          <ClientManagemenInfo />
          <ClientManagemenForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default BuyAContainer;
