import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartProductInfo from "./CartProductInfo/CartProductInfo";
import PriceCartBox from "./PriceCartBox/PriceCartBox";
import _, { result, set } from "lodash";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import {
  getTotals,
  handleToDistanceAndFareItem,
  setShippingAddress,
  handRentItem,
} from "../Slices/CartSlice";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import useDocumentTitle from "../../DocumentTitle";
import "./ShoppingCart.css";
import axiosClient from "../../axiosClient";
import { useLoadScript } from "@react-google-maps/api";
import Swal from "sweetalert2";

const placesLibrary = ["places", "distance_matrix"];

function ShoppingCart() {
  const [TransporationCost, setTransporationCost] = useState(0);
  const [isDistanceMoreThan150Miles, setIsDistanceMoreThan150Miles] =
    useState(false);
  const [selectedRent, setSelectedRent] = useState(null);
  const [selectedRentPrice, setSelectedRentPrice] = useState(null);

  useDocumentTitle("PC | Checkout");
  const dispatch = useDispatch();
  const [seed, setSeed] = useState(Math.random());

  var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  var totalCount = 0;
  //const [searchResult, setSearchResult] = useState("Result: none");
  const [searchResult, setSearchResult] = useState("");
  const MilagePrice = [];
  // const { isLoaded } = useLoadScript({
  // //   googleMapsApiKey: "AIzaSyChZ090rTa9rpoGPaYLpddSpFCqzJ7M56c",
  // //   libraries: placesLibrary,
  // // });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyChZ090rTa9rpoGPaYLpddSpFCqzJ7M56c",
    libraries: placesLibrary,
  });

  if (cartItems && cartItems.length > 0) {
    totalCount = cartItems.length;
  }

  function checkShippingEligibility() {
    var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];
    if (SelectedItems) {
      const filteredItems = SelectedItems.filter(
        (item) =>
          Number(item.estimatedTotalWeight) > 4500 ||
          Number(item.widthInches) > 96 ||
          Number(item.heightInches) > 96 ||
          Number(item.lengthInches) > 96
      );
      return filteredItems.length > 0;
    } else {
      return false;
    }
  }

  const handleRentSelection = (rentPrice) => {
    // console.log(rentPrice);
    setSelectedRentPrice(rentPrice);
  };

  //

  const RemoveAddCart = (selectedItem) => {
    var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
    if (cartItems !== null) {
      var index = _.findIndex(cartItems, function (o) {
        return o.id === selectedItem.id;
      });
      if (index > -1) {
        _.remove(cartItems, function (i) {
          return i.id === selectedItem.id;
        });
        localStorage.setItem("cart_items", JSON.stringify(cartItems));
        setSeed(Math.random());
        dispatch(getTotals());
      }
    }
  };

  function handleUpdateQtyCart(selectedItem) {
    var cartItems = [];
    cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
    if (cartItems !== null) {
      var index = _.findIndex(cartItems, function (o) {
        return o.id === selectedItem.id;
      });
      if (index > -1) {
        if (
          selectedItem.selectedQty > 0 &&
          selectedItem.quantity >= selectedItem.selectedQty
        ) {
          cartItems[index].selectedQty = selectedItem.selectedQty;
          localStorage.setItem("cart_items", JSON.stringify(cartItems));

          cartItems.forEach((element) => {
            dispatch(handleToDistanceAndFareItem(element));
          });
        }

        setSeed(Math.random());
        dispatch(getTotals());
      }
    }
  }

  function onPlaceChanged(selectedItem) {
    // debugger;
    if (searchResult && searchResult != "") {
      if (searchResult.getPlace()) {
        const place = searchResult.getPlace();

        if (place && place.address_components) {
          const addressComponents = place.address_components;
          let postalCode = "";

          for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const componentTypes = component.types;

            if (componentTypes.includes("postal_code")) {
              postalCode = component.short_name;
              break;
            }
          }
          selectedItem.toPostalCode = postalCode;
          localStorage.setItem("zipCode", postalCode);
          calculateDistance(selectedItem);
        }
      }
    }
  }

  function calculateDistance(selectedItem) {
    const words = selectedItem.location.split(" ");
    const lastWord = words[words.length - 1];
    const service = new window.google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [lastWord],
        destinations: [selectedItem.toPostalCode],
        travelMode: "DRIVING",
      },
      async (response, status) => {
        if (status === "OK" && response.rows.length > 0) {
          const distanceText = response.rows[0].elements[0].distance.text;
          // console.log(distanceText);
          const numericDistance = parseFloat(
            distanceText.replace(/[^0-9.]/g, "")
          );
          const distanceInMiles = kilometersToMiles(numericDistance);
          // console.log(distanceInMiles);
          selectedItem.distance = distanceInMiles;
          selectedItem.milage = Number(distanceInMiles);
          if (distanceInMiles > 150) {
            setIsDistanceMoreThan150Miles(true);
          } else {
            setIsDistanceMoreThan150Miles(false);
          }

          const cartItems =
            JSON.parse(localStorage.getItem("cart_items")) || [];
          for (const cartItem of cartItems) {
            await axiosClient
              .post("GetQBMilagesRate", {
                containerSize: cartItem.categoryName,
                distanceInMiles: Number(distanceInMiles),
              })
              .then((res) => {
                const milageRate = res.milageRate;
                const perMilageRate = res.perMilageRate;
                const distanceInMiles = Number(distanceInMiles);

                cartItem.perMilagePrice = milageRate;
                cartItem.perMilageRate = "$" + perMilageRate.toString();
                cartItem.milage = selectedItem.milage.toString();

                dispatch(handleToDistanceAndFareItem(cartItem));
                handleUpdateQtyCart(cartItem);
                // console.log(cartItem);

                setTransporationCost(milageRate);
              });
          }
          dispatch(getTotals());
        } else {
          alert("Unable to calculate distance");
        }
      }
    );
  }

  // function calculateDistance(selectedItem) {
  //   const words = selectedItem.location.split(" ");
  //   const lastWord = words[words.length - 1];
  //   const service = new window.google.maps.DistanceMatrixService();

  //   service.getDistanceMatrix(
  //     {
  //       origins: [lastWord],
  //       destinations: [selectedItem.toPostalCode],
  //       travelMode: "DRIVING",
  //     },
  //     async (response, status) => {
  //       if (status === "OK" && response.rows.length > 0) {
  //         const distanceText = response.rows[0].elements[0].distance.text;
  //         console.log(distanceText);
  //         const numericDistance = parseFloat(
  //           distanceText.replace(/[^0-9.]/g, "")
  //         );
  //         const distanceInMiles = kilometersToMiles(numericDistance);
  //         console.log(distanceInMiles);
  //         selectedItem.distance = distanceInMiles;
  //         selectedItem.milage = Number(distanceInMiles);
  //         if (distanceInMiles > 150) {
  //           setIsDistanceMoreThan150Miles(true);
  //         } else {
  //           setIsDistanceMoreThan150Miles(false);
  //         }

  //         await axiosClient
  //           .post("GetQBMilagesRate", {
  //             containerSize: selectedItem.categoryName,
  //             distanceInMiles: Number(distanceInMiles),
  //           })
  //           .then((res) => {
  //             const milageRate = res.milageRate;
  //             const perMilageRate = res.perMilageRate;
  //             const distanceInMiles = Number(distanceInMiles);
  //             const cartItems =
  //               JSON.parse(localStorage.getItem("cart_items")) || [];
  //             for (const cartItem of cartItems) {
  //               cartItem.perMilagePrice = milageRate;
  //               cartItem.perMilageRate = "$"+perMilageRate.toString();
  //               cartItem.milage = selectedItem.milage.toString();

  //               debugger;
  //               dispatch(handleToDistanceAndFareItem(cartItem));
  //               handleUpdateQtyCart(cartItem);
  //               console.log(cartItem);
  //             }
  //             // Store the milageRate value in the state
  //             setTransporationCost(milageRate);
  //             dispatch(getTotals());
  //           });

  //       } else {
  //         alert("Unable to calculate distance");
  //       }
  //     }
  //   );
  // }

  useEffect(() => {}, [isDistanceMoreThan150Miles]);

  function redirectToContactPage() {
    const phoneNumber = "+1234567890";
    window.location.href = `tel:${phoneNumber}`;
  }

  function showAlert() {
    Swal.fire({
      title: "We apologize for this, but we only deliver within 150 miles.",
      text: "",
      icon: "warning",
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonText: "Call Us",
      showCancelButton: true,
      cancelButtonText: "Close",
      html: `
        <div>
          <p>If you would like us to ship a container to you, and you are located outside of our delivery range, we would be happy to provide a shipping quote.</p>
        </div>
      `,
    }).then((result) => {
      if (result.isConfirmed) {
        redirectToContactPage(); // Redirect to the contact page when "Contact Us" is clicked
      }
    });
  }

  function kilometersToMiles(distance) {
    const conversionFactor = 0.621371;
    const numericalValue = parseFloat(distance);

    if (!isNaN(numericalValue)) {
      const miles = numericalValue * conversionFactor;

      if (miles > 150) {
        showAlert();
      }
      return miles.toFixed(2);
    }
    return null;
  }

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function calculateRent(selectedItem) {
    dispatch(handRentItem(selectedItem));
    handleUpdateQtyCart(selectedItem);
    dispatch(getTotals());
  }

  useEffect(() => {
    dispatch(setShippingAddress({}));
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className="d-margin pt-3">
        <Container>
          <Link
            to="/Containers"
            className="my-3 d-block d-flex align-items-center"
          >
            <Icon icon="bi:arrow-left" className="me-2" /> Continue Shopping
          </Link>
          <h3 className="checkout-title">Shopping Cart</h3>
          <p className="checkout-description">
            You have {totalCount} items in your cart
          </p>
          <Row>
            <Col xl={9} lg={8}>
              {cartItems !== null &&
                cartItems !== undefined &&
                cartItems.map((product) => (
                  <CartProductInfo
                    RemoveAddCart={RemoveAddCart}
                    handleUpdateQtyCart={handleUpdateQtyCart}
                    key={seed + product.id + product.quantity}
                    item={product}
                    onPlaceChanged={onPlaceChanged}
                    onLoad={onLoad}
                    isLoaded={isLoaded}
                    calculateRent={calculateRent}
                    handleRentSelection={handleRentSelection}
                    TransporationCost={TransporationCost}
                  />
                ))}
            </Col>
            <Col xl={3} lg={4}>
              <PriceCartBox
                customKey={uuid.toString()}
                TransporationCost={TransporationCost}
                rent={selectedRent}
                cartItems={cartItems}
                isDistanceMoreThan150Miles={isDistanceMoreThan150Miles}
                RemoveAddCart={RemoveAddCart}
                handleUpdateQtyCart={handleUpdateQtyCart}
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                isLoaded={isLoaded}
                calculateRent={calculateRent}
                // handleRentSelection={handleRentSelection}
                handleRentSelection={handleRentSelection}
                item={cartItems[0]}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default ShoppingCart;
