import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import axiosClient from "../../../axiosClient";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotals,
  setShippingAddress,
  setBillingAddress,
  setPaymentMethod,
  setTotalShippingAmount,
} from "../../Slices/CartSlice";

import { Autocomplete } from "@react-google-maps/api";
import CreateBillingAddress from "../../Billings/createBillingAddress";
import CreatePaymentPreference from "../../PaymentPreferences/CreatePaymentPreference";
import CreateShipping from "../../Shippings/CreateShipping/CreateShipping";
import ShipeEngineQuotes from "../../ShipeEgine/ShipeEngineQuotes";
import Cancel from "../../Stripe/Cancel";
import uuid from "react-uuid";
import { Spinner, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./PriceCartBox.css";
import * as appConstants from "../../../constants";
import { toast } from "react-toastify/dist/react-toastify";
import axios from "axios";
import { set } from "lodash";
import Swal from "sweetalert2";

const placesLibrary = ["places", "distance_matrix"];

function PriceCartBox(props) {
  const {
    TransporationCost,

    rent,

    isDistanceMoreThan150Miles,

    cartItems,

    isLoaded,

    onLoad,

    customKey,

    RemoveAddCart,

    handleUpdateQtyCart,

    onPlaceChanged,

    calculateRent,

    handleRentSelection,
  } = props;

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchResult, setSearchResult] = useState("Result: none");
  const rentRate = useSelector((state) => state.cart.rentRate);
  const [isRentSelected, setIsRentSelected] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [deliverCost, setDeliverCost] = useState(0);
  const userData = JSON.parse(localStorage.getItem("login"));
  const [totalAmount, setTotalAmount] = useState(null);
  const [shippingInfo, setShippingInfo] = useState(null);
  const [tax, setTax] = useState(0);
  const [shippingInfos, setShippingInfos] = useState([]);
  const [billings, setBillings] = useState([]);
  const [paymentPreferences, setPaymentPreferences] = useState([]);
  const [showShipping, setShowShipping] = useState(null);
  const [showBilling, setShowBilling] = useState(null);
  const [showPP, setShowPP] = useState(null);
  const [paymentCard, setPaymentCard] = useState("");
  const [selectedShippingOption, setSelectedShippingOption] = useState("");
  const [selectedBillingOption, setSelectedBillingOption] = useState("");
  const [selectedPPOption, setSelectedPPOption] = useState("");
  const [isFromPB, setIsFromPB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrModel, setShowErrModel] = useState(null);
  const [showCarrierQuote, setShowCarrierQuote] = useState(null);
  const SetTureFromPb = () => setIsFromPB(true);
  const placesLibrary = ["places", "distance_matrix"];
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isExamet, setIExamet] = useState(false);
  const formRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [fileResponse, setFileResponse] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [deliverFees, setDeliverFees] = useState(0);
  const [transaction, setTransaction] = useState(0);
  const [zipCode, setZipCode] = useState(""); // Add this line

  const [isCheckedCustomerWillCall, setIsCheckedCustomerWillCall] =
    useState(false);
  var fileRes = null;
  var data = {};
  var fileData = {};

  const handleShowErrModel = () => {
    if (showErrModel == null) {
      if (showErrModel == null) {
        setShowErrModel(true);
      }
    } else {
      setShowErrModel(!showErrModel);
    }
  };

  const handleRentSelectionForPriceBox = () => {
    setIsRentSelected(true);
  };

  const onQuotePopClose = () => {
    setShowCarrierQuote(false);
  };

  const handleShow = () => {
    if (showCarrierQuote == null) {
      if (showCarrierQuote == null) {
        setShowCarrierQuote(true);
      }
    } else {
      setShowCarrierQuote(!showCarrierQuote);
    }
  };

  const SetShippingValFromPb = (selectVal) => {
    if (selectVal.value != undefined) {
      setSelectedShippingOption(selectVal.value);
    }

    setIsFromPB(false);
  };

  const SetBillingValFromPb = (selectVal) => {
    if (selectVal.value != undefined) {
      setSelectedBillingOption(selectVal.value);
    }

    setIsFromPB(false);
  };

  const SetPPValFromPb = (selectVal) => {
    if (selectVal.value != undefined) {
      setSelectedPPOption(selectVal.value);
    }

    setIsFromPB(false);
  };

  const onChangeShippingOtipon = (val) => {
    if (val == undefined || val == "") {
      return true;
    }

    if (val == "new") {
      handleShowShipping();
    } else {
      let shp = shippingInfos.filter((v) => v.id == val);

      dispatch(setShippingAddress(shp));

      setSelectedShippingOption(val);
    }
  };

  const handleShowShipping = () => {
    SetTureFromPb();

    if (showShipping == null) {
      if (showShipping == null) {
        setShowShipping(true);
      }
    } else {
      setShowShipping(!showShipping);
    }
  };

  const onChangeBillingOtipon = (val) => {
    if (val == undefined || val == "") {
      return true;
    }

    if (val == "new") {
      handleShowBilling();
    } else {
      let bill = billings.filter((v) => v.id == val);

      dispatch(setBillingAddress(bill));

      setSelectedBillingOption(val);
    }
  };

  const handleShowBilling = () => {
    SetTureFromPb();

    if (showBilling == null) {
      if (showBilling == null) {
        setShowBilling(true);
      }
    } else {
      setShowBilling(!showBilling);
    }
  };

  function calculateTotalShippingCost(cartItems) {
    let totalShippingCost = 0;
    if (cart.allShippingCost) {
      setDeliverFees(cart.allShippingCost);
      totalShippingCost = cart.allShippingCost;
    }
    return totalShippingCost;
  }

  const onChangePPOption = (val) => {
    if (val == undefined || val == "") {
      return true;
    }
    if (val == "new") {
      handleShowPP();
    } else {
      let pp = paymentPreferences.filter((v) => v.id == val);
      dispatch(setPaymentMethod(pp));
      setSelectedPPOption(val);
    }
  };

  const handleShowPP = () => {
    SetTureFromPb();
    if (showPP == null) {
      if (showPP == null) {
        setShowPP(true);
      }
    } else {
      setShowPP(!showPP);
    }
  };

  useEffect(() => {
    getshippings();
    getBillings();
    getPaymentPreferences();
  }, []);

  const refreshDataShipping = () => {
    getshippings();
  };

  const refreshDataBilling = () => {
    getBillings();
  };

  const refreshDataPP = () => {
    getPaymentPreferences();
  };

  function checkNumberisNULL(val) {
    if (!val) {
      return 0;
    }
    var nu = Number(val);
    if (isNaN(nu)) {
      return 0;
    }
    return nu;
  }

  useEffect(() => {
    dispatch(getTotals());

    if (userData.user.examet != null && userData.user.examet == false) {
      var totalTax = 0.0675;
      getAllTotalAmountFun(totalTax);
    } else {
      setTax(0);
      getAllTotalAmountFun(0);
    }
  }, [cart, dispatch, userData, selectedShippingOption]);

  function getAllTotalAmountFun(taxtPara) {
    let damageWavier = 0;
    let transactionFee = 0;
    let TaxApply = 0;
    let allTotal = 0;
    let tolerance = 0.0001;
    let previousTrans = 0;
    let previousTX = 0;

    if (checkNumberisNULL(cart.totalRent) > 0) {
      damageWavier = checkNumberisNULL(cart.totalRent) * 0.1;
    }
    allTotal =
      checkNumberisNULL(cart.cartTotalAmount) +
      checkNumberisNULL(cart.totalRent) +
      checkNumberisNULL(damageWavier) +
      checkNumberisNULL(cart.totalTransportation) +
      checkNumberisNULL(cart.allShippingCost);

    TaxApply = parseFloat(allTotal) * parseFloat(0.0675);
    allTotal = allTotal + TaxApply;

    if (allTotal > 3500) {
      transactionFee = parseFloat(allTotal) * 0.03;
      let taxApplyTransaction = parseFloat(transactionFee) * parseFloat(0.0675);
      TaxApply = taxApplyTransaction + TaxApply;
      allTotal = allTotal + taxApplyTransaction;
    }

    allTotal = allTotal + transactionFee;

    // do {
    //   previousTrans = parseFloat(transactionFee);
    //   previousTX = parseFloat(TaxApply);
    //   if (allTotal > 3500) {
    //     transactionFee = (parseFloat(allTotal) + parseFloat(TaxApply)) * 0.03;
    //     transactionFee = transactionFee.toFixed(2);
    //   }

    //   TaxApply = (allTotal + parseFloat(transactionFee)) * 0.0675;
    //   TaxApply = TaxApply.toFixed(2);

    // } while (
    //   Math.abs(parseFloat(transactionFee) - previousTrans) > tolerance ||
    //   Math.abs(parseFloat(TaxApply) - previousTX) > tolerance
    // );

    // allTotal = allTotal + parseFloat(TaxApply) + parseFloat(transactionFee);
    setTransaction(parseFloat(transactionFee));
    setTax(parseFloat(TaxApply));
    setTotalAmount(parseFloat(allTotal));
  }

  // useEffect(() => {}, [tax, totalAmount]);

  function getSelectedShippingInfo() {
    var shipeData = shippingInfos.find(
      (a) => Number(a.id) == Number(selectedShippingOption)
    );

    return shipeData;
    // if (isCheckedCustomerWillCall) {
    //   var shipCheckedData = {};
    //   shipCheckedData.nameAlias = shipeData.nameAlias;
    //   shipCheckedData.userId = shipeData.userId;
    //   shipCheckedData.address1 = "Will Call Pickup At Location";
    //   shipCheckedData.address2 = "";
    //   shipCheckedData.address2 = "";
    //   shipCheckedData.city = "";
    //   shipCheckedData.state = "";
    //   shipCheckedData.zip_PostalCode = "";
    //   shipCheckedData.country = "";

    //   return shipCheckedData;
    // } else {
    //   return shipeData;
    // }
  }

  function getSelectedBillingInfo() {
    return billings.find((a) => Number(a.id) == Number(selectedBillingOption));
  }

  function getSelectedPaymentPreference() {
    return paymentPreferences.find(
      (a) => Number(a.id) == Number(selectedPPOption)
    );
  }

  function handleSelectPaymentPreferenceChange(event) {
    const selectedOption = event.target.value;

    setPaymentCard(selectedOption);
  }

  function formattedValue(myValue) {
    if (myValue !== null && myValue !== undefined) {
      let v = Number(myValue).toFixed(2);

      if (v > 0) {
        return v;
      } else {
        return "0.00";
      }
    } else {
      return "0.00";
    }
  }

  const handleFreightAndParcel = () => {
    if (selectedShippingOption == "") {
      setErrorMessage("Select Shipping Address");

      return true;
    }

    setErrorMessage("");

    handleShow();
  };

  const handleSubmitFileUrl = async (e) => {
    var fileData = {};

    setLoading(true);

    e.preventDefault();

    fileData.examet = isExamet;

    if (!file && isExamet == true) {
      toast.error("A file is required If check Tax Examet!");

      setLoading(false);

      return;
    }

    if (isExamet == true) {
      await uploadFile();
    }

    if ((fileRes && fileRes.status == 200) || isExamet == false) {
      if (fileRes && fileRes.status == 200) {
        fileData.fileUrl = fileRes.data.blob.uri;

        fileData.fileName = fileRes.data.blob.name;

        fileData.fullName = "";

        fileData.businessName = "";

        fileData.email = "";

        fileData.password = "";

        fileData.id = userData.user.id;

        data = userData.user;

        data.fileUrl = fileData.fileUrl;

        data.fileName = fileData.fileName;

        setError(null);

        await axiosClient

          .post("UpdateFileUrlUser", fileData)

          .then((res) => {
            let token = userData.token;
            localStorage.removeItem("login");
            localStorage.setItem(
              "login",

              JSON.stringify({
                login: true,

                token: token,

                user: data,
              })
            );
            setFileError(null);
            toast.success("File upload successfully!");
            setLoading(false);
          })

          .catch((error) => {
            console.error(error);

            toast.error("Error! Something wrong.");

            setFileResponse("");

            setLoading(false);
          });
      }
    }

    if (isExamet == false) {
      fileData.fileUrl = "";

      fileData.fileName = "";

      fileData.fullName = "";

      fileData.businessName = "";

      fileData.email = "";

      fileData.password = "";

      fileData.id = userData.user.id;

      data = userData.user;

      data.fileUrl = "";

      data.fileName = "";

      setError(null);

      await axiosClient

        .post("UpdateFileUrlUser", fileData)

        .then((res) => {
          let token = userData.token;

          localStorage.removeItem("login");

          localStorage.setItem(
            "login",

            JSON.stringify({
              login: true,

              token: token,

              user: data,
            })
          );

          //  setIsSubmit(true)

          setFileError(null);

          toast.success("File upload successfully !");

          setLoading(false);
        })

        .catch((error) => {
          console.error(error);

          toast.error("Error! Something wrong.");

          setFileResponse("");

          setLoading(false);
        });
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      if (file.type !== "application/pdf") {
        toast.error("Please upload pdf file only");

        return;
      }

      uploadFile();
    }
  }, [file]);

  async function uploadFile() {
    if (!file) {
      toast.error("Something wrong! Please try again.");

      return;
    }

    const formData = new FormData();

    const fileName = `${file.name}-${Date.now()}-${uuid()}`;

    file.fileName = fileName;

    formData.append("file", file);

    fileRes = await axios({
      method: "post",

      url: appConstants.baseUrl + "Upload",

      data: formData,

      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (fileRes && fileRes.status == 200) {
      fileData.fileUrl = fileRes.data.blob.uri;

      fileData.fileName = fileRes.data.blob.name;

      fileData.fullName = "";

      fileData.businessName = "";

      fileData.email = "";

      fileData.password = "";

      fileData.id = userData.user.id;

      data = userData.user;

      data.fileUrl = fileData.fileUrl;

      data.fileName = fileData.fileName;

      setError(null);

      await axiosClient

        .post("UpdateFileUrlUser", fileData)

        .then((res) => {
          let token = userData.token;

          localStorage.removeItem("login");

          localStorage.setItem(
            "login",

            JSON.stringify({
              login: true,

              token: token,

              user: data,
            })
          );

          //  setIsSubmit(true)

          setFileError(null);

          toast.success("File upload successfully!");

          setLoading(false);
        })

        .catch((error) => {
          console.error(error);

          toast.error("Error! Something wrong.");

          setFileResponse("");

          setLoading(false);
        });
    }
  }

  function showAlertRequired(title, html) {
    Swal.fire({
      title: title,
      text: "",
      icon: "warning",
      showCancelButton: false,
      allowOutsideClick: false,
      // confirmButtonText: "Call Us",
      showCancelButton: false,
      // cancelButtonText: "Close",
      html: html,
    }).then((result) => {
      // if (result.isConfirmed) {
      //   redirectToContactPage(); // Redirect to the contact page when "Contact Us" is clicked
      // }
    });
  }
  const handleCheckOut = async () => {
    let zpCode = localStorage.getItem("zipCode");

    // console.log("zp code data ===>>>", zpCode);
    var selectedShippingData = getSelectedShippingInfo();

    if (selectedShippingOption == "") {
      showAlertRequired(
        "Please choose your preferred shipping address and method from the available options."
      );

      return true;
    }

    if (!cart.totalTransportation || cart.totalTransportation == 0) {
      showAlertRequired("Please enter zip code", "");
    }

    if (selectedShippingData.zip_PostalCode !== zpCode) {
      showAlertRequired(
        "Please enter matching zip codes for delivery and shipping",
        ""
      );

      return true;
    }

    if (selectedBillingOption == "") {
      showAlertRequired("Please choose your preferred billing address");

      return true;
    }

    if (selectedPPOption == "") {
      showAlertRequired("Please choose your preferred payment method ");

      return true;
    }

    // debugger;
    if (cartItems.length > 0 && cartItems[0].milage == "") {
      showAlertRequired("Please enter zip code", "");
      return true;
    }

    checkoutAPI();
  };

  function checkShippingEligibility() {
    var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];

    if (SelectedItems) {
      const filteredItems = SelectedItems.filter(
        (item) =>
          Number(item.estimatedTotalWeight) > 4500 ||
          Number(item.widthInches) > 96 ||
          Number(item.heightInches) > 96 ||
          Number(item.lengthInches) > 96
      );

      return filteredItems.length > 0;
    } else {
      return false;
    }
  }

  const checkoutAPI = async () => {
    const cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];

    const checkBody = {
      orderNo: "",

      items: cartItems,

      // cartTotalAmount: formattedValue(cart.cartTotalAmount),
      // cartTotalShippingAmount: formattedValue(cart.cartTotalShippingAmount),
      // cartPackagingAmount: formattedValue(getTotalpackaging()),

      totalAmount: formattedValue(totalAmount),
      totalTax: formattedValue(tax),
      totalPurchasePrice: formattedValue(cart.cartTotalAmount),
      TotalRentPrice: formattedValue(cart.totalRent),
      TotalTransportationPrice: formattedValue(cart.totalTransportation),
      TotalTransactionPrice: formattedValue(transaction),
      LadoutPrice: formattedValue(cart.allShippingCost),
      TotalDemageWaiver: formattedValue(cart.totalRent * 0.1),
      selectedShippingOption: getSelectedShippingInfo(),
      selectedBillingOption: getSelectedBillingInfo(),
      selectedPPOption: Number(selectedPPOption),

      ppDetail: {
        id: 0,

        nameAlias: "string",

        paymentMethodId: "string",

        cardLastDigits: "string",

        expireDate: "string",

        cardType: "string",

        userId: 0,
      },
      userDetail: userData.user,
      stripePayment: 0,
    };

    setLoading(true);

    await axiosClient

      .post("checkout", checkBody)

      .then((response) => {
        if (response.statusCode === 200) {
          setLoading(false);

          localStorage.removeItem("cart_items");

          dispatch(getTotals());

          dispatch(setTotalShippingAmount(null));

          setTax(0);

          navigate("/success");
        } else {
          setLoading(false);

          handleShowErrModel();
        }
      })

      .catch((error) => {
        console.error(error);

        setLoading(false);

        handleShowErrModel();
      });
  };

  const getTotalpackaging = () => {
    return cart.cartPackagingAmount;
    // if (isCheckedCustomerWillCall) {
    //   return 0;
    // } else {
    //   return cart.cartPackagingAmount;
    // }
  };

  const handleCheckboxCustomerWillCallChange = (e) => {
    setIsCheckedCustomerWillCall(e.target.checked);
  };

  const damageWaiver = cart.totalRent > 0 ? cart.totalRent * 0.1 : 0;

  const mainTrans = 0;

  if (totalAmount > 3500) {
    const mainTrans = totalAmount * 0.03;
  }

  const maintax = cart.cartTotalAmount + cart.totalRent + mainTrans;

  useEffect(() => {
    if (isCheckedCustomerWillCall) {
      dispatch(setTotalShippingAmount(null));
      setIsCheckedCustomerWillCall(true);
      dispatch(getTotals());
    }
  }, [isCheckedCustomerWillCall]);

  return (
    <>
      <ShipeEngineQuotes
        key={uuid.toString()}
        show={showCarrierQuote}
        close={onQuotePopClose}
      />

      <ul className="PriceboxInfo">
        <>
          {errorMessage != "" && (
            <li>
              {" "}
              <span className="text-danger">{errorMessage} </span>{" "}
            </li>
          )}

          <li>
            <label>Enter Zip Code</label>
          </li>

          <li>
            <div className="search-zip">
              {props.isLoaded && (
                <Autocomplete
                  onPlaceChanged={() => {
                    props.onPlaceChanged(props.item);
                  }}
                  onLoad={props.onLoad}
                  types={["(regions)"]}
                  componentRestrictions={{ country: "us" }}
                >
                  <input
                    type="number"
                    className="form-group"
                    placeholder="Search a Postal Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    style={{
                      boxSizing: `border-box`,
                      height: `40px`,
                      width: "100%",
                      margin: "0",
                      padding: `0.375rem 2.25rem 0.375rem 0.75rem`,
                      fontSize: `1rem`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      border: "1px solid #dee2e6",
                      borderRadius: "4px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </Autocomplete>
              )}
            </div>
          </li>

          <li>
            <label>Select Shipping Address</label>
          </li>

          <li>
            <Form.Select
              value={selectedShippingOption}
              onChange={(event) => onChangeShippingOtipon(event.target.value)}
            >
              <option>Select Shipping</option>

              <option
                value="new"
                style={{
                  backgroundColor: "#333",

                  color: "#f5f5f5",

                  padding: "8px",

                  border: "none",

                  borderRadius: "4px",

                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

                  cursor: "pointer",
                }}
              >
                {" "}
                Add New Shipping Shipping
              </option>

              {shippingInfos &&
                shippingInfos.map((shippingInfo) => (
                  <option value={shippingInfo.id}>
                    {shippingInfo.nameAlias}
                  </option>
                ))}
            </Form.Select>
          </li>

          <li>
            {" "}
            <label>Select Billing Address</label>
          </li>

          <li>
            <Form.Select
              value={selectedBillingOption}
              onChange={(event) => onChangeBillingOtipon(event.target.value)}
            >
              <option>Select Billing</option>

              <option
                value="new"
                style={{
                  backgroundColor: "#333",

                  color: "#f5f5f5",

                  padding: "8px",

                  border: "none",

                  borderRadius: "4px",

                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

                  cursor: "pointer",
                }}
              >
                {" "}
                Add New Billing Address
              </option>

              {billings &&
                billings.map((billItem) => (
                  <option value={billItem.id}>{billItem.nameAlias}</option>
                ))}
            </Form.Select>
          </li>

          <li>
            {" "}
            <label>Select Payment Method</label>
          </li>

          <li>
            <Form.Select
              value={selectedPPOption}
              onChange={(event) => onChangePPOption(event.target.value)}
            >
              <option>Select Payment </option>

              <option
                value="new"
                style={{
                  backgroundColor: "#333",

                  color: "#f5f5f5",

                  padding: "8px",

                  border: "none",

                  borderRadius: "4px",

                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

                  cursor: "pointer",
                }}
              >
                {" "}
                Add New Payment Payment
              </option>

              {paymentPreferences &&
                paymentPreferences.map((itemPP) => (
                  <option value={itemPP.id}>{itemPP.nameAlias}</option>
                ))}
            </Form.Select>
          </li>

          {!userData.user.fileUrl && (
            <>
              <li>
                If you are eligible for tax exemption, please check the box
                below
              </li>
              <li>
                <Form.Check
                  type="checkbox"
                  label="Claim Tax Exemption"
                  checked={isCheckedCustomerWillCall}
                  onChange={handleCheckboxCustomerWillCallChange}
                />
              </li>
            </>
          )}

          {isCheckedCustomerWillCall && (
            <li>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>
                    Upload Tax Exemption Document <br></br>
                    <p className="pdf-text">(PDF Format Only)</p>
                  </Form.Label>

                  <Form.Control
                    className="upload-input"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Col>
            </li>
          )}
          {userData && userData.user.fileUrl && (
            <>
              <a href={userData.user.fileUrl} className="nav-link">
                <button className="btn btn-blue">View uploaded file</button>
              </a>
            </>
          )}
          {isCheckedCustomerWillCall ||
            (userData && userData.user.fileUrl && (
              <p className="mt-1 mb-1">
                {" "}
                Once your exemption is verified, a refund will be issued for the
                taxed amount to your payment method.{" "}
              </p>
            ))}

          <hr />
        </>

        {cart.cartTotalAmount > 0 && (
          <li>
            <span>Purchase Total</span>

            <h6>${formattedValue(cart.cartTotalAmount)} USD</h6>
          </li>
        )}

        {checkNumberisNULL(cart.totalRent) > 0 && (
          <li>
            <span>Rent Total</span>

            <h6>${formattedValue(cart.totalRent)} USD</h6>
          </li>
        )}

        {checkNumberisNULL(cart.totalTransportation) > 0 && (
          <li>
            <span>Transportation Fees </span>

            <h6>${formattedValue(cart.totalTransportation)} USD</h6>
          </li>
        )}

        {checkNumberisNULL(cart.allShippingCost) > 0 && (
          <li>
            <span>Loadout Fees</span>

            <h6>${formattedValue(cart.allShippingCost)} USD</h6>
          </li>
        )}

        {totalAmount > 3500 && (
          <li>
            <span>Transaction Fee (3%)</span>

            <h6>${formattedValue(transaction)} USD</h6>
          </li>
        )}

        {checkNumberisNULL(cart.totalRent) > 0 && (
          <li>
            <span>Damage Waiver (10%)</span>

            <h6>${formattedValue(cart.totalRent * 0.1)} USD</h6>
          </li>
        )}

        <li>
          <span>Tax charges</span>

          <h6>${formattedValue(tax)} USD</h6>
        </li>

        <hr />

        <li>
          <span>Total</span>

          <h4>${formattedValue(totalAmount)} USD</h4>
        </li>

        <li className="w-100">
          {loading ? (
            <button
              className="btn btn-blue  d-block w-100 mt-2"
              disabled
              style={{ position: "relative" }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  color: "white",

                  marginRight: "calc(50% - 12px)",

                  marginLeft: "calc(50% - 12px)",
                }}
              />
            </button>
          ) : (
            <button
              disabled={
                !cart.isAllowCheckout && isDistanceMoreThan150Miles === true
              }
              onClick={handleCheckOut}
              className="btn  d-block btn-blue w-100 mt-2"
              type="submit"
            >
              Check Out {cart.isAllowCheckout}
            </button>
          )}
        </li>
      </ul>

      <CreateShipping
        show={showShipping}
        refreshData={refreshDataShipping}
        SetValFromPb={SetShippingValFromPb}
        isFromPB={isFromPB}
      />

      <CreateBillingAddress
        show={showBilling}
        refreshData={refreshDataBilling}
        SetValFromPb={SetBillingValFromPb}
        isFromPB={isFromPB}
      />

      <CreatePaymentPreference
        show={showPP}
        refreshData={refreshDataPP}
        SetValFromPb={SetPPValFromPb}
        isFromPB={isFromPB}
      />

      <Cancel show={showErrModel} />
    </>
  );

  function getshippings() {
    if (userData && userData.user) {
      axiosClient

        .get("getShippingsInfoByUserId/" + Number(userData.user.id))

        .then((res) => {
          setShippingInfos(res);
        })

        .catch((error) => {
          console.error(error);
        });
    }
  }

  function getBillings() {
    if (userData && userData.user) {
      axiosClient

        .get("getBillingsInfoByUserId/" + Number(userData.user.id))

        .then((res) => {
          setBillings(res);
        })

        .catch((error) => {
          console.error(error);
        });
    }
  }

  function getPaymentPreferences() {
    axiosClient

      .get("getPaymentPreferencesInfoByUserId/" + Number(userData.user.id))

      .then((res) => {
        setPaymentPreferences(res);
      })

      .catch((error) => {
        console.error(error);
      });
  }
}

export default PriceCartBox;
