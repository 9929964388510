import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Appraisalsinfo from "./Appraisalsinfo/Appraisalsinfo";
import AppraisalsForm from "./AppraisalsForm/AppraisalsForm";
import useDocumentTitle from "../../DocumentTitle";

function Appraisals() {
  // useDocumentTitle("PC | Appraisals");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <Appraisalsinfo />
          <AppraisalsForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Appraisals;
