import React from "react";
import InventoryPoductSection from "./InventoryroductSection/InventoryProductSection";
import SellWithUsBanner from "../SellWithUsBanner/SellWithUsBanner";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import useDocumentTitle from "../../DocumentTitle";
import "./Inventory.css";
import SeoHelmet from "../Seo/SeoHelmet";

function Inventory() {
  useDocumentTitle("PC | 40FT Containers");
  const location = useLocation();
  var industryId = 0;
  var categoryId = 0;
  var typeId = 0;

  var payload = {};
  if (location && location.pathname !== "/Containers" && location.state) {
    if (location.state.industryId !== null && location.state.industryId > 0) {
      industryId = location.state.industryId;
    }

    if (
      location.pathname !== "/Containers" &&
      location.state.categoryId !== null &&
      location.state.categoryId > 0
    ) {
      categoryId = location.state.categoryId;
    }

    if (
      location.pathname !== "/Containers" &&
      location.state.typeId !== null &&
      location.state.typeId > 0
    ) {
      typeId = location.state.typeId;
    }
    if (
      location.pathname !== "/Containers" &&
      location.state.payload &&
      location.state.payload.IsGlobelSearch
    ) {
      payload = location.state.payload;
    }
  }
  return (
    <div>
      <Header />
      <SeoHelmet
        title="PC | Search Results"
        description="Browse our inventory for a wide range of industrial equipment and surplus items. Find what you need from various categories and industries."
        name="Pro Container"
        type="website"
        canonical="https://procontainers.com/Containers"
        url="https://procontainers.com/Containers"
      />

      <div className="d-margin pt-3 inventoryPage">
        <InventoryPoductSection key="inventory-section" payload={payload} />
        <SellWithUsBanner key="inventory-sectio-2n" />
      </div>
      <Footer />
    </div>
  );
}
export default Inventory;
