import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import RecyclingServicesInfo from "./RecyclingServicesInfo/RecyclingServicesInfo";
import RecyclingServicesInfoForm from "./RecyclingServicesInfoForm/RecyclingServicesInfoForm";
import useDocumentTitle from "../../DocumentTitle";

function ContainerServices() {
  useDocumentTitle("PC | Container Services");
  return (
    <div>
      <Header />
      <div className="d-margin">
        <Container className="pt-2">
          <RecyclingServicesInfo />
          <RecyclingServicesInfoForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default ContainerServices;
