import React from "react";
import { Container } from "react-bootstrap";
import RentForm from "./RentForm/RentForm";
import SellMyEquipment from "./SellMyEquipment/SellMyEquipment";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import useDocumentTitle from "../../DocumentTitle";
import Helmet from "react-helmet";
import "./SwitchtoSell.css";

function SwitchtoSell() {
  useDocumentTitle("PC | Sell to Us");
  return (
    <div>
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={`https://kingsurplus.com/InventoryDetail/SelltoUs`}
        />
      </Helmet>
      <div className="d-margin">
        <Container className="pt-2">
          <SellMyEquipment />
          <RentForm />
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default SwitchtoSell;
