import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  rentRate: parseFloat(localStorage.getItem("rentRate")),
  cartItems: localStorage.getItem("cart_items")
    ? JSON.parse(localStorage.getItem("cart_items"))
    : [],
  userData: JSON.parse(localStorage.getItem("login"))
    ? JSON.parse(localStorage.getItem("login"))
    : JSON.stringify({
        login: false,
        token: "",
        user: {
          accessToken: "",
          businessName: "",
          customerKeyQB: null,
          customerNameQB: null,
          customerQBId: null,
          email: "",
          examet: false,
          fileName: "",
          fileUrl: "",
          fullName: "",
          id: 0,
          isAlreadyRegistor: false,
        },
      }),
  cartTotalTax: 0,
  cartTotalAmount: 0,
  cartTotalShippingAmount: 0,
  cartPackagingAmount: 0,
  totalItem: 0,
  shippingAddres: {},
  billingAddres: {},
  paymentMethod: {},
  allShippingCost: 0,
  isAllowCheckout: true,
  totalRent: 0,
  totalTransportation: 0,
  perMilagePrice: localStorage.getItem("perMilagePrice")
    ? JSON.parse(localStorage.getItem("perMilagePrice"))
    : "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setTotalShippingAmount(state, param) {
      state.cartTotalShippingAmount = param.payload;
    },

    setUserData: (state, action) => {
      var data = action.payload;
      localStorage.setItem("cart_items", JSON.stringify(data));
    },
    setRentRate: (state, action) => {
      var selectedItem = action.payload;
      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems != null) {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index > -1) {
          //  _.remove(cartItems, function (i) { return i.id == selectedItem.id; });
          if (selectedItem.selectedRentPrice) {
            cartItems[index].selectedRentPrice = selectedItem.selectedRentPrice;
            localStorage.setItem("cart_items", JSON.stringify(cartItems));

            // selectedItem.selectedQty = selectedItem.selectedQty;
          }
          // cartItems.push(selectedItem);
          // localStorage.setItem('cart_items', JSON.stringify(cartItems));
        }
      }

      // state.rentRate = action.payload; // Update the rent rate value in the state
    },

    setTotalTaxZero(state, param) {
      state.cartTotalTax = 0;
    },

    setShippingAddress(state, param) {
      state.shippingAddres = param.payload;
    },
    setBillingAddress(state, param) {
      state.billingAddres = param.payload;
    },
    setPaymentMethod(state, param) {
      state.paymentMethod = param.payload;
    },
    getTotals(state, action) {
      let isAllowCheck = true;
      var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (SelectedItems == null) {
        return;
      }

      let {
        total,
        tax,
        packaging,
        allShippingCost,
        totalRent,
        totalTransportation,
      } = SelectedItems.reduce(
        (product, cartItem) => {
          var itemTotal = cartItem.salePrice * cartItem.selectedQty;

          if (cartItem.selectedBuyOrRent !== "rent") {
            product.total += itemTotal;

            if (cartItem.milagePrice && cartItem.milagePrice !== "") {
              let tran = Number(cartItem.milagePrice);
              if (tran) {
                product.totalTransportation += Number(tran);
              }
            }

          }

          if (cartItem.selectedBuyOrRent === "rent") {
            let rn =
              Number(cartItem.selectedRentPrice) * Number(cartItem.selectedQty);
            if (rn) {
              product.totalRent += Number(rn);

              if (cartItem.milagePrice && cartItem.milagePrice !== "") {
                let tran2 = Number(cartItem.milagePrice) * 2;
                if (tran2) {
                  product.totalTransportation += Number(tran2);
                }
              }

            }
          }

          

          if (cartItem.totalShippCost && Number(cartItem.totalShippCost) > 0) {
            product.allShippingCost +=
              Number(cartItem.totalShippCost) * cartItem.selectedQty;
          }

          if (cartItem.itemTax && Number(cartItem.itemTax) > 0) {
            product.tax += cartItem.itemTax * cartItem.selectedQty;
          }

          if (cartItem.toPostalCode == "") {
            isAllowCheck = false;
          }

          let packageCost = getPackgingRate(cartItem.packagingCostData);

          product.packaging += packageCost * cartItem.selectedQty;
          return product;
        },
        {
          total: 0,
          tax: 0,
          packaging: 0,
          allShippingCost: 0,
          totalRent: 0,
          totalTransportation: 0,
        }
      );
      // total = parseInt(Math.ceil(total));
      // tax = parseInt(Math.ceil(tax));
      // packaging = parseInt(Math.ceil(packaging));
      // allShippingCost = parseInt(Math.ceil(allShippingCost));

      var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      var totalCount = 0;
      if (cartItems && cartItems.length > 0) {
        totalCount = cartItems.length;
      }

      state.totalItem = totalCount;
      state.cartTotalAmount = total;
      state.cartTotalTax = tax;
      state.cartPackagingAmount = packaging;
      state.allShippingCost = allShippingCost;
      state.isAllowCheckout = isAllowCheck;
      state.totalRent = totalRent;
      state.totalTransportation = totalTransportation;
    },

    clearCart(state, action) {
      localStorage.removeItem("cart_items");
    },

    handleAddCart(state, action) {
      let selectedItem = action.payload;
      var itemList = [];
      selectedItem.selectedQty = 1;
      selectedItem.selectedPackingType = "";
      selectedItem.selectedPackingPrice = 0;
      selectedItem.containerDoorOnDelivery = "";
      selectedItem.ShippingMethod = "";
      selectedItem.toPostalCode = "";
      selectedItem.distance = "";
      selectedItem.milagePrice = "";
      selectedItem.perMilagePrice = localStorage.getItem("perMilagePrice");
      selectedItem.fuelCost = "";
      selectedItem.selectedRentType = "";
      selectedItem.selectedBuyOrRent = "";
      selectedItem.selectedRentPrice = 0;
      selectedItem.requiredPostcode = "This field is Required";
      selectedItem.requiredBuyOrRent = "";
      selectedItem.requiredRentDurration = "";
      selectedItem.startedRentDate = "";
      selectedItem.endRentDate = "";
      selectedItem.perMilageRate = "";
      selectedItem.milage = "";


      var containerSize = selectedItem.categoryName.replace("'", "");

      if (containerSize == "10" || containerSize == "20") {
        selectedItem.totalShippCost = 50;
      }

      if (containerSize == "40" || containerSize == "53") {
        selectedItem.totalShippCost = 95;
      }

      if (selectedItem.isRental == true) {
        selectedItem.requiredBuyOrRent = "This field is Required";
        selectedItem.requiredRentDurration = "This field is Required";
      }

      if (
        selectedItem.estimatedTotalWeight > 4500 ||
        selectedItem.widthInches > 96 ||
        selectedItem.heightInches > 96 ||
        selectedItem.lengthInches > 96
      ) {
        selectedItem.selectedShippingType = 1;
      }
      itemList.push(selectedItem);
      var cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems === null) {
        localStorage.setItem("cart_items", JSON.stringify(itemList));
      } else {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index == -1) {
          cartItems.push(selectedItem);
          localStorage.removeItem("cart_items");
          localStorage.setItem("cart_items", JSON.stringify(cartItems));
          AddMillageRate(selectedItem);
        }
      }
    },

    handleUpdateQtyCart(state, action) {
      var selectedItem = action.payload;
      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems != null) {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index > -1) {
          //  _.remove(cartItems, function (i) { return i.id == selectedItem.id; });
          if (
            selectedItem.selectedQty > 0 &&
            selectedItem.quantity >= selectedItem.selectedQty
          ) {
            cartItems[index].selectedQty = selectedItem.selectedQty;
            localStorage.setItem("cart_items", JSON.stringify(cartItems));

            state.cartTotalShippingAmount = 0;
            // selectedItem.selectedQty = selectedItem.selectedQty;
          }
          // cartItems.push(selectedItem);
          // localStorage.setItem('cart_items', JSON.stringify(cartItems));
        }
      }
    },

    handleToDistanceAndFareItem(state, action) {
      var selectedItem = action.payload;
      AddMillageRate(selectedItem);
    },

    handleStartAndEndDate(state, action) {
      var selectedItem = action.payload;
      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems != null) {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index > -1) {
          cartItems[index].startedRentDate = selectedItem.startedRentDate;
          cartItems[index].endRentDate = selectedItem.endRentDate;
          localStorage.setItem("cart_items", JSON.stringify(cartItems));
        }
      }
    },

    handRentItem(state, action) {
      var selectedItem = action.payload;
      var cartItems = [];
      cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
      if (cartItems != null) {
        var index = _.findIndex(cartItems, function (o) {
          return o.id === selectedItem.id;
        });
        if (index > -1) {
          cartItems[index].selectedRentPrice = Number(
            selectedItem.selectedRentPrice
          ).toFixed(2);
          cartItems[index].selectedRentType = selectedItem.selectedRentType;
          cartItems[index].selectedBuyOrRent = selectedItem.selectedBuyOrRent;

          if (selectedItem.isRental && selectedItem.selectedBuyOrRent != "") {
            cartItems[index].requiredBuyOrRent = "";
          }

          if (selectedItem.isRental && selectedItem.selectedRentType != "") {
            cartItems[index].requiredRentDurration = "";
          }

          if (selectedItem.isRental && selectedItem.selectedBuyOrRent == "") {
            cartItems[index].requiredBuyOrRent = "This field is required";
          }
          if (selectedItem.isRental && selectedItem.selectedRentType == "") {
            cartItems[index].requiredRentDurration = "This field is required";
          }
          localStorage.setItem("cart_items", JSON.stringify(cartItems));
        }
      }
    },
  },
});

function AddMillageRate(selectedItem) {
  var cartItems = [];
  cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  if (cartItems != null) {
    var index = _.findIndex(cartItems, function (o) {
      return o.id === selectedItem.id;
    });
    if (
      index > -1 &&
      selectedItem.perMilagePrice &&
      selectedItem.perMilagePrice != ""
    ) {
      cartItems[index].perMilagePrice = selectedItem.perMilagePrice;
      cartItems[index].milagePrice = Number(selectedItem.perMilagePrice) * Number(selectedItem.selectedQty);
      cartItems[index].perMilageRate = selectedItem.perMilageRate;
      if(selectedItem.milage){
        cartItems[index].milage = selectedItem.milage.toString();
      }
      

      if (selectedItem.toPostalCode != "") {
        cartItems[index].requiredPostcode = "";
      }
      localStorage.setItem("cart_items", JSON.stringify(cartItems));
      localStorage.setItem("perMilagePrice", cartItems[index].perMilagePrice);
    }
  }
}

function getPackgingRate(val) {
  let setPrice = 0;
  if (val && checkShippingEligibility() === false) {
    var arr = val.split(";");
    if (arr.length !== undefined && arr[0] !== undefined && arr[0] !== "") {
      // let packageSelectVal = arr[0].match(/\d+(\.\d+)+/)[0];
      let packageSelectVal = arr[0];

      if (packageSelectVal === "1.1") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.2") {
        setPrice = 15;
      }
      if (packageSelectVal === "1.3") {
        setPrice = 20;
      }
      if (packageSelectVal === "1.4") {
        setPrice = 25;
      }
      if (packageSelectVal === "1.5") {
        setPrice = 30;
      }
      if (packageSelectVal === "1.5.1") {
        setPrice = 35;
      }
      if (packageSelectVal === "1.5.2") {
        setPrice = 40;
      }
      if (packageSelectVal === "1.6") {
        setPrice = 3;
      }
      if (packageSelectVal === "1.7") {
        setPrice = 8;
      }

      if (packageSelectVal === "1.8") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.9") {
        setPrice = 0;
      }
      if (packageSelectVal === "2.1") {
        setPrice = 25;
      }
      if (packageSelectVal === "2.2") {
        setPrice = 35;
      }
      if (packageSelectVal === "3.1") {
        setPrice = 45;
      }
      if (packageSelectVal === "3.2") {
        setPrice = 100;
      }
      if (packageSelectVal === "3.3") {
        setPrice = 0;
      }
      if (packageSelectVal === "4") {
        setPrice = 50;
      }
      if (packageSelectVal === "6") {
        setPrice = 100;
      }
      if (packageSelectVal === "7") {
        setPrice = 200;
      }
      if (packageSelectVal === "8") {
        setPrice = 300;
      }

      if (packageSelectVal === "9.1") {
        setPrice = 400;
      }
      if (packageSelectVal === "9.2") {
        setPrice = 450;
      }

      if (packageSelectVal === "9.3") {
        setPrice = 750;
      }

      if (packageSelectVal === "9.4") {
        setPrice = 0;
      }
      if (packageSelectVal === "9.5") {
        setPrice = 0;
      }

      if (packageSelectVal === "9.6") {
        setPrice = 0;
      }
      if (packageSelectVal === "1.1") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.2") {
        setPrice = 15;
      }
      if (packageSelectVal === "1.3") {
        setPrice = 20;
      }
      if (packageSelectVal === "1.4") {
        setPrice = 25;
      }
      if (packageSelectVal === "1.5") {
        setPrice = 30;
      }
      if (packageSelectVal === "1.5.1") {
        setPrice = 35;
      }
      if (packageSelectVal === "1.5.2") {
        setPrice = 40;
      }
      if (packageSelectVal === "1.6") {
        setPrice = 3;
      }
      if (packageSelectVal === "1.7") {
        setPrice = 8;
      }

      if (packageSelectVal === "1.8") {
        setPrice = 10;
      }
      if (packageSelectVal === "1.9") {
        setPrice = 0;
      }
      if (packageSelectVal === "2.1") {
        setPrice = 25;
      }
      if (packageSelectVal === "2.2") {
        setPrice = 35;
      }
      if (packageSelectVal === "3.1") {
        setPrice = 45;
      }
      if (packageSelectVal === "3.2") {
        setPrice = 100;
      }
      if (packageSelectVal === "3.3") {
        setPrice = 0;
      }
      if (packageSelectVal === "4") {
        setPrice = 50;
      }
      if (packageSelectVal === "6") {
        setPrice = 100;
      }
      if (packageSelectVal === "7") {
        setPrice = 200;
      }
      if (packageSelectVal === "8") {
        setPrice = 300;
      }
    }
  }
  return setPrice;
}

function checkShippingEligibility() {
  var SelectedItems = JSON.parse(localStorage.getItem("cart_items")) || [];
  if (SelectedItems) {
    const filteredItems = SelectedItems.filter(
      (item) =>
        Number(item.estimatedTotalWeight) > 4500 ||
        Number(item.widthInches) > 96 ||
        Number(item.heightInches) > 96 ||
        Number(item.lengthInches) > 96
    );
    return filteredItems.length > 0;
  } else {
    return false;
  }
}

// function updateQty(selectedItem, totalShippCost) {
//     var cartItems = JSON.parse(localStorage.getItem('cart_items')) || [];
//     var index = cartItems.findIndex(function (o) {
//       return o.id === selectedItem.id;
//     });
//     if (index > -1) {
//       var updatedItem = Object.assign({}, selectedItem, { totalShippCost: totalShippCost });
//       if (updatedItem != null) {
//         cartItems[index] = updatedItem;
//         cartItems[index].selectedShippingType = "3";
//         localStorage.setItem('cart_items', JSON.stringify(cartItems));
//         dispatch(getTotals());
//         window.location.reload();
//       }
//     }
//   }
export const {
  getTotals,
  clearCart,
  handleAddCart,
  setShippingAddress,
  setBillingAddress,
  setPaymentMethod,
  handleConfirmShipRate,
  setTotalShippingAmount,
  handleUpdateQtyCart,
  setTotalTaxZero,
  handleToDistanceAndFareItem,
  handRentItem,
  setRentRate,
  handleStartAndEndDate,
  setUserData,
} = cartSlice.actions;

export default cartSlice.reducer;
